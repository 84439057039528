import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { black } from 'styles';

const Alarm24: FC<IconProps> = ({ width = 24, height = 24, color = black, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' color={color} {...props}>
    <path
      d='M4.91223 10.0872C4.91223 6.17279 8.08552 2.99951 12 2.99951V2.99951C15.9144 2.99951 19.0877 6.17279 19.0877 10.0872V17.0869H4.91223V10.0872Z'
      stroke={color}
      strokeWidth='1.5'
    />
    <path d='M2.99969 17.0869H21.0003' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <path
      d='M15.9376 17.0869C15.9376 19.2481 14.1747 21.0001 12 21.0001C9.82531 21.0001 8.06238 19.2481 8.06238 17.0869'
      stroke={color}
      strokeWidth='1.5'
    />
  </svg>
);
export default Alarm24;
