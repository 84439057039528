import default_router, { NextRouter, SingletonRouter, useRouter } from 'next/router';
import { useAppVersion } from './app/useAppVersion';
import { web_path } from 'constants/web_path';
import {
  MAIN_TAB_ID,
  WebViewType,
  callAppDeepLink,
  getAppDeepLink,
  moveMainTab,
  playShopLiveCampaign,
  back as backForApp,
  SUB_TAB_ID,
  isLowAppVersion,
  APP_VERSION_TYPE,
} from 'util/app_utils';
import config from 'util/config';
import { getDevice } from 'util/device';
import { useIsApp } from 'util/is_app';

interface PdpPageProps {
  /**
   * csr 로만 렌더 필요한 경우
   * @default false
   */
  only_csr?: boolean;
}

/**
 * 다음과 같은 이유로 인한 상황으로 커스텀 라우트 사용
 * 1. 커스텀 routes를 사용하고 있어 pathname을 전부 맞추어야 하는 경우
 * 2. app 분기가 필요한 경우
 */
export const useFbkRouter = () => {
  const router = useRouter();
  const { is_native_version, is_live_version } = useAppVersion();
  const is_app = useIsApp();

  const push = (path: string, props?: { only_csr?: boolean; webview_type?: WebViewType }) => {
    const init_props: { only_csr?: boolean; webview_type?: WebViewType } = { only_csr: true, webview_type: 'full' };
    const { only_csr, webview_type } = props ?? init_props;
    if (is_native_version) {
      callAppDeepLink(path, webview_type);
      return;
    }

    if (is_app && only_csr) {
      window.location.href = path;
      return;
    }

    router.push(path, undefined, { shallow: true });
  };

  const back = () => {
    is_native_version ? backForApp() : router.back();
  };

  const goLive = (campaign_key: string) => {
    is_live_version ? playShopLiveCampaign(campaign_key) : push(`/lives/${campaign_key}`);
  };

  const goHome = (only_web_push: boolean | undefined = false) => {
    if (only_web_push) {
      router.push(web_path.root);
    }
    is_native_version ? moveMainTab({ main_tab: MAIN_TAB_ID.HOME }) : router.push(web_path.root);
  };

  const goMainTab = ({ main_tab, sub_tab }: { main_tab: MAIN_TAB_ID; sub_tab?: SUB_TAB_ID }) => {
    if (is_native_version) {
      moveMainTab({ main_tab, sub_tab });
      return;
    }

    const web_path = {
      [MAIN_TAB_ID.HOME]: '/',
      [MAIN_TAB_ID.CATEGORIES]: '/categories',
      [MAIN_TAB_ID.STORE]: '/stores',
      [MAIN_TAB_ID.LIKE]: '/like',
      [MAIN_TAB_ID.MY_PAGE]: '/my-page',
    }[main_tab];

    const sub_tab_web_path = {
      [SUB_TAB_ID.HOME_TREND]: 'hot',
      [SUB_TAB_ID.HOME_BEST]: 'best',
      [SUB_TAB_ID.HOME_SALE]: 'sale',
      [SUB_TAB_ID.HOME_NEW]: 'new',
    };
    const sub_tab_path = sub_tab ? `?tab=${sub_tab_web_path[sub_tab]}` : '';

    router.push(`${web_path}${sub_tab_path}`);
  };

  return {
    push,
    query: router.query,
    web_push: router.push,
    goLive,
    goHome,
    back,
    goMainTab,
    ...getPaths(is_app, is_native_version),
    ...goPages({ router, is_app, is_native_version }),
  };
};

/**
 * FC 또는 Hook 내부 외의 Client 함수 유틸 상황에서 커스텀 라우트 사용
 */
export const fbkRouter = () => {
  const router = default_router;
  const { is_app } = getDevice(navigator.userAgent || '');
  const is_native_version = isLowAppVersion(APP_VERSION_TYPE.NATIVE_SUPPORT, navigator.userAgent);

  return { ...getPaths(is_app), ...goPages({ router, is_app, is_native_version }) };
};

/**
 * Page별 라우트 정의
 */
const goPages = ({
  router,
  is_app,
  is_native_version,
}: {
  router: NextRouter | SingletonRouter;
  is_app: boolean;
  is_native_version: boolean;
}) => {
  const goPdpPage = (catalog_product_id: string | null = '', { only_csr }: PdpPageProps = { only_csr: false }) => {
    const pdp_path = `/catalog/products/${catalog_product_id}`;

    if (is_native_version) {
      callAppDeepLink(pdp_path, 'full');
      return;
    }

    if (only_csr) {
      router.push(pdp_path);
      return;
    }

    if (is_app) {
      window.location.href = getPdpPathWithoutAppCache(catalog_product_id);
      return;
    }

    router.push(pdp_path);
  };

  const goStorePage = (shop_id: string) => {
    const store_path = `/store/${shop_id}`;

    if (is_native_version) {
      callAppDeepLink(store_path, 'full');
      return;
    }

    if (is_app) {
      window.location.href = store_path;
      return;
    }

    router.push(store_path);
  };

  const goLiveDetailPage = (campaign_key: string) => {
    router.push(`/lives/${campaign_key}`);
  };

  const goReivewDetailPage = (id: string) => {
    router.push(`/catalog/products/${id}/review`, undefined, { shallow: true });
  };

  const goOtherPage = (path: string) => {
    const page_path = path.startsWith('http') ? path : `${config.appUrl}${path}`;

    if (is_native_version) {
      callAppDeepLink(page_path, 'full');
      return;
    }

    if (is_app) {
      window.location.href = page_path;
      return;
    }

    router.push(page_path);
  };

  return { goPdpPage, goStorePage, goLiveDetailPage, goReivewDetailPage, goOtherPage };
};

const getPaths = (is_app: boolean, is_native_version?: boolean) => {
  const getPdpPath = ({
    catalog_product_id = '',
  }: {
    catalog_product_id: string | null;
    only_csr?: boolean;
    use_full_path?: boolean;
  }) => {
    const pdp_path = `/catalog/products/${catalog_product_id}`;

    if (is_native_version) {
      return getAppDeepLink(pdp_path, 'full');
    }

    if (!is_app) {
      return pdp_path;
    }

    return getPdpPathWithoutAppCache(catalog_product_id);
  };

  return { getPdpPath };
};

const getPdpPathWithoutAppCache = (catalog_product_id: string | null = '') => {
  /*
   * 앱에서의 PDP 전환은 SSR -> SSR진행
   * https://croquis.atlassian.net/browse/FBK-187 앱 캐싱이슈로 인한 캐싱 방지 처리
   * TODO: server.ts의 캐싱 제거시 해당 time 필드 제거예정
   */
  return `/catalog/products/${catalog_product_id}?time=${new Date().getTime()}`;
};
