import { FC } from 'react';
import { white, black } from '../../../styles';
import { IconProps } from 'components/base/Icon';

const Enlargement36: FC<IconProps> = ({ width = 36, height = 36, color = black, sub_color = white, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 36 36' fill='none' {...props}>
      <circle cx='18' cy='18' r='18' fill={color} fillOpacity='0.6' />
      <path d='M17 12H12V17' stroke={sub_color} strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 12L17 17' stroke={sub_color} strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19 24L24 24L24 19' stroke={sub_color} strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M24 24L19 19' stroke={sub_color} strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export default Enlargement36;
