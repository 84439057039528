import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray900 } from 'styles';

const BottomStoreP24: FC<IconProps> = ({ width = 24, height = 24, color = gray900, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.07475 3.07515L2.35487 12.2548L12.9743 22.8742L22.8738 12.9747L12.2544 2.35527L3.07475 3.07515ZM9.7743 9.77453C10.7406 8.80824 10.7406 7.24157 9.7743 6.27528C8.80801 5.30899 7.24135 5.30899 6.27505 6.27528C5.30876 7.24157 5.30876 8.80824 6.27505 9.77453C7.24135 10.7408 8.80801 10.7408 9.7743 9.77453Z'
      fill={color}
    />
    <path
      d='M2.35487 12.2548L1.65701 12.2L1.63179 12.5216L1.85989 12.7497L2.35487 12.2548ZM3.07475 3.07515L3.02002 2.3773L2.42366 2.42407L2.37689 3.02043L3.07475 3.07515ZM12.9743 22.8742L12.4793 23.3692L12.9743 23.8642L13.4693 23.3692L12.9743 22.8742ZM22.8738 12.9747L23.3688 13.4697L23.8638 12.9747L23.3688 12.4797L22.8738 12.9747ZM12.2544 2.35527L12.7493 1.8603L12.5212 1.6322L12.1996 1.65742L12.2544 2.35527ZM3.05272 12.3095L3.7726 3.12988L2.37689 3.02043L1.65701 12.2L3.05272 12.3095ZM13.4693 22.3792L2.84984 11.7598L1.85989 12.7497L12.4793 23.3692L13.4693 22.3792ZM22.3788 12.4797L12.4793 22.3792L13.4693 23.3692L23.3688 13.4697L22.3788 12.4797ZM11.7594 2.85025L22.3788 13.4697L23.3688 12.4797L12.7493 1.8603L11.7594 2.85025ZM3.12947 3.77301L12.3091 3.05313L12.1996 1.65742L3.02002 2.3773L3.12947 3.77301ZM9.27933 6.77025C9.97225 7.46318 9.97225 8.58663 9.27933 9.27955L10.2693 10.2695C11.5089 9.02984 11.5089 7.01996 10.2693 5.7803L9.27933 6.77025ZM6.77003 6.77025C7.46295 6.07733 8.5864 6.07733 9.27933 6.77025L10.2693 5.7803C9.02962 4.54065 7.01974 4.54065 5.78008 5.7803L6.77003 6.77025ZM6.77003 9.27955C6.07711 8.58663 6.07711 7.46318 6.77003 6.77025L5.78008 5.7803C4.54042 7.01996 4.54042 9.02984 5.78008 10.2695L6.77003 9.27955ZM9.27933 9.27955C8.5864 9.97248 7.46295 9.97248 6.77003 9.27955L5.78008 10.2695C7.01974 11.5092 9.02962 11.5092 10.2693 10.2695L9.27933 9.27955Z'
      fill={color}
    />
  </svg>
);
export default BottomStoreP24;
