import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { gray300 } from 'styles';

const CheckCircle60: FC<IconProps> = ({ width = 60, height = 60, color = gray300, sub_color = gray300, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 60 60' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.0363 11.9609C20.0751 11.9609 12 20.0361 12 29.9973C12 39.9585 20.0751 48.0336 30.0363 48.0336C39.9975 48.0336 48.0727 39.9585 48.0727 29.9973C48.0727 20.0361 39.9975 11.9609 30.0363 11.9609ZM10 29.9973C10 18.9315 18.9706 9.96094 30.0363 9.96094C41.1021 9.96094 50.0727 18.9315 50.0727 29.9973C50.0727 41.063 41.1021 50.0336 30.0363 50.0336C18.9706 50.0336 10 41.063 10 29.9973Z'
        fill={color}
      />
      <path d='M22 29.5455L27.6471 35L38 25' stroke={sub_color} strokeWidth='2' />
    </svg>
  );
};

export default CheckCircle60;
