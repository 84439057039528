import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { pink600 } from 'styles';

const HeartFill24: FC<IconProps> = ({ size = 24, color = pink600, ...props }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' color={color} {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.5754 6.83916C20.2286 6.08841 19.718 5.44088 19.088 4.95288C18.4579 4.46488 17.7275 4.15119 16.9601 4.03909C15.9184 3.90219 14.8635 4.12693 13.947 4.681C13.1816 5.1403 12.6152 5.84585 12.0003 6.51267C11.3855 5.84585 10.8242 5.12923 10.0537 4.681C9.13714 4.12693 8.08224 3.90219 7.04055 4.03909C6.27323 4.15119 5.54277 4.46488 4.91271 4.95288C4.28264 5.44088 3.77204 6.08841 3.4253 6.83916C2.51958 8.88111 3.14976 11.1582 4.4739 12.7741C6.54814 15.2836 7.10986 15.6111 12 20C16.9 15.6111 17.4321 15.2947 19.5064 12.7741C20.8509 11.1582 21.4811 8.88111 20.5754 6.83916Z'
        fill={color}
        stroke={color}
        strokeWidth='1.4'
      />
    </svg>
  );
};

export default HeartFill24;
