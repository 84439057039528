import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray700 } from 'styles';

const ArrowLeft24: FC<IconProps> = ({ width = 24, height = 24, color = gray700, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
    <path d='M15.6 20.4001L7.19995 12.0001L15.6 3.60015' stroke={color} strokeWidth='1.4' />
  </svg>
);
export default ArrowLeft24;
