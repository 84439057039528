import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const Search60: FC<IconProps> = ({ size = 60, color = colors.gray300, ...props }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 60 60' fill='none' {...props}>
      <path d='M35.0664 34.5664L50.4173 49.9173' stroke={color} strokeWidth='2' />
      <circle cx='24.9088' cy='24.4088' r='15.1588' stroke={color} strokeWidth='2' />
    </svg>
  );
};

export default Search60;
