import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';

import { createInviteCode, getUserInfo, getUserInviteStatus, isUserInviteEnable } from 'api2';
import { GetUserInviteStatus } from 'api2/types';

type UserInviteStatus = GetUserInviteStatus['user_invite_status'];

export interface UserInviteInfo extends UserInviteStatus {
  invite_code: string;
  is_user_invite_enable: boolean;
}

export const INVITE_CODE_INFO_QUERY_KEY = ['getUserInviteInfo'];

const init_info: UserInviteInfo = {
  count_of_invitee: 0,
  count_of_promotion_fulfill: 0,
  reward_amount: 0,
  invite_code: '',
  is_user_invite_enable: false,
};

export const fetchUserInviteInfo = async (req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  const {
    data: { user_account },
  } = await getUserInfo(undefined, context_request);
  // 미로그인 시 init 값 세팅
  if (!user_account) {
    return init_info;
  }

  const [
    {
      data: { user_invite_status },
    },
    invite_code,
    invite_enable,
  ] = await Promise.all([
    getUserInviteStatus(undefined, context_request),
    createInviteCode(undefined, context_request),
    isUserInviteEnable(undefined, context_request),
  ]);

  const invite_info: UserInviteInfo = {
    ...user_invite_status,
    invite_code: invite_code.data.createInviteCode,
    is_user_invite_enable: invite_enable.data.isUserInviteEnable,
  };
  return invite_info;
};

/**
 * 초대 코드 관련 정보 제공 query hook
 * @hook
 */
export const useInviteCodeInfo = () => {
  const { data, ...rest } = useQuery<UserInviteInfo>(INVITE_CODE_INFO_QUERY_KEY, () => fetchUserInviteInfo());
  const invite_code_info: UserInviteInfo = data ?? init_info;
  // 나의 초대 현황 뉴뱃지 유무
  const is_show_invite_status_new_badge =
    invite_code_info.count_of_invitee > 0 || invite_code_info.count_of_promotion_fulfill > 0;

  return {
    invite_code_info,
    is_show_invite_status_new_badge,
    ...rest,
  };
};
