import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray400 } from 'styles';

const ScheduleFill20: FC<IconProps> = ({ width = 20, height = 20, color = gray400, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 20 20' fill='none' {...props}>
    <path d='M7 3L7 7' stroke={color} stroke-width='1.4' />
    <path d='M13 3L13 7' stroke={color} stroke-width='1.4' />
    <path d='M7 4.5L7 6.5' stroke={color} stroke-width='1.4' />
    <path d='M13 4.5L13 6.5' stroke={color} stroke-width='1.4' />
    <path d='M3 10H17V15C17 16.1046 16.1046 17 15 17H5C3.89543 17 3 16.1046 3 15V10Z' fill={color} />
    <path d='M3 7C3 5.89543 3.89543 5 5 5H15C16.1046 5 17 5.89543 17 7V9H3V7Z' fill={color} />
  </svg>
);
export default ScheduleFill20;
