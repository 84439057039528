import { FC } from 'react';

import Dialog from './Dialog';
import Navigation from './Navigation';

export enum LandingType {
  DIALOG = 'DIALOG',
  NAVIGATION = 'NAVIGATION',
}

interface Props {
  type: LandingType;
}

/**
 * 앱 랜딩 알림
 * @component
 */
const AppLandingNotify: FC<Props> = ({ type }) => {
  switch (type) {
    case LandingType.DIALOG:
      return <Dialog />;
    case LandingType.NAVIGATION:
      return <Navigation />;
    default:
      return null;
  }
};

export default AppLandingNotify;
