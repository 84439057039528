import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray900 } from 'styles';

const NavigationBack24: FC<IconProps> = ({ width = 24, height = 24, color = gray900, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
    <path d='M11 3L2 12L11 21' stroke={color} strokeWidth='1.5' />
  </svg>
);
export default NavigationBack24;
