import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray_tertiary } from 'styles';

const Notice20: FC<IconProps> = ({ width = 20, height = 20, color = gray_tertiary, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 20 20' fill='none' {...props}>
    <path
      d='M11 14C11 15.6569 9.65685 17 8 17C6.34315 17 5 15.6569 5 14C5 13.6494 5.06015 13.3128 5.17071 13'
      stroke={color}
      strokeWidth='1.4'
    />
    <path
      d='M8 6V6.7H8.11359L8.22136 6.66408L8 6ZM17 3H17.7V2.0288L16.7786 2.33592L17 3ZM8 13L8.22136 12.3359L8.11359 12.3H8V13ZM17 16L16.7786 16.6641L17.7 16.9712V16H17ZM8.22136 6.66408L17.2214 3.66408L16.7786 2.33592L7.77864 5.33592L8.22136 6.66408ZM4 6.7H8V5.3H4V6.7ZM2.7 8C2.7 7.28203 3.28203 6.7 4 6.7V5.3C2.50883 5.3 1.3 6.50883 1.3 8H2.7ZM2.7 11V8H1.3V11H2.7ZM4 12.3C3.28203 12.3 2.7 11.718 2.7 11H1.3C1.3 12.4912 2.50883 13.7 4 13.7V12.3ZM8 12.3H4V13.7H8V12.3ZM17.2214 15.3359L8.22136 12.3359L7.77864 13.6641L16.7786 16.6641L17.2214 15.3359ZM16.3 3V16H17.7V3H16.3Z'
      fill={color}
    />
    <path d='M5 6V13' stroke={color} strokeWidth='1.4' />
  </svg>
);
export default Notice20;
