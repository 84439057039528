import { ForwardedRef, MutableRefObject, RefCallback, useCallback } from 'react';

type Ref<T> = MutableRefObject<T | null> | RefCallback<T | null> | ForwardedRef<T | null>;

/**
 * 다른 element의 기존 ref를 해치지 않고 복사 할 때 사용 합니다
 *
 * ex 1) useInView 나 useRef 등에 의해 ref 가 이미 점유당한 상황
 * const { ref } = useInView();
 * const new_ref = useRef();
 * const forkRef = useForkElementRef(ref, new_ref);
 * return (
 *   <div {...forkRef()}>{...}</div>
 * );
 *
 * ex 2) forwardRef 와 같이 부모 컴포넌트에서 ref 가 선택적으로 넘어 올 수 있는 상황
 * const Component = forwardRef((props, ref) => {
 *   const inner_ref = useRef();  // 안전하게 inner_ref 를 참조 할 수 있습니다
 *   const forkRef = useForkElementRef(ref, inner_ref);
 *   return (
 *     <div {...forkRef()}>{...}</div>
 *   )
 * })
 *
 * @param refs: 복사할 ref 리스트
 */
export const useForkElementRef = <T extends HTMLElement>(...refs: Array<Ref<T>>) => {
  const setRefs = useCallback<RefCallback<T>>((instance) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(instance);
      } else if (ref) {
        ref.current = instance;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs);
  return useCallback(() => ({ ref: setRefs }), [setRefs]);
};
