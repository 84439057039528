import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray900 } from 'styles';

const BottomLikeP24: FC<IconProps> = ({ width = 24, height = 24, color = gray900, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.5282 6.19406C21.1429 5.34946 20.5756 4.62099 19.8755 4.07199C19.1755 3.523 18.3638 3.17009 17.5113 3.04397C16.3538 2.88997 15.1817 3.14279 14.1634 3.76613C13.3129 4.28284 12.6836 5.07659 12.0004 5.82675C11.3172 5.07659 10.6935 4.27039 9.83742 3.76613C8.81905 3.14279 7.64694 2.88997 6.4895 3.04397C5.63692 3.17009 4.8253 3.523 4.12523 4.07199C3.42516 4.62099 2.85782 5.34946 2.47256 6.19406C1.4662 8.49125 2.1664 11.053 3.63767 12.8709C5.94238 15.6941 6.56651 16.0625 12 21C17.4444 16.0625 18.0357 15.7066 20.3404 12.8709C21.8344 11.053 22.5346 8.49125 21.5282 6.19406Z'
      fill={color}
      stroke={color}
      strokeWidth='1.4'
    />
  </svg>
);
export default BottomLikeP24;
