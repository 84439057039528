import { BaseConfigType } from './default';

const Config: BaseConfigType = {
  site_name: '패션 바이 카카오',
  baseDomain: 'zigzag.kr',
  apiBaseUrl: 'https://fashionbykakao.com/api/store',
  apiConsumerBaseUrl: 'https://fashionbykakao.com/api/2',
  appUrl: 'https://fashionbykakao.com',
  contentUrl: 'https://content.zigzag.kr',
  // assetPrefix: 'https://cf.ec.s.zigzag.kr',
  assetPrefix: '',
  payApiBaseUrl: 'https://api.zigzag.kr/api/user-pay',
  inlineImageLimit: 8192,
  localIdentName: '[hash:base64]',
  simplePayPassword: 'https://api.zigzag.kr/api/security-keypad/static/js/simple_pay/simple_pay_password.js',
  simpleBankAppVersion: '6.6.0',
  sentry_dsn: 'https://f91a64217e594c459955cf3a78a34a31@o314572.ingest.sentry.io/6208248',
  sentry_org: 'zigzag-production',
  kakaoRestApiKey: 'b8eff74ca809c69442797ec2cd49f887',
  kakaoJavascriptKey: 'a646d3f89c20d25dbf2b6d61c9956b5a',
  live_player_access_key: 'dIjVRVKEX4svFNBhXvpn',
  aasaDestination: '/.well-known/apple-app-site-association.prod',
  ga_id: 'G-7J9Q5CZNYW',
  fbq_id: '5618606671500405',
  app_scheme: 'fbk',
  // 카카오톡 메세지 친구초대 템플릿 템플릿 id
  kakao_invite_friend_template_id: 83272,
  // 친구초대 쿠폰 id
  invite_code_welcome_coupon_id: '1680524',
  // 카카오톡 메세지 공유왕 템플릿 id
  kakao_share_king_template_id: 84222,
  // 앱 다이나믹 딥링크
  dynamic_deep_link: 'https://fashionbykakao.page.link',
  // IOS APP 설치 id
  ios_app_store_id: '653403166',
  // 파이어베이스 키값
  firebase_web_key: 'AIzaSyAp5QzeMiJP1ay9bxTsC83uf4HppuTNK2k',
  zendesk_url: 'https://fashionbykakao.zendesk.com/hc/p/requests-with-sso',
  // 회원가입 웰컴 쿠폰 id
  register_complete_welcome_coupon_id: '2b7d7af4-9b38-44f2-967c-3d644cbb7b14',
  // 랜덤혜택 페이지 uuid
  random_benefit_content_uuid: '55e85698-642d-44fb-a8e4-1f111e3b32ff',
  // 랜덤혜택 메타데이터 uuid
  random_benefit_metadata_uuid: '71d3ec04-caad-4047-8994-1f689dec927b',
  // 키즈 카테고리 id
  kid_category_id: '2187',
  // '전체' 상품 카테고리 id
  root_product_category_id: '1112',
  // 에어브릿지 앱 이름
  airbridge_app_name: 'fbk',
  // 에어브릿지 웹 SDK 토큰
  airbridge_web_token: '48cbd1b50915459fbff9c35d09bdf383',
  // 에어브릿지 url
  airbridge_url: 'https://abr.ge/@fbk',
  // 에어브릿지 공유 채널
  airbridge_share_channel: 'in_app_share',
};

export default Config;
