import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray600 } from 'styles';

const Search16: FC<IconProps> = ({ size = 16, color = gray600, ...props }) => {
  return (
    <svg width={size} height={size} color={color} viewBox='0 0 16 16' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.69306 4.26546C11.2471 5.81946 11.2471 8.339 9.69306 9.89301C8.13905 11.447 5.61951 11.447 4.0655 9.89301C2.5115 8.339 2.5115 5.81946 4.0655 4.26546C5.61951 2.71145 8.13905 2.71145 9.69306 4.26546ZM11.2729 10.1844C12.7608 8.08446 12.5642 5.15667 10.683 3.27551C8.58227 1.17477 5.17629 1.17477 3.07555 3.27551C0.974815 5.37625 0.974815 8.78222 3.07555 10.883C5.05975 12.8672 8.20837 12.9772 10.3218 11.2132L13.5578 14.4493L14.5478 13.4593L11.2729 10.1844Z'
        fill={color}
      />
    </svg>
  );
};

export default Search16;
