import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray500 } from 'styles';

const ArrowDown16: FC<IconProps> = ({ width = 16, height = 16, color = gray500, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 16 16' fill='none' {...props}>
    <path d='M2.5 5.25L8 10.75L13.5 5.25' stroke={color} strokeWidth='1.4' />
  </svg>
);
export default ArrowDown16;
