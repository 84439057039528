import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray600 } from 'styles';

const BottomHomeN24: FC<IconProps> = ({ width = 24, height = 24, color = gray600, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
    <path d='M3.5 21.5V9.5L12 3L20.5 9.5V21.5H3.5Z' stroke={color} strokeWidth='1.4' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 15C12.8284 15 13.5 15.6716 13.5 16.5V22H10.5V16.5C10.5 15.6716 11.1716 15 12 15Z'
      fill={color}
    />
  </svg>
);
export default BottomHomeN24;
