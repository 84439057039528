import React, { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { yellow_review } from 'styles';

const Star16: FC<IconProps> = ({ width = 16, height = 16, color = yellow_review, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 16 16' fill='none' {...props}>
    <path
      d='M7.70147 1.93431C7.82404 1.68763 8.17594 1.68763 8.29851 1.93431L10.0864 5.53264C10.1349 5.63033 10.2282 5.69809 10.3361 5.71406L14.3107 6.30246C14.5832 6.3428 14.692 6.67748 14.4952 6.87027L11.6255 9.68256C11.5476 9.75891 11.512 9.86855 11.5301 9.97611L12.1988 13.9381C12.2446 14.2097 11.9599 14.4165 11.7158 14.289L8.15432 12.4288C8.05763 12.3783 7.94235 12.3783 7.84566 12.4288L4.28422 14.289C4.04006 14.4165 3.75537 14.2097 3.80121 13.9381L4.46986 9.97611C4.48801 9.86855 4.45239 9.75891 4.37448 9.68256L1.50474 6.87027C1.308 6.67748 1.41674 6.3428 1.68923 6.30246L5.66392 5.71406C5.77183 5.69809 5.86509 5.63033 5.91363 5.53264L7.70147 1.93431Z'
      fill={color}
    />
  </svg>
);
export default Star16;
