import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { gray700, white } from 'styles';

const Share16: FC<IconProps> = ({ size = 16, color = gray700, sub_color = white, ...props }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 16 16' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0387 5.24262C9.68796 4.92992 9.43801 4.50656 9.34366 4.02734L5.26589 6.3575C5.61659 6.67025 5.86648 7.09365 5.96075 7.57289L10.0387 5.24262ZM5.96068 8.42734C5.86633 8.90655 5.61638 9.32991 5.26562 9.64261L9.34359 11.9729C9.43786 11.4936 9.68775 11.0702 10.0384 10.7575L5.96068 8.42734Z'
        fill={color}
      />
      <mask id='path-2-inside-1_3825_4734' fill={sub_color}>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.7016 3.60002C13.7016 4.81505 12.7166 5.80002 11.5016 5.80002C10.2865 5.80002 9.30156 4.81505 9.30156 3.60002C9.30156 2.385 10.2865 1.40002 11.5016 1.40002C12.7166 1.40002 13.7016 2.385 13.7016 3.60002ZM6.00156 8.00002C6.00156 9.21505 5.01659 10.2 3.80156 10.2C2.58654 10.2 1.60156 9.21505 1.60156 8.00002C1.60156 6.785 2.58654 5.80002 3.80156 5.80002C5.01659 5.80002 6.00156 6.785 6.00156 8.00002ZM11.5016 14.6C12.7166 14.6 13.7016 13.6151 13.7016 12.4C13.7016 11.185 12.7166 10.2 11.5016 10.2C10.2865 10.2 9.30156 11.185 9.30156 12.4C9.30156 13.6151 10.2865 14.6 11.5016 14.6Z'
        />
      </mask>
      <path
        d='M11.5016 7.20002C13.4898 7.20002 15.1016 5.58825 15.1016 3.60002H12.3016C12.3016 4.04185 11.9434 4.40002 11.5016 4.40002V7.20002ZM7.90156 3.60002C7.90156 5.58825 9.51334 7.20002 11.5016 7.20002V4.40002C11.0597 4.40002 10.7016 4.04185 10.7016 3.60002H7.90156ZM11.5016 2.44379e-05C9.51334 2.44379e-05 7.90156 1.6118 7.90156 3.60002H10.7016C10.7016 3.1582 11.0597 2.80002 11.5016 2.80002V2.44379e-05ZM15.1016 3.60002C15.1016 1.6118 13.4898 2.44379e-05 11.5016 2.44379e-05V2.80002C11.9434 2.80002 12.3016 3.1582 12.3016 3.60002H15.1016ZM3.80156 11.6C5.78979 11.6 7.40156 9.98825 7.40156 8.00002H4.60156C4.60156 8.44185 4.24339 8.80002 3.80156 8.80002V11.6ZM0.201563 8.00002C0.201563 9.98825 1.81334 11.6 3.80156 11.6V8.80002C3.35974 8.80002 3.00156 8.44185 3.00156 8.00002H0.201563ZM3.80156 4.40002C1.81334 4.40002 0.201563 6.0118 0.201563 8.00002H3.00156C3.00156 7.5582 3.35973 7.20002 3.80156 7.20002V4.40002ZM7.40156 8.00002C7.40156 6.0118 5.78979 4.40002 3.80156 4.40002V7.20002C4.24339 7.20002 4.60156 7.5582 4.60156 8.00002H7.40156ZM12.3016 12.4C12.3016 12.8419 11.9434 13.2 11.5016 13.2V16C13.4898 16 15.1016 14.3882 15.1016 12.4H12.3016ZM11.5016 11.6C11.9434 11.6 12.3016 11.9582 12.3016 12.4H15.1016C15.1016 10.4118 13.4898 8.80002 11.5016 8.80002V11.6ZM10.7016 12.4C10.7016 11.9582 11.0597 11.6 11.5016 11.6V8.80002C9.51334 8.80002 7.90156 10.4118 7.90156 12.4H10.7016ZM11.5016 13.2C11.0597 13.2 10.7016 12.8419 10.7016 12.4H7.90156C7.90156 14.3882 9.51334 16 11.5016 16V13.2Z'
        fill={color}
        mask='url(#path-2-inside-1_3825_4734)'
      />
    </svg>
  );
};

export default Share16;
