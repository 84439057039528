import { useLoginDialog } from 'components/auth/Login/LoginDialog/useLoginDialog';
import { useUserInfo } from 'components/my-page/hooks/useUserInfo';
import { useInviteCodeInfo } from 'queries/invite_friends/useInviteCodeInfo';
import { makeOneLink } from 'util/app_utils';
import config from 'util/config';
import { Toast } from 'util/Toast';

/**
 *
 * @hook 카카오톡 공유하기 hook
 * @description
 *  카카오톡 공유하기 기능을 여러 페이지에서 사용할 수 있기 때문에 이를 위해
 *  공유하기 기능을 해당 hook 에서 집중적으로 관리하도록 합니다.
 * @example
 *  const { shareInvteCode } = useKakaoShare();
 *  const handleShareInviteCodeClick = () => shareInviteCode();
 */
export const useKakaoShare = () => {
  const {
    invite_code_info: { invite_code },
  } = useInviteCodeInfo();
  const { is_login, user_info } = useUserInfo();
  const { openLoginDialog } = useLoginDialog();

  const shareInviteCode = async () => {
    if (!is_login) {
      openLoginDialog();
      return;
    }
    shareInviteCodeForkakao(invite_code, user_info?.full_name ?? '');
  };

  return {
    shareInviteCode,
  };
};

export function shareInviteCodeForkakao(invite_code: string, user_name: string) {
  const path = `/invite-friends?sheet_type=login&invite_code=${invite_code}`;
  const deep_link_path = makeOneLink(path);

  const Kakao = (window as any).Kakao;

  if (!Kakao) {
    Toast.show('카카오톡 설치 후 이용해 주세요.');
    return;
  }

  Kakao.Share.sendCustom({
    templateId: config.kakao_invite_friend_template_id,
    templateArgs: {
      user_name: user_name,
      invite_code,
      web_url: `${path}`,
      mobile_url: deep_link_path,
    },
  });
}
