import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { border1, border2, gray050 } from 'styles';

interface Props extends IconProps {
  third_color?: string;
}

const Bank40: FC<Props> = ({
  width = 40,
  height = 40,
  color = border1,
  sub_color = border2,
  third_color = gray050,
  ...props
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 40 40' fill='none' {...props}>
      <path
        d='M5.6 12C5.6 11.2268 6.2268 10.6 7 10.6H33C33.7732 10.6 34.4 11.2268 34.4 12V26C34.4 27.8778 32.8778 29.4 31 29.4H9C7.12223 29.4 5.6 27.8778 5.6 26V12Z'
        fill={third_color}
        stroke={color}
        strokeWidth='1.2'
      />
      <rect x='8.5' y='14' width='23' height='3' fill={sub_color} />
    </svg>
  );
};

export default Bank40;
