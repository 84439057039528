import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { gray500 } from 'styles';

const Cancel20: FC<IconProps> = ({ width = 20, height = 20, color = gray500, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' {...props}>
      <path d='M16 4C15.6 4.4 7.83333 12.1667 4 16' stroke={color} strokeWidth='1.4' />
      <path d='M16 16C15.6 15.6 7.83333 7.83333 4 4' stroke={color} strokeWidth='1.4' />
    </svg>
  );
};

export default Cancel20;
