import { FC } from 'react';

import { IconProps } from 'components/base/Icon';

const ShareFacebook32: FC<IconProps> = ({ size = 32, ...props }) => {
  return (
    <svg width={size} height={size} fill='none' viewBox='0 0 32 32' {...props}>
      <circle cx='16' cy='16' r='16' fill='#1877F2' />
      <path
        d='M19.9103 16.9601L20.3767 13.7556H17.4589V11.6761C17.4589 10.7994 17.8666 9.94473 19.1734 9.94473H20.5V7.21652C20.5 7.21652 19.2961 7 18.145 7C15.7419 7 14.1712 8.53472 14.1712 11.3132V13.7556H11.5V16.9601H14.1712V26C14.7158 26 15.2147 26 15.774 26C16.3333 26 16.9144 26 17.4589 26V16.9601H19.9103Z'
        fill='white'
      />
    </svg>
  );
};

export default ShareFacebook32;
