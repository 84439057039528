import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { black } from 'styles';

const Basket24: FC<IconProps> = ({ width = 24, height = 24, color = black, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M3.7 6.2H20.3V20C20.3 20.718 19.718 21.3 19 21.3H5C4.28203 21.3 3.7 20.718 3.7 20V6.2Z'
      stroke='black'
      strokeWidth='1.4'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.4 11.0001V6.0001C16.4 3.57004 14.43 1.6001 12 1.6001C9.56992 1.6001 7.59998 3.57004 7.59998 6.0001V11.0001H8.99998V6.0001C8.99998 4.34324 10.3431 3.0001 12 3.0001C13.6568 3.0001 15 4.34324 15 6.0001V11.0001H16.4Z'
      fill={color}
    />
  </svg>
);
export default Basket24;
