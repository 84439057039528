import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';

import ToastBar from 'components/toolbox/croquis/ToastBar';
import { isNativeAppVersion } from 'hooks/app/useAppVersion';
import { NAVIGATION_BAR_HEIGHT } from 'styles/layout';
import createOverlayComp, { OverlayCompProps } from 'util/overlay.ts';

const AnimatedToastBar = motion(ToastBar);

interface ToastComponentProps {
  message?: string;
  /**
   *  @description 등장 시간
   * @default 1500ms
   */
  time?: number;
  /**
   *  @description 토스트 하단 position 조정
   * @default 52
   */
  bottom_floating_position?: number;
  onConfirm?: () => void;
}

function ToastComponent({
  message,
  resolve,
  dispose,
  time = 1500,
  bottom_floating_position = 52,
  onConfirm,
}: ToastComponentProps & OverlayCompProps) {
  const [active, setActive] = useState(true);

  return (
    <AnimatePresence>
      {active && (
        <AnimatedToastBar
          message={message}
          bottom={getBottomFloatingPosition(bottom_floating_position)}
          onConfirm={onConfirm}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onAnimationComplete={(value: any) => {
            if (value?.opacity === 1) {
              setTimeout(() => {
                setActive(false);
              }, time);
            }
            if (value?.opacity === 0) {
              resolve?.();
              dispose?.();
            }
          }}
        />
      )}
    </AnimatePresence>
  );
}

function getBottomFloatingPosition(default_position: number) {
  if (typeof window === 'undefined') {
    return default_position;
  }

  const is_native_app_version = isNativeAppVersion(window.navigator.userAgent);

  if (is_native_app_version) {
    return default_position - NAVIGATION_BAR_HEIGHT;
  }

  return default_position;
}

export function renderToast(props: ToastComponentProps) {
  return ({ resolve, dispose }: OverlayCompProps) => {
    return <ToastComponent {...{ ...props, resolve, dispose }} />;
  };
}

export type ConfirmReturnValue = true;
const openToast = createOverlayComp(renderToast);

export class Toast {
  static show(message: string, props?: Omit<ToastComponentProps, 'message'>) {
    return openToast({ message, ...props });
  }
}
