import { FC } from 'react';
import { IconProps } from 'components/base/Icon';

const Fbk44: FC<IconProps> = ({ width = 44, height = 44, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 44 44' fill='none' {...props}>
    <g clip-path='url(#clip0_225_3133)'>
      <circle cx='22.5449' cy='22' r='22' fill='#F4F4F4' />
      <path
        d='M33.3904 26.8707L22.1218 18.2205C20.5677 16.9503 20.5961 16.2762 20.6245 15.5666C20.6456 15.2352 20.734 14.9115 20.8845 14.6154C21.035 14.3193 21.2443 14.057 21.4996 13.8446C21.7549 13.6323 22.0509 13.4742 22.3694 13.3802C22.688 13.2861 23.0223 13.258 23.3521 13.2977C23.6818 13.3373 24 13.4439 24.2872 13.6107C24.5743 13.7776 24.8244 14.0013 25.0221 14.2682C25.2198 14.5351 25.361 14.8394 25.437 15.1628C25.513 15.4861 25.5222 15.8215 25.4641 16.1485C25.4151 16.4007 25.4684 16.662 25.6121 16.8749C25.7558 17.0878 25.9782 17.2349 26.2304 17.2839C26.4826 17.3328 26.7439 17.2795 26.9568 17.1358C27.1698 16.9921 27.3169 16.7697 27.3658 16.5175C27.4878 15.9231 27.4851 15.3099 27.3579 14.7166C27.2307 14.1233 26.9818 13.5629 26.6269 13.0708C26.272 12.5786 25.8188 12.1655 25.296 11.8575C24.7733 11.5495 24.1923 11.3533 23.5898 11.2814C22.9873 11.2095 22.3764 11.2634 21.7959 11.4396C21.2153 11.6159 20.6776 11.9108 20.2168 12.3055C19.756 12.7003 19.3822 13.1864 19.1189 13.7331C18.8556 14.2797 18.7086 14.8751 18.6873 15.4815C18.6305 16.6736 18.7653 17.9864 20.9155 19.7391L32.0563 28.2544C32.2905 28.5028 32.1841 28.7795 31.9428 28.7795H13.2162C12.9678 28.7795 12.8685 28.5028 13.1027 28.2544L20.2413 22.8046C20.4097 22.6366 20.5106 22.4127 20.525 22.1752C20.5393 21.9378 20.4661 21.7033 20.3192 21.5162C20.1724 21.3291 19.962 21.2024 19.7279 21.16C19.4939 21.1175 19.2524 21.1624 19.0492 21.2861L11.7544 26.8707C11.3706 27.1715 11.0943 27.5882 10.9664 28.0587C10.8386 28.5292 10.866 29.0284 11.0448 29.482C11.2319 29.8819 11.535 30.2162 11.9147 30.4415C12.2943 30.6667 12.733 30.7725 13.1736 30.7452H31.9002C32.3407 30.7716 32.7789 30.6653 33.1584 30.4402C33.5379 30.215 33.8411 29.8813 34.029 29.482C34.2145 29.0355 34.2526 28.5414 34.1377 28.0717C34.0228 27.602 33.761 27.1813 33.3904 26.8707Z'
        fill='#D3D3D3'
      />
    </g>
    <defs>
      <clipPath id='clip0_225_3133'>
        <rect x='0.544922' width='44' height='44' rx='22' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export default Fbk44;
