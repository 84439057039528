import { FC } from 'react';
import { IconProps } from './index';
import { black } from 'styles';

const Camera24: FC<IconProps> = ({ width = 24, height = 24, color = black, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17 3.4H7C5.01178 3.4 3.4 5.01178 3.4 7V17C3.4 18.9882 5.01178 20.6 7 20.6H17C18.9882 20.6 20.6 18.9882 20.6 17V7C20.6 5.01178 18.9882 3.4 17 3.4ZM7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7ZM12 14.6909C13.4861 14.6909 14.6909 13.4861 14.6909 12C14.6909 10.5139 13.4861 9.30909 12 9.30909C10.5139 9.30909 9.30909 10.5139 9.30909 12C9.30909 13.4861 10.5139 14.6909 12 14.6909ZM12 16.0909C14.2593 16.0909 16.0909 14.2593 16.0909 12C16.0909 9.74065 14.2593 7.90909 12 7.90909C9.74065 7.90909 7.90909 9.74065 7.90909 12C7.90909 14.2593 9.74065 16.0909 12 16.0909ZM6.55005 5.3C5.85969 5.3 5.30005 5.85964 5.30005 6.55C5.30005 7.24036 5.85969 7.8 6.55005 7.8C7.2404 7.8 7.80005 7.24036 7.80005 6.55C7.80005 5.85964 7.2404 5.3 6.55005 5.3Z'
        fill={color}
      />
    </svg>
  );
};

export default Camera24;
