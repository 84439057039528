import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { gray500, white } from 'styles';

const KRWCoin20: FC<IconProps> = ({ width = 20, height = 20, color = gray500, sub_color = white, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' {...props}>
      <g clipPath='url(#clip0_4285_37736)'>
        <circle cx='10' cy='10' r='8' stroke={color} strokeWidth='1.4' />
        <path
          d='M6 7.5L8.28571 12.5L10 7.75L11.7143 12.5L14 7.5'
          stroke={color}
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M5 10H7' stroke={color} strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M13 10H15' stroke={color} strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_4285_37736'>
          <rect width={width} height={height} fill={sub_color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KRWCoin20;
