import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { pink600 } from 'styles';

const Check16: FC<IconProps> = ({ width = 16, height = 16, color = pink600, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 16 16' fill='none' {...props}>
    <path d='M2 6.5L6.5 11L13.5 4' stroke={color} strokeWidth='1.4' />
  </svg>
);
export default Check16;
