import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray600 } from 'styles';

const Arrive24: FC<IconProps> = ({ width = 24, height = 24, color = gray600, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' color={color} {...props}>
    <path fill='currentColor' fillRule='nonzero' d='M8 16.118V22H6V3l15.401 6.417L8 16.118zM8 6v7.882l8.599-4.3L8 6z' />
  </svg>
);
export default Arrive24;
