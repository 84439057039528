import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const Describe60: FC<IconProps> = ({ size = 60, color = colors.gray500, ...props }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 60 60' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.5363 11.9609C20.5751 11.9609 12.5 20.0361 12.5 29.9973C12.5 39.9585 20.5751 48.0336 30.5363 48.0336C40.4975 48.0336 48.5727 39.9585 48.5727 29.9973C48.5727 20.0361 40.4975 11.9609 30.5363 11.9609ZM10.5 29.9973C10.5 18.9315 19.4706 9.96094 30.5363 9.96094C41.6021 9.96094 50.5727 18.9315 50.5727 29.9973C50.5727 41.063 41.6021 50.0336 30.5363 50.0336C19.4706 50.0336 10.5 41.063 10.5 29.9973Z'
        fill={color}
      />
      <path d='M30.501 27L30.501 39' stroke={color} strokeWidth='2' />
      <circle cx='30.5' cy='22.5' r='1.5' fill={color} />
    </svg>
  );
};

export default Describe60;
