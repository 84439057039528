import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { blue600 } from 'styles';

const ZigShipping20: FC<IconProps> = ({ size = 20, color = blue600, ...props }) => {
  return (
    <svg width={size} height={size} fill='none' viewBox='0 0 20 20' {...props}>
      <path d='M0 4.25H12C12.5523 4.25 13 4.69772 13 5.25V13.25' stroke={color} strokeWidth='1.2' />
      <path d='M1 7.25L6 7.25' stroke={color} strokeWidth='1.2' />
      <path
        d='M12.9993 7.25L15.9997 7.25H17C18.1046 7.25 19 8.14543 19 9.25V13.25L4.4999 13.25L4.4999 10.25H2.5'
        stroke={color}
        strokeWidth='1.2'
      />
      <path
        d='M9 14.25C9 15.0784 8.32843 15.75 7.5 15.75C6.67157 15.75 6 15.0784 6 14.25C6 13.8658 6 13.75 6 13.25C6.5 13.25 7.05585 13.25 7.5001 13.25C7.94435 13.25 8.5 13.25 9.0001 13.25C9 13.75 9 13.8658 9 14.25Z'
        stroke={color}
        strokeWidth='1.2'
      />
      <path
        d='M17 14.25C17 15.0784 16.3284 15.75 15.5 15.75C14.6716 15.75 14 15.0784 14 14.25C14 13.8658 14 13.75 14 13.25C14.5 13.25 15.0558 13.25 15.5001 13.25C15.9443 13.25 16.5 13.25 17.0001 13.25C17 13.75 17 13.8658 17 14.25Z'
        stroke={color}
        strokeWidth='1.2'
      />
    </svg>
  );
};

export default ZigShipping20;
