import { atom } from 'jotai';
import { DeviceType } from 'util/device';

/**
 * @description
 * device 정보 저장
 */
export const device_info_atom = atom<DeviceType>({
  platform: 'Web',
  app_version: '',
  os_version: '',
  os_version_full: '',
  app_version_full: '',
  is_app: false,
  is_ios: false,
  is_aos: false,
  is_mobile: false,
  user_agent: '',
});
