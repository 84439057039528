import { FC } from 'react';

import { IconProps } from 'components/base/Icon';

const ShareKakaoTalk32: FC<IconProps> = ({ size = 32, ...props }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 32 32' fill='none' {...props} xmlns='http://www.w3.org/2000/svg'>
      <rect width={size} height={size} rx='16' fill='#FAE300' />
      <g clipPath='url(#clip0_205_9894)'>
        <path
          d='M16 8C11.0293 8 7 11.1854 7 15.115C7 17.6728 8.7074 19.9141 11.2694 21.1683C11.0811 21.8714 10.5878 23.7144 10.4889 24.1089C10.3672 24.5985 10.6684 24.5919 10.8657 24.46C11.0205 24.3569 13.3321 22.7857 14.3294 22.1073C14.8707 22.1875 15.4288 22.2299 16 22.2299C20.9707 22.2299 25 19.044 25 15.115C25 11.1859 20.9707 8 16 8Z'
          fill='#391B1B'
        />
        <path
          d='M12.3782 13.0197H9.74667C9.49909 13.0197 9.29907 13.2201 9.29907 13.4673C9.29907 13.7144 9.49956 13.9149 9.74667 13.9149H10.6022V17.0676C10.6022 17.1823 10.6489 17.2952 10.7309 17.3767C10.8111 17.4579 10.9235 17.5045 11.0386 17.5045H11.1342C11.2489 17.5045 11.3608 17.4579 11.4415 17.3767C11.5235 17.2952 11.5702 17.1828 11.5702 17.0676V13.9149H12.3782C12.6258 13.9149 12.8262 13.7144 12.8262 13.4673C12.8262 13.2201 12.6258 13.0197 12.3782 13.0197V13.0197Z'
          fill='#FAE300'
        />
        <path
          d='M18.7034 16.6624H17.4795V13.4425C17.4795 13.174 17.2622 12.9567 16.9942 12.9567C16.7261 12.9567 16.5088 13.174 16.5088 13.4425V16.9133C16.5088 16.9403 16.5121 16.9655 16.5162 16.9911C16.5116 17.0163 16.5088 17.0424 16.5088 17.0685C16.5088 17.2933 16.6902 17.4751 16.9149 17.4751H18.7034C18.9286 17.4751 19.1095 17.2933 19.1095 17.0685C19.1095 16.8438 18.9282 16.6624 18.7034 16.6624V16.6624Z'
          fill='#FAE300'
        />
        <path
          d='M22.6143 16.8102L21.2505 15.0189L22.4828 13.7862C22.6498 13.6197 22.6498 13.3488 22.4828 13.1819C22.3159 13.015 22.0455 13.015 21.8781 13.1819L20.3525 14.7075V13.4425C20.3525 13.174 20.1353 12.9567 19.8672 12.9567C19.5991 12.9567 19.3813 13.174 19.3813 13.4425V17.0201C19.3813 17.2886 19.5986 17.5059 19.8672 17.5059C20.1357 17.5059 20.3525 17.2891 20.3525 17.0201V15.9164L20.63 15.6395L21.9201 17.3324C22.0637 17.5241 22.3364 17.5637 22.5281 17.4187C22.7197 17.2746 22.7584 17.0023 22.6143 16.8102V16.8102Z'
          fill='#FAE300'
        />
        <path
          d='M16.145 16.9263L14.8334 13.3381C14.8334 13.3381 14.8301 13.3353 14.8292 13.3339C14.7453 13.1152 14.5033 12.9562 14.2147 12.9562C13.8944 12.9562 13.6296 13.1521 13.5769 13.408C13.568 13.4271 13.5592 13.4463 13.5517 13.4668L12.262 16.9263C12.1823 17.1534 12.3017 17.4015 12.5278 17.4812C12.7544 17.5609 13.0029 17.4416 13.0826 17.215L13.306 16.5781H15.101L15.3239 17.215C15.4036 17.4416 15.6521 17.5609 15.8787 17.4812C16.1053 17.4015 16.2247 17.1534 16.145 16.9263ZM13.5909 15.7645L14.1942 14.0431C14.2012 14.0431 14.2068 14.0449 14.2138 14.0449L14.8166 15.7645H13.5913H13.5909Z'
          fill='#FAE300'
        />
      </g>
      <defs>
        <clipPath id='clip0_205_9894'>
          <rect width='18' height='16.5364' fill='white' transform='translate(7 8)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShareKakaoTalk32;
