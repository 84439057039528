import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray600 } from 'styles';

const Shipping24: FC<IconProps> = ({ width = 24, height = 24, color = gray600, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' color={color} {...props}>
    <path
      fill='currentColor'
      fillRule='nonzero'
      d='M22 10.09c0 3.246-1.81 6.412-4.79 9.416a30.671 30.671 0 0 1-4.256 3.558c-.195.135-.337.228-.417.28l-.537.341-.537-.341a28.333 28.333 0 0 1-1.475-1.047 30.671 30.671 0 0 1-3.198-2.791C3.81 16.502 2 13.336 2 10.09 2 4.783 6.487.5 12 .5s10 4.283 10 9.59zm-9.199 10.615a28.696 28.696 0 0 0 2.99-2.608C18.434 15.432 20 12.693 20 10.09 20 5.909 16.428 2.5 12 2.5s-8 3.41-8 7.59c0 2.603 1.565 5.342 4.21 8.007A28.696 28.696 0 0 0 12 21.292c.247-.174.516-.37.801-.587zM12 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z'
    />
  </svg>
);
export default Shipping24;
