import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { IconCancel20 } from 'components/base/Icon';
import Text from 'components/base/Text';

export interface HeaderProps {
  /**
   * 바텀싯 타이틀을 정의합니다.
   */
  title?: string;
  /**
   * 바텀싯 타이틀이 center 정렬인지 정의합니다.
   * @default true
   */
  is_title_center?: boolean;
  /**
   * 닫기 버튼을 표시할지 정의합니다.
   */
  show_close_icon?: boolean;
  /**
   * handlebar를 사용할지 정의합니다.
   */
  use_handlebar?: boolean;
}

interface Props extends HeaderProps {
  /**
   * 바텀싯을 종료를 위한 이벤트
   */
  onClose?: () => void;
}

/**
 * Bottom Dialog에서 사용되는 헤더를 정의한 컴포넌트
 * @component
 */
const Header = forwardRef<HTMLDivElement, Props>(
  ({ use_handlebar, title, is_title_center = true, show_close_icon, onClose }, ref) => {
    const is_show_header = title || show_close_icon || use_handlebar;

    if (!is_show_header) {
      return <SC.Header is_default />;
    }

    if (use_handlebar) {
      return (
        <SC.Header className='has_handlbar' ref={ref}>
          <SC.HandleBar />
        </SC.Header>
      );
    }

    return (
      <SC.Header className={cx({ only_close_button: !title && show_close_icon })}>
        {title && (
          <SC.DialogTitle
            center={is_title_center}
            variant='title4_bold'
            className={cx({ has_close_icon: show_close_icon })}
          >
            {title}
          </SC.DialogTitle>
        )}
        {show_close_icon && <IconCancel20 onClick={onClose} />}
      </SC.Header>
    );
  },
);

export default Header;

const SC = {
  Header: styled.div<{ is_default?: boolean }>`
    padding: 0 16px;
    height: ${({ is_default }) => (is_default ? '16px' : '52px')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: ${({ is_default }) => (is_default ? '0' : '4px')};

    &.has_handlbar {
      justify-content: center;
      height: 32px;
      margin-top: 0;
    }

    &.only_close_button {
      justify-content: flex-end;
    }
  `,
  DialogTitle: styled(Text)<{ center: boolean }>`
    display: flex;
    width: 100%;
    justify-content: ${({ center }) => (center ? 'center' : 'left')};

    &.has_close_icon {
      margin-left: 20px;
    }
  `,
  HandleBar: styled.div`
    background: #e9e9e9;
    border-radius: 2px;
    width: 52px;
    height: 4px;
  `,
};
