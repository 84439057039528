import { FC } from 'react';

import { white } from '../../../styles';
import { IconProps } from './index';

const CircleLoading24: FC<IconProps> = ({ width = 24, height = 24, color = white, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
      <g opacity='0.2' clipPath='url(#clip0_4332_49778)'>
        <path
          d='M10.886 2.06125C11.1496 2.03207 11.414 2.10882 11.6211 2.2746C11.8281 2.44038 11.9608 2.68162 11.99 2.94525C12.0192 3.20887 11.9424 3.47329 11.7766 3.68033C11.6109 3.88737 11.3696 4.02007 11.106 4.04925C9.07764 4.2816 7.21483 5.28056 5.899 6.84158C4.58316 8.40261 3.91385 10.4076 4.0281 12.446C4.14236 14.4844 5.03153 16.4021 6.51357 17.8063C7.99561 19.2105 9.95838 19.995 12 19.9992V21.9992C6.477 21.9992 2 17.5232 2 11.9992C2 6.89325 5.848 2.62125 10.886 2.06225V2.06125Z'
          fill='url(#paint0_linear_4332_49778)'
        />
        <path
          d='M16.322 4.04002C16.4737 3.82259 16.7056 3.67432 16.9667 3.62781C17.2277 3.58131 17.4965 3.64037 17.714 3.79202C19.0379 4.71257 20.1193 5.93997 20.8656 7.36934C21.612 8.7987 22.0012 10.3875 22 12C22 17.523 17.523 22 12 22V20C13.7035 20.0003 15.3626 19.4567 16.7358 18.4485C18.1089 17.4403 19.1244 16.0201 19.6343 14.3947C20.1442 12.7693 20.122 11.0236 19.5708 9.41168C19.0197 7.79978 17.9684 6.40591 16.57 5.43302C16.4622 5.35788 16.3703 5.26223 16.2994 5.15154C16.2286 5.04085 16.1803 4.91729 16.1573 4.78792C16.1343 4.65855 16.137 4.52591 16.1652 4.39758C16.1935 4.26925 16.2468 4.14775 16.322 4.04002Z'
          fill='url(#paint1_linear_4332_49778)'
        />
      </g>
      <defs>
        <linearGradient id='paint0_linear_4332_49778' x1='2' y1='10.5' x2='13' y2='22' gradientUnits='userSpaceOnUse'>
          <stop />
          <stop offset='1' stopOpacity='0.5' />
        </linearGradient>
        <linearGradient id='paint1_linear_4332_49778' x1='12' y1='22' x2='20.5' y2='4' gradientUnits='userSpaceOnUse'>
          <stop stopOpacity='0.53' />
          <stop offset='1' stopOpacity='0' />
        </linearGradient>
        <clipPath id='clip0_4332_49778'>
          <rect width='24' height='24' fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CircleLoading24;
