import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { gray500 } from 'styles';

const Point20: FC<IconProps> = ({ width = 20, height = 20, color = gray500, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.85247 2.4C5.73659 2.4 2.4 5.73659 2.4 9.85247C2.4 13.9684 5.73659 17.3049 9.85247 17.3049C13.9684 17.3049 17.3049 13.9684 17.3049 9.85247C17.3049 5.73659 13.9684 2.4 9.85247 2.4ZM1 9.85247C1 4.96339 4.96339 1 9.85247 1C14.7416 1 18.705 4.96339 18.705 9.85247C18.705 14.7416 14.7416 18.705 9.85247 18.705C4.96339 18.705 1 14.7416 1 9.85247ZM7.13948 13.8308V11.426V10.726V6.39356V5.88801V5.69356H7.83948H10.5111C12.0941 5.69356 13.3774 6.97681 13.3774 8.55977C13.3774 10.1427 12.0941 11.426 10.5111 11.426H8.53948V13.8308H7.13948ZM8.53948 10.026H10.5111C11.3209 10.026 11.9774 9.36955 11.9774 8.55977C11.9774 7.75 11.3209 7.09356 10.5111 7.09356H8.53948V10.026Z'
        fill={color}
      />
    </svg>
  );
};

export default Point20;
