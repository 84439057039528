import React, { ReactText, useMemo, useState } from 'react';
import BottomSheet, { DialogOption } from 'components/common/BottomSheet';
import { useAppVersion } from 'hooks/app/useAppVersion';
import { AppAlertInfo, callAlert, callBottomSheet } from 'util/app_utils';

export interface OpenDialogType extends DialogOption {
  localstorage_key?: string;
  use_native_sheet?: boolean;
  sheet_type?: 'image' | 'icon';
  src?: string;
}

export enum CONFIRM_ID {
  CANCEL = 'CANCEL',
  OK = 'OK',
}

const BottomDialogContext = React.createContext<{
  open: (options: OpenDialogType) => Promise<unknown>;
  close: () => void;
  handleSubmit: (id: ReactText) => void;
}>({
  open: Promise.resolve,
  close: () => {},
  handleSubmit: () => {},
});

/**
 * bottom Dialog 사용을 위한 훅
 * @example
 * const { open } = useBottomDialog();
 *
 * const onButtonClick = async () => {
 * const d = await open({ title: 'hi',text: 'text' });
 * console.log('종료됨', d);
 */
export const useBottomDialog = () => React.useContext(BottomDialogContext);

export function BottomDialogProvider({ children }: { children: React.ReactNode }) {
  const { is_native_version } = useAppVersion();
  const awaitingPromiseRef =
    React.useRef<{
      resolve: (id: ReactText) => void;
      reject: () => void;
    }>();

  const [option, setOption] = useState<DialogOption>();
  const buttons = useMemo(() => {
    return option?.buttons?.map((value, index) => ({
      ...value,
      key: index,
      onClick: () => {
        value.onClick?.();
        handleSubmit(value.id ?? index);
      },
    }));
  }, [option?.buttons]);

  const active = useMemo(() => {
    return Boolean(option);
  }, [option]);

  const open = ({ use_native_sheet = false, localstorage_key, ...open_option }: OpenDialogType) => {
    const is_open_native_bottom_sheet = is_native_version && use_native_sheet && open_option.buttons;
    // TODO: 앱인터페이스 호환용 useConfirm, useAlert 별도 제작 예정 (일정부족으로 해당 hook 에 임시 구현체 구현)
    if (is_open_native_bottom_sheet) {
      const default_sheet_info: AppAlertInfo = {
        title: String(open_option.contents?.title ?? ''),
        message: String(open_option.contents?.text ?? ''),
        image_url: open_option.src,
        type: open_option.src === undefined || open_option.sheet_type === undefined ? 'none' : open_option.sheet_type,
        ok: {
          title: String(open_option.buttons?.[0].children ?? ''),
          type: String(open_option.buttons?.[0].variant ?? 'tertiary_gray'),
        },
      };

      const is_alert = open_option.buttons?.length === 1;

      if (is_alert) {
        callAlert(default_sheet_info);
        return new Promise((resolve, reject) => {
          awaitingPromiseRef.current = { resolve, reject };
        });
      }

      const confirm_sheet_info = {
        ...default_sheet_info,
        cancel: {
          title: String(open_option.buttons?.[1].children ?? ''),
          type: open_option.buttons?.[1].variant ?? 'primary',
        },
      };

      /**
       * MEMO: 네이티브 바텀싯이 여러번 호출되는 문제로인해 바텀싯 표시된 경우 스토리지에 값 셋하도록 임시 수정
       * 앱인터페이스 호환 hook 생성 시 삭제 필요
       */
      if (localstorage_key) {
        window.localStorage.setItem(localstorage_key, 'true');
      }

      const is_ok = callBottomSheet(confirm_sheet_info);

      const id = open_option.buttons?.[is_ok ? 0 : 1].id ?? '';
      setTimeout(() => awaitingPromiseRef.current?.resolve(id), 300);
      return new Promise((resolve, reject) => {
        awaitingPromiseRef.current = { resolve, reject };
      });
    }

    setOption(open_option);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleSubmit = (id: ReactText) => {
    awaitingPromiseRef.current?.resolve(id);
    setOption(undefined);
  };

  const close = () => setOption(undefined);

  return (
    <>
      <BottomDialogContext.Provider
        value={{
          open,
          close,
          handleSubmit,
        }}
        children={children}
      />
      <BottomSheet {...{ ...option, active, buttons, setIsActive: () => close() }} />
    </>
  );
}
