import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const Point32: FC<IconProps> = ({ width = 32, height = 32, color = colors.gray700, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 33 32' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.8234 5.4C10.9745 5.4 6.23301 10.1415 6.23301 15.9904C6.23301 21.8393 10.9745 26.5807 16.8234 26.5807C22.6723 26.5807 27.4138 21.8393 27.4138 15.9904C27.4138 10.1415 22.6723 5.4 16.8234 5.4ZM4.83301 15.9904C4.83301 9.36827 10.2013 4 16.8234 4C23.4455 4 28.8138 9.36827 28.8138 15.9904C28.8138 22.6125 23.4455 27.9807 16.8234 27.9807C10.2013 27.9807 4.83301 22.6125 4.83301 15.9904Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.333 10.5V21.5H14.733V17.9H17.733C19.7765 17.9 21.433 16.2435 21.433 14.2C21.433 12.1565 19.7765 10.5 17.733 10.5H14.733H14.033H13.333H13.333ZM14.733 16.5V11.9H17.733C19.0033 11.9 20.033 12.9297 20.033 14.2C20.033 15.4703 19.0033 16.5 17.733 16.5H14.733Z'
        fill={color}
      />
    </svg>
  );
};

export default Point32;
