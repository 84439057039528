import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray600 } from 'styles';

const Send24: FC<IconProps> = ({ width = 24, height = 24, color = gray600, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' color={color} {...props}>
    <path
      fill='currentColor'
      fillRule='nonzero'
      d='M12.975 11.583v9.333l7.975-3.8v-9.31l-2.2 1.041v5.303l-2 1V9.795l-3.775 1.788zm-2 0L3 7.805v9.311l7.975 3.8v-9.333zm-.777-8.153l7.454 3.724 2.033-.963-7.71-3.588-1.777.827zM7.88 4.508L4.265 6.191l7.71 3.652 3.38-1.6-7.474-3.735zM11.975.397L22.95 5.504V18.38l-10.975 5.229L1 18.378V5.505L11.975.397z'
    />
  </svg>
);
export default Send24;
