import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { gray500, white } from 'styles';

const Shipping20: FC<IconProps> = ({ width = 20, height = 20, color = gray500, sub_color = white, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' {...props}>
      <g clipPath='url(#clip0_3352_3730)'>
        <path
          d='M1.89844 4.125H12.282C12.8343 4.125 13.282 4.57272 13.282 5.125V13.3207'
          stroke={color}
          strokeWidth='1.4'
        />
        <path
          d='M13.278 7.19019L16.3435 7.19019H17.4091C18.5136 7.19019 19.4091 8.08562 19.4091 9.19019L19.4091 13.3206L2.59375 13.3206L2.59375 4.19019'
          stroke={color}
          strokeWidth='1.4'
        />
        <path
          d='M8.19022 14.3425C8.19022 15.189 7.50405 15.8751 6.65761 15.8751C5.81117 15.8751 5.125 15.189 5.125 14.3425C5.125 13.95 5.125 13.8316 5.125 13.3208C5.63587 13.3208 6.2038 13.3208 6.65771 13.3208C7.11161 13.3208 7.67935 13.3208 8.19031 13.3208C8.19022 13.8317 8.19022 13.95 8.19022 14.3425Z'
          stroke={color}
          strokeWidth='1.4'
        />
        <path
          d='M17.3699 14.3423C17.3699 15.1887 16.6837 15.8749 15.8373 15.8749C14.9909 15.8749 14.3047 15.1887 14.3047 14.3423C14.3047 13.9498 14.3047 13.8314 14.3047 13.3206C14.8156 13.3206 15.3835 13.3206 15.8374 13.3206C16.2913 13.3206 16.859 13.3206 17.37 13.3206C17.3699 13.8314 17.3699 13.9498 17.3699 14.3423Z'
          stroke={color}
          strokeWidth='1.4'
        />
      </g>
      <defs>
        <clipPath id='clip0_3352_3730'>
          <rect width={width} height={height} fill={sub_color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Shipping20;
