import React, { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray300 } from 'styles';

const Star32: FC<IconProps> = ({ width = 32, height = 32, color = gray300, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 32 32' fill='none' {...props}>
    <path
      d='M15.1044 2.80245C15.4721 2.06239 16.5279 2.06239 16.8956 2.80245L20.1936 9.44033C20.3392 9.73339 20.619 9.93667 20.9427 9.98459L28.2749 11.07C29.0923 11.191 29.4186 12.1951 28.8284 12.7735L23.5345 17.9613C23.3008 18.1904 23.1939 18.5193 23.2484 18.8419L24.4818 26.1507C24.6194 26.9655 23.7653 27.586 23.0328 27.2034L16.463 23.7718C16.1729 23.6203 15.8271 23.6203 15.537 23.7718L8.96719 27.2034C8.23472 27.586 7.38064 26.9655 7.51816 26.1507L8.75162 18.8419C8.80608 18.5193 8.69921 18.1904 8.46549 17.9613L3.17164 12.7735C2.58143 12.1951 2.90766 11.191 3.72512 11.07L11.0573 9.98459C11.381 9.93667 11.6608 9.73339 11.8064 9.44033L15.1044 2.80245Z'
      fill={color}
    />
  </svg>
);
export default Star32;
