import { FC } from 'react';
import { gray600 } from '../../../styles';
import { IconProps } from './index';

const InputCancel16: FC<IconProps> = ({ width = 16, height = 16, color = gray600, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8ZM4.5 5.56066L5.56066 4.5L8.04205 6.98139L10.5234 4.5L11.5841 5.56066L9.10271 8.04205L11.5841 10.5234L10.5234 11.5841L8.04205 9.10271L5.56066 11.5841L4.5 10.5234L6.98139 8.04205L4.5 5.56066Z'
        fill={color}
      />
    </svg>
  );
};

export default InputCancel16;
