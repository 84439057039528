export enum Typography {
  TITLE1 = 'title1',
  TITLE2 = 'title2',
  TITLE3 = 'title3',
  TITLE4 = 'title4',
  TITLE4_SEMIBOLD = 'title4-semibold',
  TITLE5 = 'title5',
  TITLE7 = 'title7',
  BODY1 = 'body1',
  BODY2 = 'body2',
  BODY3 = 'body3',
  SMALL1 = 'small1',
  SMALL1_BOLD = 'small1-bold',
  SMALL1_REGULAR = 'small1-regular',
  SMALL2 = 'small2',
  SMALL2_REGULAR = 'small2-regular',
  SMALL2_BOLD = 'small2-bold',
  SMALL3_BOLD = 'small3-bold',
  SMALL3_MEDIUM = 'small3-medium',
  SMALL4_SEMIBOLD = 'small4-semibold',
  SUB_TITLE1 = 'sub-title1',
  SUB_TITLE2 = 'sub-title2',
  SUB_TITLE3 = 'sub-title3',
  BUTTON = 'button',
  BUTTON_SMALL = 'button-small',
  CAPTION = 'caption',
  TAG = 'tag',
  TITLE1_NUM = 'title1-num',
  TITLE2_NUM = 'title2-num',
  TITLE6_NUM = 'title6-num',
  BODY1_NUM = 'body1-num',
  BODY2_NUM = 'body2-num',
  BODY3_NUM = 'body3-num',
  SMALL2_NUM = 'small2-num',
  SUB_TITLE1_NUM = 'sub-title1-num',
  SUB_TITLE2_NUM = 'sub-title2-num',
  SUB_TITLE3_NUM = 'sub-title3-num',
  BUTTON_NUM = 'button-num',
  BUTTON_SMALL_NUM = 'button-small-num',
  CAPTION_NUM = 'caption-num',
  TAG_NUM = 'tag-num',
}
