import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { black } from 'styles';

const FBKLogo118x26: FC<IconProps> = ({ width = 108, height = 23, color = black, ...props }) => (
  <svg width={width} height={height} color={color} fill='none' viewBox='0 0 108 23' {...props}>
    <path
      d='M9.08444 15.1159L7.40169 15.1985L5.77383 15.2535C5.2434 15.2535 4.77699 15.2535 4.3746 15.2535H0.881104V13.1085H2.60956V3.85928H1.21948V1.73264H10.3648V3.85928H8.93811L8.85581 12.9801C9.51427 12.9801 10.127 12.9159 10.6849 12.8518V14.9876L9.08444 15.1159ZM5.68239 13.1176H6.53289L6.59692 3.88678H4.87759L4.92332 13.136L5.68239 13.1176ZM15.1112 18.9385V9.35928H13.6205V18.1593H11.3067V0.30264H13.6205V7.08597H15.1112V0.0917969H17.4615V18.9385H15.1112Z'
      fill={color}
    />
    <path
      d='M28.8657 10.0101C27.9372 9.45294 27.101 8.75398 26.3874 7.93846C26.0606 7.55351 25.7697 7.13926 25.5186 6.70096C25.3153 7.15835 25.0574 7.58929 24.7504 7.98428C24.406 8.43789 24.0203 8.85835 23.5981 9.24014C23.1406 9.65283 22.6547 10.0327 22.144 10.3768C21.6123 10.7229 21.056 11.0292 20.4795 11.2935L18.9248 9.6068C19.6869 9.28927 20.4059 8.87657 21.0648 8.37847C21.6864 7.918 22.2458 7.3788 22.7293 6.7743C23.1867 6.20448 23.5481 5.56352 23.7993 4.8768C24.0529 4.19368 24.18 3.46984 24.1742 2.74095V0.40346H26.6617V2.74095C26.6562 3.41622 26.7836 4.08598 27.0367 4.7118C27.2894 5.33877 27.6414 5.92068 28.0792 6.43511C28.5304 6.97496 29.0535 7.44998 29.634 7.84679C30.2293 8.26737 30.8744 8.61223 31.5545 8.87345L30.2741 10.7068C29.7899 10.5059 29.3194 10.2732 28.8657 10.0101ZM23.0585 18.6268V12.0543H25.5003V16.5184H36.1911V18.6268H23.0585ZM33.3744 13.6585V7.24179H29.9906V5.14264H33.3744V3.63012H29.9906V1.52179H33.3744V0.0917969H35.7704V13.6585H33.3744Z'
      fill={color}
    />
    <path
      d='M48.5099 18.8009C47.9395 18.807 47.3698 18.7579 46.8089 18.6542C46.3635 18.573 45.9281 18.4439 45.5103 18.2692V6.15089L46.9095 5.83008V10.4684C47.2579 10.2113 47.6476 10.016 48.0618 9.8909C48.4812 9.75486 48.9196 9.68678 49.3604 9.68924C49.828 9.65808 50.296 9.744 50.7223 9.93922C51.1485 10.1344 51.5196 10.4328 51.8022 10.8076C52.3832 11.8468 52.6667 13.0266 52.6215 14.217C52.5763 15.4074 52.2042 16.5622 51.5462 17.5542C51.1649 17.9759 50.6944 18.3068 50.169 18.5225C49.6436 18.7382 49.0767 18.8334 48.5099 18.8009ZM48.9763 10.8717C48.6075 10.8733 48.2408 10.9289 47.888 11.0367C47.5418 11.1379 47.2121 11.2892 46.9095 11.4859V17.5176C47.1621 17.5811 47.4188 17.6271 47.6777 17.6551H48.327C48.7241 17.6732 49.1201 17.6 49.4847 17.4411C49.8493 17.2822 50.1728 17.0419 50.4304 16.7384C50.9974 15.9393 51.2661 14.9659 51.1895 13.9884C51.2427 13.1676 51.0652 12.3482 50.6773 11.6234C50.4823 11.362 50.2232 11.1555 49.9252 11.0239C49.6272 10.8922 49.3004 10.8398 48.9763 10.8717Z'
      fill={color}
    />
    <path
      d='M58.2495 17.9667C57.9279 18.8727 57.4947 19.7349 56.96 20.5334C56.4904 21.2225 55.8983 21.8191 55.2133 22.2934L54.3445 21.4592C54.8188 21.0754 55.2511 20.6421 55.634 20.1667C56.002 19.7038 56.3094 19.1955 56.5485 18.6542L53.3202 10.12L54.7011 9.68921L57.271 17.1875L59.8682 9.68921L61.2035 10.0834L58.2495 17.9667Z'
      fill={color}
    />
    <path
      d='M67.2851 18.6359H65.8859V6.15089L67.2851 5.83008V18.6359ZM71.3365 9.58839L72.4339 10.4042L69.5531 14.0067L72.882 18.0217L71.7846 18.8834L67.8978 14.0984L71.3365 9.58839Z'
      fill={color}
    />
    <path
      d='M73.9795 16.2618C73.9586 15.874 74.0306 15.4869 74.1898 15.1328C74.3489 14.7787 74.5904 14.468 74.894 14.2268C75.6499 13.7162 76.5547 13.4742 77.4639 13.5393H79.2106V12.8243C79.2376 12.5579 79.212 12.2889 79.1351 12.0325C79.0582 11.7761 78.9316 11.5374 78.7625 11.3301C78.5799 11.1722 78.3679 11.0519 78.1387 10.9764C77.9096 10.9009 77.6678 10.8716 77.4273 10.8901C77.0014 10.8926 76.5775 10.948 76.1652 11.0551C75.7587 11.1552 75.3634 11.2965 74.9855 11.4768L74.5648 10.4868C75.0151 10.2323 75.494 10.0323 75.9914 9.89097C76.4711 9.75834 76.9663 9.69051 77.4639 9.6893C78.311 9.62354 79.1497 9.89713 79.7959 10.4501C80.0776 10.786 80.2904 11.1742 80.4223 11.5926C80.5541 12.011 80.6023 12.4514 80.5641 12.8885V18.636H79.5307L79.3661 17.6643C78.9384 18.0152 78.4565 18.2938 77.9394 18.4893C77.4774 18.6658 76.9887 18.7619 76.4945 18.7735C76.1608 18.7943 75.8264 18.7465 75.5118 18.633C75.1973 18.5195 74.9092 18.3427 74.6654 18.1135C74.4317 17.8676 74.2504 17.5767 74.1325 17.2583C74.0146 16.94 73.9625 16.6009 73.9795 16.2618ZM76.7231 17.6368C76.9384 17.6335 77.1528 17.6089 77.3632 17.5635C77.5925 17.5058 77.8185 17.4354 78.04 17.3526C78.2575 17.2604 78.4683 17.1533 78.671 17.0318C78.8598 16.9221 79.0322 16.7864 79.1832 16.6285V14.5293H77.6193C77.0084 14.4813 76.3978 14.6255 75.8726 14.9418C75.6847 15.0894 75.5363 15.2814 75.4406 15.5005C75.3448 15.7197 75.3047 15.9592 75.3239 16.1976C75.297 16.5883 75.4252 16.9737 75.6805 17.2701C75.8199 17.3969 75.983 17.4947 76.1604 17.5576C76.3379 17.6206 76.526 17.6475 76.7139 17.6368H76.7231Z'
      fill={color}
    />
    <path
      d='M84.7709 18.6359H83.3717V6.15089L84.7709 5.83008V18.6359ZM88.8132 9.58839L89.9106 10.4042L87.0298 14.0067L90.3679 18.0034L89.2613 18.8651L85.3837 14.0801L88.8132 9.58839Z'
      fill={color}
    />
    <path
      d='M91.4562 16.2618C91.4313 15.878 91.497 15.4938 91.648 15.1402C91.7989 14.7867 92.0309 14.4737 92.325 14.2268C93.0852 13.7181 93.9921 13.4764 94.904 13.5393H96.6599V12.8243C96.6869 12.5579 96.6612 12.2889 96.5843 12.0325C96.5074 11.7761 96.3808 11.5374 96.2118 11.3301C96.0291 11.1722 95.8171 11.0519 95.588 10.9764C95.3588 10.9009 95.117 10.8716 94.8765 10.8901C94.4506 10.892 94.0266 10.9475 93.6145 11.0551C93.2046 11.1537 92.8061 11.2951 92.4256 11.4768L92.0141 10.4868C92.4628 10.2291 92.9421 10.0289 93.4407 9.89097C93.9204 9.75834 94.4155 9.69051 94.9131 9.6893C95.7603 9.62354 96.5989 9.89713 97.2452 10.4501C97.5269 10.786 97.7397 11.1742 97.8715 11.5926C98.0034 12.011 98.0516 12.4514 98.0134 12.8885V18.636H96.98L96.8153 17.6643C96.3877 18.0152 95.9058 18.2938 95.3887 18.4893C94.9264 18.6649 94.4379 18.761 93.9437 18.7735C93.61 18.7943 93.2757 18.7465 92.9611 18.633C92.6466 18.5195 92.3585 18.3427 92.1147 18.1135C91.8859 17.8654 91.7096 17.5734 91.5964 17.2552C91.4833 16.9369 91.4356 16.599 91.4562 16.2618ZM94.1998 17.6368C94.415 17.633 94.6294 17.6084 94.84 17.5635C95.0666 17.5071 95.2896 17.4367 95.5075 17.3526C95.7289 17.2623 95.9429 17.1551 96.1477 17.0318C96.3349 16.9198 96.507 16.7842 96.6599 16.6285V14.5293H95.096C94.4851 14.4813 93.8745 14.6255 93.3493 14.9418C93.1614 15.0894 93.013 15.2814 92.9173 15.5005C92.8215 15.7197 92.7814 15.9592 92.8005 16.1976C92.7737 16.5883 92.9019 16.9737 93.1572 17.2701C93.2966 17.3969 93.4597 17.4947 93.6371 17.5576C93.8146 17.6206 94.0027 17.6475 94.1906 17.6368H94.1998Z'
      fill={color}
    />
    <path
      d='M103.729 9.68913C104.264 9.66195 104.799 9.75295 105.295 9.9557C105.791 10.1585 106.237 10.468 106.601 10.8625C107.271 11.8641 107.63 13.0432 107.63 14.2496C107.63 15.4559 107.271 16.635 106.601 17.6366C105.83 18.3826 104.801 18.7996 103.729 18.7996C102.658 18.7996 101.628 18.3826 100.857 17.6366C100.181 16.6373 99.8191 15.4573 99.8191 14.2496C99.8191 13.0418 100.181 11.8618 100.857 10.8625C101.219 10.4649 101.664 10.1533 102.161 9.9503C102.658 9.7473 103.194 9.65809 103.729 9.68913ZM103.729 10.835C103.371 10.8174 103.015 10.8926 102.695 11.0531C102.374 11.2137 102.101 11.4542 101.9 11.7516C101.424 12.512 101.2 13.4041 101.26 14.3C101.199 15.1901 101.423 16.0765 101.9 16.83C102.109 17.1147 102.386 17.3426 102.706 17.4931C103.025 17.6436 103.377 17.7118 103.729 17.6916C104.081 17.7093 104.432 17.64 104.751 17.4897C105.07 17.3395 105.347 17.1129 105.558 16.83C106.035 16.0765 106.259 15.1901 106.198 14.3C106.259 13.4041 106.035 12.512 105.558 11.7516C105.356 11.4561 105.082 11.217 104.762 11.0567C104.442 10.8964 104.086 10.8201 103.729 10.835Z'
      fill={color}
    />
  </svg>
);
export default FBKLogo118x26;
