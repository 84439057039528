import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useState } from 'react';

import useBrowserEffect from 'hooks/useBrowserEffect';
import { useIsApp } from 'util/is_app';

export const appLandingUrl = 'https://fashionbykakao.page.link/DQbR';

// web에서 app이동 notify 여부 확인 및 설정하기 위한  hook
export const useAdLandingNotify = (landing_storage_key: string) => {
  const [is_show, setIsShow] = useState(false);
  const is_native_app = useIsApp();
  const { asPath } = useRouter();

  const openApp = () => {
    window.location.assign(appLandingUrl);
  };

  const close = () => {
    setIsShow(false);
    // 앱 유도 배너 제한 시기는 클릭시점 24시간
    localStorage.setItem(landing_storage_key, dayjs().add(1, 'day').format('YYYY-MM-DD HH:mm'));
  };

  useBrowserEffect(() => {
    if (is_native_app) {
      return;
    }

    // CMS 공유 url로 직접 진입 시, 바텀싯에 스크롤 보정이 막히는 이슈로 예외처리
    const is_cms_page = asPath.startsWith('/events/cms/');
    if (is_cms_page) {
      return;
    }

    const hide_end_date = localStorage.getItem(landing_storage_key);

    if (!hide_end_date) {
      setIsShow(true);
      return;
    }

    const is_over_24_hour = dayjs(hide_end_date).isBefore(dayjs());
    if (is_over_24_hour) {
      setIsShow(true);
      localStorage.removeItem(landing_storage_key);
    }
  }, []);

  const is_show_ad_notify = is_show && !is_native_app;
  return { is_show: is_show_ad_notify, openApp, close };
};
