import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const Coupon60: FC<IconProps> = ({ size = 60, color = colors.pink600, ...props }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 60 60' fill='none' color={color} {...props}>
      <g clipPath='url(#clip0_648_3561)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.5 25.5558L48.0052 28.6623L46.5 30.0002L48.0052 31.3382L51.5 34.4447V41.2001C51.5 44.5138 48.8137 47.2001 45.5 47.2001H14.5C11.1863 47.2001 8.5 44.5138 8.5 41.2V34.4447L11.9948 31.3382L13.5 30.0002L11.9948 28.6623L8.5 25.5558V18.8C8.5 15.4863 11.1863 12.8 14.5 12.8H45.5C48.8137 12.8 51.5 15.4863 51.5 18.8V25.5558ZM45.1713 31.4951L49.5 35.3428V41.2001C49.5 43.4092 47.7091 45.2001 45.5 45.2001H14.5C12.2909 45.2001 10.5 43.4092 10.5 41.2V35.3428L14.8287 31.4951L16.5104 30.0002L14.8287 28.5054L10.5 24.6577V18.8C10.5 16.5909 12.2909 14.8 14.5 14.8H45.5C47.7091 14.8 49.5 16.5909 49.5 18.8V24.6577L45.1713 28.5054L43.4896 30.0002L45.1713 31.4951ZM25.4667 26.1836C25.4667 27.1133 24.7131 27.867 23.7834 27.867C22.8537 27.867 22.1 27.1133 22.1 26.1836C22.1 25.2539 22.8537 24.5002 23.7834 24.5002C24.7131 24.5002 25.4667 25.2539 25.4667 26.1836ZM27.4667 26.1836C27.4667 28.2179 25.8176 29.867 23.7834 29.867C21.7491 29.867 20.1 28.2179 20.1 26.1836C20.1 24.1493 21.7491 22.5002 23.7834 22.5002C25.8176 22.5002 27.4667 24.1493 27.4667 26.1836ZM23.722 36.5807L34.7112 23.4843H37.322L26.3328 36.5807H23.722ZM37.0435 35.3992C37.9732 35.3992 38.7269 34.6455 38.7269 33.7158C38.7269 32.7861 37.9732 32.0324 37.0435 32.0324C36.1138 32.0324 35.3601 32.7861 35.3601 33.7158C35.3601 34.6455 36.1138 35.3992 37.0435 35.3992ZM37.0435 37.3992C39.0778 37.3992 40.7269 35.7501 40.7269 33.7158C40.7269 31.6815 39.0778 30.0324 37.0435 30.0324C35.0092 30.0324 33.3601 31.6815 33.3601 33.7158C33.3601 35.7501 35.0092 37.3992 37.0435 37.3992Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_648_3561'>
          <rect width='60' height='60' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Coupon60;
