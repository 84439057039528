import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray400 } from 'styles';

const LineArrowRight15x20: FC<IconProps> = ({ width = 15, height = 20, color = gray400, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 15 20' fill='none' color={color} {...props}>
    <path
      d='M1.99922 8.20005C1.55739 8.20005 1.19922 8.55822 1.19922 9.00005C1.19922 9.44188 1.55739 9.80005 1.99922 9.80005V8.20005ZM14.5649 9.56574C14.8773 9.25332 14.8773 8.74678 14.5649 8.43436L9.47374 3.3432C9.16132 3.03078 8.65479 3.03078 8.34237 3.3432C8.02995 3.65562 8.02995 4.16215 8.34237 4.47457L12.8678 9.00005L8.34237 13.5256C8.02995 13.838 8.02995 14.3445 8.34237 14.657C8.65479 14.9694 9.16132 14.9694 9.47374 14.657L14.5649 9.56574ZM1.99922 9.80005H13.9992V8.20005H1.99922V9.80005Z'
      fill='currentColor'
    />
  </svg>
);
export default LineArrowRight15x20;
