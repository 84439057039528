import { isAndroid } from '@croquiscom/web2app';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import LoginDialog, { LoginDialogId } from '.';
import { useBottomDialog } from 'context/ButtomDialogContext';
import { useAppVersion } from 'hooks/app/useAppVersion';
import { useKaKaoLogin } from 'hooks/auth/useKakaoLogin';
import { showLoginPopup } from 'util/app_utils';
import { isKaKaoTalkBrowser } from 'util/kakao_utils';
import { Toast } from 'util/Toast';

export const useLoginDialog = () => {
  const { open, close, handleSubmit } = useBottomDialog();
  const { kakaoLogin } = useKaKaoLogin();
  const router = useRouter();
  const { is_native_version, is_native_login_version } = useAppVersion();

  const openLoginDialog = async () => {
    // 카톡브라우저 (안드로이드) 톡 웹뷰 버그로 인하여 해당 경우 토스트 메세지만 노출
    if (isKaKaoTalkBrowser() && isAndroid()) {
      Toast.show('로그인이 필요합니다.');
      return;
    }

    if (is_native_version) {
      showLoginPopup(is_native_login_version ? '' : String(router.asPath));
      return;
    }

    const id = await open({
      children: <LoginDialog handleSubmit={handleSubmit} />,
    });
    id === LoginDialogId.LOGIN ? kakaoLogin() : close();
  };

  useEffect(() => {
    const onRouteChangeStart = () => close();
    router.events.on('routeChangeStart', onRouteChangeStart);
    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
    };
  }, [close, router]);

  return {
    openLoginDialog,
  };
};
