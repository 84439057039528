import { FC } from 'react';

import { IconProps } from 'components/base/Icon';

const ShareBand32: FC<IconProps> = ({ size = 32, ...props }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 32 32' fill='none' {...props}>
      <rect width={size} height={size} rx='16' fill='#21C531' />
      <path
        d='M16 10.7676C15.4005 10.7676 14.8175 10.8446 14.2621 10.9889V9.67471C14.2621 8.19794 13.0837 7 11.631 7C10.1784 7 9 8.19794 9 9.67471V17.8838C9 21.8139 12.1341 25 16 25C19.8659 25 23 21.8139 23 17.8838C23 13.9537 19.8659 10.7676 16 10.7676ZM16 23.9296C12.7157 23.9296 10.053 21.2226 10.053 17.8838V9.67471C10.053 8.78781 10.76 8.07044 11.631 8.07044C12.5021 8.07044 13.2091 8.78921 13.2091 9.67471V17.8838C13.2091 19.4502 14.4591 20.721 16 20.721C17.5409 20.721 18.7909 19.4502 18.7909 17.8838C18.7909 16.3174 17.5409 15.0465 16 15.0465C15.3426 15.0465 14.7389 15.2777 14.2621 15.6644V14.3992C14.7844 14.1288 15.3743 13.9775 16 13.9775C18.1225 13.9775 19.8425 15.7261 19.8425 17.8838C19.8425 20.0415 18.1225 21.7901 16 21.7901C13.8775 21.7901 12.1575 20.0415 12.1575 17.8838V9.67471C12.1575 9.37908 11.9218 9.13949 11.631 9.13949C11.3402 9.13949 11.1045 9.37908 11.1045 9.67471V17.8838C11.1045 20.6328 13.2959 22.8605 16 22.8605C18.7041 22.8605 20.8955 20.6328 20.8955 17.8838C20.8955 15.1348 18.7041 12.9071 16 12.9071C15.3881 12.9071 14.8023 13.022 14.2621 13.2307V12.1C14.812 11.9291 15.395 11.8366 16 11.8366C19.2843 11.8366 21.947 14.5436 21.947 17.8824C21.947 21.2212 19.2843 23.9282 16 23.9282V23.9296ZM14.2621 17.8838C14.2621 16.9072 15.0408 16.117 16 16.117C16.9592 16.117 17.7379 16.9086 17.7379 17.8838C17.7379 18.859 16.9592 19.6506 16 19.6506C15.0408 19.6506 14.2621 18.859 14.2621 17.8838Z'
        fill='white'
      />
    </svg>
  );
};

export default ShareBand32;
