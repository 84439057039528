import { numberWithCommas } from 'util/number';

export const parsePriceDelta = (price_delta: number) => {
  return price_delta ? `(${numberWithCommas(price_delta)}원)` : '';
};

export const validateImageURL = (imageUrl: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const optionImage = new Image();
    optionImage.src = imageUrl;

    optionImage.onload = () => {
      resolve();
    };

    optionImage.onerror = () => {
      reject();
    };
  });
};
