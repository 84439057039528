import { useRouter } from 'next/router';
import { web_path } from 'constants/web_path';
import { kakakoLoginForApp, showLoginPopup } from 'util/app_utils';
import config from 'util/config';
import { isKaKaoTalkBrowser } from 'util/kakao_utils';
import { useAppVersion } from 'hooks/app/useAppVersion';
declare global {
  interface Window {
    Kakao?: {
      Auth?: {
        /** [parameters 자세히](https://developers.kakao.com/sdk/reference/js/release/Kakao.Auth.html#.authorize) */
        authorize: (parameters: { redirectUri: string; state: string }) => void;
      };
      init: (key: string) => void;
      isInitialized: () => boolean;
    };
  }
}

export const useKaKaoLogin = () => {
  const { is_native_version, is_native_login_version } = useAppVersion();
  const { push, asPath } = useRouter();

  const kakaoLogin = (redirect_url?: string) => {
    // 카톡 브라우저의 경우 전용인앱브라우저 버그로 현재 login페이지로 진입시켜 자동로그인 처리해주어야 한다.
    if (isKaKaoTalkBrowser()) {
      push(`${web_path.auth.login}?redirect_path=${asPath}`);
    }

    const go_url = redirect_url ?? asPath;

    if (is_native_login_version) {
      showLoginPopup('');
      return;
    }

    if (is_native_version) {
      kakakoLoginForApp(go_url);
      return;
    }
    kakaoLoginForWeb(go_url);
  };

  const kakaoLoginForWeb = (redirect_url: string) => {
    if (typeof window.Kakao?.Auth === 'undefined') {
      return console.error('kakao가 설치되어 있지 않습니다.');
    }

    window.Kakao.Auth.authorize({
      redirectUri: `${config.appUrl}/auth/kakao-callback`,
      state: JSON.stringify({ redirect_path: redirect_url }),
    });
  };

  return {
    kakaoLogin,
    kakaoLoginForWeb,
  };
};
