import { IncomingMessage } from 'http';
import { useQuery } from '@tanstack/react-query';
import isNil from 'lodash-es/isNil';
import { getAbTestList, getDeviceUuid } from 'api2';
import { getDeviceUuidFromCookie } from 'util/cookie-util';

export const A_B_TEST_QUERY_KEY = ['getUserFeatureList'];

export const fetchDeviceUuid = async (req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  if (req) {
    // SSR인 경우, 쿠키에서 uuid 추출
    return getDeviceUuidFromCookie(req.headers.cookie ?? '');
  }
  const { data: deviceData } = await getDeviceUuid(null, context_request);

  return deviceData.user?.uuid ?? '';
};

export const fetchUserFeatureList = async (req?: IncomingMessage) => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };
  const device_uuid = await fetchDeviceUuid(req);
  const { data } = await getAbTestList({ uuid: device_uuid }, context_request);

  if (isNil(data) || isNil(data.metadata.feature_list)) {
    return [];
  }
  return data.metadata.feature_list;
};

/**
 * https://www.notion.so/croquis/AB-FE-118a576c567c49e4abae2603440eb718 참고
 * @description
 * ab테스트시 a,b 안에 대한 flag를 받아볼수 있는 hook 입니다.
 * 1. test key 생성시 prefix로 fbk를 꼭 추가해주세요
 * 2. a,b 테스트 완료된 케이스는 테스트 종료 이후 꼭 삭제 부탁드립니다. (레거시 분기포인트 제거 및 유지보수 편리 목적)
 * 3. 마지막 case에 대한 값인 a,b 등은 대문자로 통일해 주세요.
 * 4. key example => fbk_xxx_A, fbk_xxx_B
 * @example
 * const { is_b_case_hot_product } = useABTest();
 *
 * if (is_b_case_hot_product) {
 *  return <BcaseComp />;
 * }
 *
 * return <AcaseComp />;
 */
export const useABTest = () => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: feature_list = [],
    isInitialLoading,
    ...rest
  } = useQuery<string[]>(A_B_TEST_QUERY_KEY, () => fetchUserFeatureList());

  return {
    is_loading: isInitialLoading,
    ...rest,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getABCase(test_key: string, feature_list: string[]) {
  try {
    const feature = feature_list.filter((feat) => feat.includes(test_key));

    if (feature.length === 0) {
      return 'A';
    }
    const cover_name = feature[0].split(test_key)[1];
    return cover_name.toUpperCase();
  } catch (e) {
    console.log(e);
  }
  return 'A';
}
