export const web_path = {
  root: '/',
  home: '/home',
  category_main: '/categories',
  search: '/search',
  style: '/style',
  like: '/like',
  like_stores: '/like/stores',
  category_detail: '/categories/[category_id]',
  auth: {
    root: '/auth',
    login: '/auth/login',
    connect_kakao: '/auth/connect-kakao',
    kakao_callback: '/auth/kakao-callback',
    register_completed: '/auth/register-completed',
  },
  mypage: {
    root: '/my-page',
    point: '/my-page/point',
    setting: '/my-page/settings',
    inquiry: '/my-page/inquiry',
    delete_account: '/my-page/delete-account',
  },
  invite_friends: {
    root: '/invite-friends',
    write: '/invite-friends/write',
  },
  orders: '/orders',
  faq: '/faq',
  coupon: {
    root: '/coupons',
  },
};
