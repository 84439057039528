import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray_tertiary } from 'styles';

const Info16: FC<IconProps> = ({ width = 16, height = 16, color = gray_tertiary, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 16 16' fill='none' {...props}>
    <path
      d='M8 14.3C4.52061 14.3 1.7 11.4794 1.7 8C1.7 4.52061 4.52061 1.7 8 1.7C11.4794 1.7 14.3 4.52061 14.3 8C14.3 11.4794 11.4794 14.3 8 14.3Z'
      stroke={color}
      strokeWidth='1.4'
    />
    <path fillRule='evenodd' clipRule='evenodd' d='M8.5 4H7.5V5.8H8.5V4ZM8.5 7.5H7.5V12H8.5V7.5Z' fill={color} />
    <path
      d='M7.5 4V3.8H7.3V4H7.5ZM8.5 4H8.7V3.8H8.5V4ZM7.5 5.8H7.3V6H7.5V5.8ZM8.5 5.8V6H8.7V5.8H8.5ZM7.5 7.5V7.3H7.3V7.5H7.5ZM8.5 7.5H8.7V7.3H8.5V7.5ZM7.5 12H7.3V12.2H7.5V12ZM8.5 12V12.2H8.7V12H8.5ZM7.5 4.2H8.5V3.8H7.5V4.2ZM7.7 5.8V4H7.3V5.8H7.7ZM8.5 5.6H7.5V6H8.5V5.6ZM8.3 4V5.8H8.7V4H8.3ZM7.5 7.7H8.5V7.3H7.5V7.7ZM7.7 12V7.5H7.3V12H7.7ZM8.5 11.8H7.5V12.2H8.5V11.8ZM8.3 7.5V12H8.7V7.5H8.3Z'
      fill={color}
    />
  </svg>
);
export default Info16;
