import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { black } from 'styles';

const ArrowDown20: FC<IconProps> = ({ width = 20, height = 20, color = black, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 20 20' fill='none' {...props}>
    <path d='M17 7L10 14L3 7' stroke={color} strokeWidth='1.4' />
  </svg>
);
export default ArrowDown20;
