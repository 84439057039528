import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { blue400, white } from 'styles';

const FastShipping20: FC<IconProps> = ({ size = 20, color = blue400, sub_color = white, ...props }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 20 20' fill='none' {...props}>
      <g clipPath='url(#clip0_7528_31781)'>
        <path
          d='M0 4.125H12.2826C12.8349 4.125 13.2826 4.57272 13.2826 5.125V13.3207'
          stroke={color}
          strokeWidth='1.4'
        />
        <path
          d='M13.2816 7.19019L16.3471 7.19019H17.4127C18.5172 7.19019 19.4127 8.08562 19.4127 9.19019V13.3206L4.59734 13.3206L4.59734 7.19019L1.53223 7.19019'
          stroke={color}
          strokeWidth='1.4'
        />
        <path
          d='M9.19608 14.3424C9.19608 15.1889 8.5099 15.875 7.66347 15.875C6.81703 15.875 6.13086 15.1889 6.13086 14.3424C6.13086 13.9499 6.13086 13.8315 6.13086 13.3207C6.64173 13.3207 7.20966 13.3207 7.66357 13.3207C8.11747 13.3207 8.68521 13.3207 9.19617 13.3207C9.19608 13.8315 9.19608 13.9499 9.19608 14.3424Z'
          stroke={color}
          strokeWidth='1.4'
        />
        <path
          d='M17.3699 14.3424C17.3699 15.1889 16.6837 15.875 15.8373 15.875C14.9909 15.875 14.3047 15.1889 14.3047 14.3424C14.3047 13.9499 14.3047 13.8315 14.3047 13.3207C14.8156 13.3207 15.3835 13.3207 15.8374 13.3207C16.2913 13.3207 16.859 13.3207 17.37 13.3207C17.3699 13.8315 17.3699 13.9499 17.3699 14.3424Z'
          stroke={color}
          strokeWidth='1.4'
        />
      </g>
      <defs>
        <clipPath id='clip0_7528_31781'>
          <rect width={size} height={size} fill={sub_color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FastShipping20;
