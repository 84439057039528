import { compareWithAppVersion } from '@croquiscom/web2app';
import { useEffect, useState } from 'react';

export const useIsAvailableByAppVer = (version: string) => {
  const [isAvailable, setIsAvailable] = useState(false);
  useEffect(() => {
    setIsAvailable(compareWithAppVersion(version));
  }, [version]);

  return isAvailable;
};
