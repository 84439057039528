import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray_primary } from 'styles';

const IconNavigationSearch24: FC<IconProps> = ({ width = 24, height = 24, color = gray_primary, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
    <path d='M14.5 14.5L21.5 21.5' stroke={color} strokeWidth='1.5' />
    <circle cx='10' cy='10' r='6.75' stroke={color} strokeWidth='1.5' />
  </svg>
);
export default IconNavigationSearch24;
