import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const CheckCircle20: FC<IconProps> = ({
  size = 20,
  color = colors.gray_inactive,
  sub_color = colors.white,
  ...props
}) => {
  return (
    <svg width={size} height={size} fill='none' viewBox='0 0 20 20' {...props}>
      <circle cx='10' cy='10' r='10' fill={color} />
      <path d='M5 8.75L8.75 13.125L15 6.25' stroke={sub_color} strokeWidth='1.4' />
    </svg>
  );
};

export default CheckCircle20;
