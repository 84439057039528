import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray900 } from 'styles';

const BottomHomeP24: FC<IconProps> = ({ width = 24, height = 24, color = gray900, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.0008 2.11914L21.2008 9.15443V22.2004H13.501V16.5005C13.501 15.6721 12.8294 15.0005 12.001 15.0005C11.1725 15.0005 10.501 15.6721 10.501 16.5005V22.2004H2.80078V9.15443L12.0008 2.11914Z'
      fill={color}
    />
  </svg>
);
export default BottomHomeP24;
