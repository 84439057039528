import { BaseConfigType } from './default';
import ProductionConfig from './production';

const Config: BaseConfigType = {
  ...ProductionConfig,
  site_name: '패션 바이 카카오[베타]',
  baseDomain: 'beta.zigzag.kr',
  apiBaseUrl: 'https://beta.fashionbykakao.com/api/store',
  apiConsumerBaseUrl: 'https://beta.fashionbykakao.com/api/2',
  appUrl: 'https://beta.fashionbykakao.com',
  contentUrl: 'https://content.beta.zigzag.kr',
  live_player_access_key: 'dIjVRVKEX4svFNBhXvpn',
  app_scheme: 'fbk-beta',
  // 앱 다이나믹 딥링크
  dynamic_deep_link: 'https://fashionbykakao.page.link',
  // IOS APP 설치 id
  ios_app_store_id: '653403166',
  // 파이어베이스 키값
  firebase_web_key: 'AIzaSyAp5QzeMiJP1ay9bxTsC83uf4HppuTNK2k',
  // 키즈 카테고리 id
  kid_category_id: '2187',
  // '전체' 상품 카테고리 id
  root_product_category_id: '1112',
  // 에어브릿지 앱 이름
  airbridge_app_name: 'fbkbeta',
  // 에어브릿지 웹 SDK 토큰
  airbridge_web_token: 'ecabfd5f93ea40fab4c82eca183de306',
  // 에어브릿지 url
  airbridge_url: 'https://abr.ge/@fbkbeta',
};

export default Config;
