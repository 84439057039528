import { useEffect, useState } from 'react';
import { validateImageURL } from 'components/toolbox/select-option/utils';

export const useValidateImage = (imageUrl?: string | null) => {
  const [isImageValid, setIsImageValid] = useState(false);

  useEffect(() => {
    if (!imageUrl) {
      return;
    }

    const checkImageValidation = async () => {
      try {
        await validateImageURL(imageUrl);

        setIsImageValid(true);
      } catch {
        setIsImageValid(false);
      }
    };
    checkImageValidation();
  }, [imageUrl]);

  return { isImageValid };
};
