import React, { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray600 } from 'styles';

const Stop8: FC<IconProps> = ({ size = 8, color = gray600, ...props }) => {
  return (
    <svg width={size} height={size} fill='none' viewBox='0 0 8 8' {...props}>
      <rect x='0.5' y='0.5' width='7' height='7' fill={color} />
    </svg>
  );
};

export default Stop8;
