import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { black } from 'styles';

const Close24: FC<IconProps> = ({ width = 24, height = 24, color = black, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
      <path d='M19 5C18.5333 5.46667 9.47222 14.5278 5 19' stroke={color} strokeWidth='1.5' />
      <path d='M19 19C18.5333 18.5333 9.47222 9.47222 5 5' stroke={color} strokeWidth='1.5' />
    </svg>
  );
};

export default Close24;
