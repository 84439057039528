import { UrlObject } from 'url';
import { callDeepLink, isBrowser, toQueryString } from '@croquiscom/web2app';
import DefaultRouter from 'next/router';
import config from './config';
import { match, Route, ZpayHeaderType } from './route_util';
import { ZpayEvent, checkZPayFunctionExists } from './zigzag_app_event';

function fetchAppToUrl(url: string) {
  if (!url.startsWith('http')) {
    if (isBrowser() && window.location.pathname.startsWith('/app/')) {
      url = `/app${url.replace(/^\/app/, '')}`;
    }
    return `${config.appUrl}${url}`;
  }
  return url;
}

function hrefToNextRouteProps(href: string | UrlObject) {
  const { route } = match(href);
  if (typeof href === 'object' && href.pathname) {
    href.pathname = fetchAppToUrl(href.pathname);
  } else if (typeof href === 'string') {
    href = fetchAppToUrl(href);
  }
  return route ? { as: href, href, route } : { as: href, href };
}

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
}

interface ZPayFnOptions {
  action?: 'close' | 'createOrderSheet';
  type?: ZpayHeaderType;
  title?: string;
  disable_zpay_function?: boolean;
}

const handleChangeRouteForZPay =
  (route: Route | undefined, as: string | UrlObject, options: ZPayFnOptions) =>
  (fallback: () => void = () => undefined) => {
    const url =
      typeof as !== 'string' && as.query
        ? `${as.pathname}?${typeof as.query === 'string' ? as.query : toQueryString(as.query)}`
        : (as as string);
    const title = options.title ? options.title : route && route.title ? route.title : '-';
    const type = options.type ? options.type : route && route.type ? route.type : 'normal';

    if (options.disable_zpay_function || !checkZPayFunctionExists(window)) {
      return fallback();
    }
    switch (options.action) {
      case 'close':
        return ZpayEvent.close();
      case 'createOrderSheet':
        return ZpayEvent.createOrderSheet(url, title, type);
      default:
        return ZpayEvent.open(url, title, type);
    }
  };

export const Router = {
  push: (
    url: string | UrlObject,
    zpayFnOptions: ZPayFnOptions = { disable_zpay_function: false },
    options?: TransitionOptions,
  ) => {
    const { href, as, route } = hrefToNextRouteProps(url);
    handleChangeRouteForZPay(
      route,
      as,
      zpayFnOptions,
    )(() => {
      DefaultRouter.push(href, as, options);
    });
  },

  replace: (
    url: string | UrlObject,
    zpayFnOptions: ZPayFnOptions = { disable_zpay_function: false },
    options?: TransitionOptions,
  ) => {
    const { href, as, route } = hrefToNextRouteProps(url);
    handleChangeRouteForZPay(
      route,
      as,
      zpayFnOptions,
    )(() => {
      DefaultRouter.replace(href, as, options);
    });
  },

  open: (url: string | UrlObject, zpayFnOptions: ZPayFnOptions = { disable_zpay_function: false }) => {
    const { href, as, route } = hrefToNextRouteProps(url);
    handleChangeRouteForZPay(
      route,
      as,
      zpayFnOptions,
    )(() => {
      window.open(href as string, '_blank');
    });
  },
};

export function gotoShopHome(shop_main_domain: string, shop_url: string, origin = '') {
  if (checkZPayFunctionExists(window)) {
    if (!origin) {
      callDeepLink(`/shops/${shop_main_domain}`)();
    } else {
      callDeepLink(`/shops/${shop_main_domain}`)({ zz_origin: origin });
    }
  } else {
    window.open(shop_url, '_blank');
  }
}

export function gotoShopCategory(params: {
  shop_id: string;
  detailed_category?: string;
  display_category_id?: string;
  title: string;
  store_home_deep_link: string | null;
  zz_origin?: string;
}) {
  if (!checkZPayFunctionExists(window)) {
    return;
  }
  if (params.store_home_deep_link) {
    const deepLinkUrls = params.store_home_deep_link.replace('zigzag://', '').split('?');
    const linkUrl = deepLinkUrls[0];
    const linkParams = deepLinkUrls[1].split('&');
    linkParams.push('zz_origin=zonly');

    callDeepLink(`${linkUrl}?${linkParams.join('&')}`)();
    return;
  }
  callDeepLink('/shop_category_goods_list')(params);
}

export function getUrlByPath(pathname: string) {
  if (isBrowser()) {
    pathname =
      checkZPayFunctionExists(window) || document.referrer.indexOf(`${window.location.origin}/app`) >= 0
        ? `/app${pathname}`
        : pathname;
    return `${window.location.origin}${pathname}`;
  }
  return pathname;
}
