import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const Setting24: FC<IconProps> = ({ width = 24, height = 24, color = colors.black, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M9.142 21.5C7.48803 21.0119 5.99009 20.1089 4.794 18.8789C5.17884 18.4278 5.41873 17.8734 5.48299 17.2868C5.54726 16.7002 5.43296 16.1079 5.15472 15.5858C4.87649 15.0638 4.44697 14.6356 3.92114 14.3562C3.39531 14.0767 2.79708 13.9587 2.203 14.0172C2.06766 13.362 1.99965 12.6949 2 12.0262C2 10.9934 2.16 9.99711 2.458 9.06115H2.5C3.00988 9.06131 3.51139 8.93303 3.95717 8.68842C4.40295 8.4438 4.7783 8.09093 5.04777 7.66312C5.31724 7.23532 5.47194 6.74668 5.49726 6.24337C5.52258 5.74005 5.4177 5.23866 5.1925 4.78654C6.3648 3.70767 7.78253 2.92342 9.326 2.5C9.57719 2.98745 9.95992 3.39673 10.4319 3.68253C10.9038 3.96834 11.4465 4.11954 12 4.11941C12.5535 4.11954 13.0962 3.96834 13.5681 3.68253C14.0401 3.39673 14.4228 2.98745 14.674 2.5C16.2175 2.92342 17.6352 3.70767 18.8075 4.78654C18.5807 5.24183 18.4759 5.74704 18.5031 6.25382C18.5304 6.7606 18.6888 7.25198 18.9632 7.68093C19.2376 8.10988 19.6188 8.46204 20.0703 8.7037C20.5218 8.94537 21.0286 9.06844 21.542 9.06115C21.8465 10.0207 22.0009 11.0206 22 12.0262C22 12.7082 21.93 13.3743 21.797 14.0177C21.2029 13.9592 20.6047 14.0772 20.0789 14.3567C19.553 14.6361 19.1235 15.0643 18.8453 15.5863C18.567 16.1084 18.4527 16.7007 18.517 17.2873C18.5813 17.8739 18.8212 18.4283 19.206 18.8794C18.0098 20.1092 16.5119 21.0121 14.858 21.5C14.6638 20.9017 14.2824 20.3798 13.7687 20.0095C13.2551 19.6393 12.6357 19.4398 12 19.4398C11.3643 19.4398 10.7449 19.6393 10.2313 20.0095C9.71764 20.3798 9.33622 20.9017 9.142 21.5Z'
        stroke={color}
        strokeWidth='1.4'
        strokeLinejoin='round'
      />
      <path
        d='M12 15C12.394 15 12.7841 14.9224 13.1481 14.7716C13.512 14.6209 13.8427 14.3999 14.1213 14.1213C14.3999 13.8427 14.6209 13.512 14.7716 13.1481C14.9224 12.7841 15 12.394 15 12C15 11.606 14.9224 11.2159 14.7716 10.8519C14.6209 10.488 14.3999 10.1573 14.1213 9.87868C13.8427 9.6001 13.512 9.37913 13.1481 9.22836C12.7841 9.0776 12.394 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15V15Z'
        stroke={color}
        strokeWidth='1.4'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Setting24;
