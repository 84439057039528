import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { black, white } from 'styles';

const RoundCancel20: FC<IconProps> = ({ size = 20, color = black, sub_color = white, opacity = 0.3, ...props }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 20 20' fill='none' {...props}>
      <rect width={size} height={size} rx='10' fill={color} fillOpacity={opacity} />
      <path d='M5.5 5.5L14.5 14.5' stroke={sub_color} strokeWidth='1.4' />
      <path d='M14.5 5.5L5.5 14.5' stroke={sub_color} strokeWidth='1.4' />
    </svg>
  );
};

export default RoundCancel20;
