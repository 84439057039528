import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray600 } from 'styles';

const BottomLikeN24: FC<IconProps> = ({ width = 24, height = 24, color = gray600, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.0518 6.51661C20.6858 5.71893 20.1468 5.03093 19.4818 4.51244C18.8167 3.99394 18.0457 3.66064 17.2357 3.54153C16.1361 3.39608 15.0226 3.63486 14.0552 4.22356C13.2473 4.71157 12.6494 5.46122 12.0004 6.16971C11.3513 5.46122 10.7589 4.69981 9.94555 4.22356C8.97809 3.63486 7.86459 3.39608 6.76502 3.54153C5.95507 3.66064 5.18404 3.99394 4.51897 4.51244C3.8539 5.03093 3.31493 5.71893 2.94893 6.51661C1.99289 8.68618 2.65808 11.1056 4.05578 12.8225C6.24526 15.4889 6.83819 15.8368 12 20.5C17.1722 15.8368 17.7339 15.5006 19.9234 12.8225C21.3427 11.1056 22.0078 8.68618 21.0518 6.51661Z'
      stroke={color}
      strokeWidth='1.4'
    />
  </svg>
);
export default BottomLikeN24;
