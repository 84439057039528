import { isIOS } from '@croquiscom/web2app';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { appLandingUrl } from 'components/common/AppLandingNotify/useAdLadingNotify';
import { useBottomDialog } from 'context/ButtomDialogContext';
import { isKaKaoTalkPrivateBroswer } from 'util/kakao_utils';

// kakao 전용 브라우저 확인 훅
export const useIsKakaoPrivateBrowser = () => {
  const [is_kakao_browser, setIsKakaoBrowser] = useState(false);
  const is_kakao_private_browser = isKaKaoTalkPrivateBroswer();

  useEffect(() => {
    setIsKakaoBrowser(is_kakao_private_browser);
  }, [is_kakao_private_browser]);

  return is_kakao_browser;
};

// kakao AOS 전용 브라우저 체크 - AOS 전용웹뷰 새창 버그로 인한 체크필요
// feat: https://croquis.atlassian.net/browse/FBK-445
export const useIsKakaoAosPrivateBrowser = () => {
  const is_kakao_browser = useIsKakaoPrivateBrowser();
  return is_kakao_browser && !isIOS();
};

enum CONFIRM_ID {
  CANCEL = 'CANCEL',
  OK = 'OK',
}

//  AOS 전용 브라우저 새창버그로 결제버그가 존재해 이경우 앱 유도 처리
// feat: https://croquis.atlassian.net/browse/FBK-445
export const useKakaoPurchaseErrorDialog = () => {
  const { open: openAppLinkGuide } = useBottomDialog();
  const router = useRouter();
  const openErrorDialog = async () => {
    const id = await openAppLinkGuide({
      contents: {
        text: '현재 사용중인 버전의 카카오톡에서는\n일시적으로 결제가 불가능합니다. \n\n패션바이카카오 앱에서 결제해주세요.',
      },
      buttons: [
        { id: CONFIRM_ID.CANCEL, size: 'large', variant: 'tertiary_gray', children: '취소' },
        { id: CONFIRM_ID.OK, size: 'large', children: '앱으로 이동' },
      ],
    });

    if (id === CONFIRM_ID.OK) {
      window.location.assign(appLandingUrl);
    } else {
      router.back();
    }
  };

  return { openErrorDialog };
};
