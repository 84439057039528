import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const Review32: FC<IconProps> = ({ width = 32, height = 32, color = colors.gray700, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 33 32' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.44492 13.7318C5.44492 9.11193 9.19006 5.3668 13.8099 5.3668H18.5215C23.1413 5.3668 26.8865 9.11193 26.8865 13.7318C26.8865 18.2716 23.2698 21.967 18.7608 22.0934L18.39 22.1038L18.1904 22.4166L16.1657 25.5887L14.141 22.4166L13.9414 22.1038L13.5706 22.0934C9.06163 21.967 5.44492 18.2716 5.44492 13.7318ZM13.8099 3.9668C8.41686 3.9668 4.04492 8.33873 4.04492 13.7318C4.04492 18.9051 8.06759 23.1386 13.1559 23.4752L15.5756 27.2663L16.1657 28.1908L16.7558 27.2663L19.1755 23.4752C24.2638 23.1386 28.2865 18.9051 28.2865 13.7318C28.2865 8.33873 23.9145 3.9668 18.5215 3.9668H13.8099ZM16.1342 14.9798C16.7913 14.9798 17.3239 14.4472 17.3239 13.7902C17.3239 13.1331 16.7913 12.6005 16.1342 12.6005C15.4772 12.6005 14.9446 13.1331 14.9446 13.7902C14.9446 14.4472 15.4772 14.9798 16.1342 14.9798ZM11.4547 14.9799C12.1117 14.9799 12.6443 14.4473 12.6443 13.7903C12.6443 13.1332 12.1117 12.6006 11.4547 12.6006C10.7976 12.6006 10.265 13.1332 10.265 13.7903C10.265 14.4473 10.7976 14.9799 11.4547 14.9799ZM22.0034 13.7902C22.0034 14.4472 21.4707 14.9798 20.8137 14.9798C20.1567 14.9798 19.624 14.4472 19.624 13.7902C19.624 13.1331 20.1567 12.6005 20.8137 12.6005C21.4707 12.6005 22.0034 13.1331 22.0034 13.7902Z'
        fill={color}
      />
    </svg>
  );
};

export default Review32;
