import React, { useCallback, useState } from 'react';

const ScrollRestorationContext = React.createContext<{
  setScroll: (key: string, y: number) => void;
  getScroll: (key: string) => number;
}>({
  setScroll: () => {},
  getScroll: () => 0,
});

export const useScrollRestoration = () => React.useContext(ScrollRestorationContext);

/**
 * 스크롤 복원을 위한 페이지 복원 globalState Context
 * TODO: jotai로 전환예정 - jotai 페이지 단위 -> root 통합 이후
 */
export function ScrollRestorationProvider({ children }: { children: React.ReactNode }) {
  const [scrollRestoration, setScrollRestoration] = useState<Array<{ key: string; y: number }>>([]);

  const setScroll = useCallback((key: string, y: number) => {
    setScrollRestoration((keys) => {
      return {
        ...keys,
        [key]: y,
      };
    });
  }, []);

  const getScroll = useCallback(
    (key: string) => {
      // @ts-ignore
      return scrollRestoration[key];
    },
    [scrollRestoration],
  );

  return (
    <ScrollRestorationContext.Provider
      value={{
        getScroll,
        setScroll,
      }}
      children={children}
    />
  );
}
