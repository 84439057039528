import styled from '@emotion/styled';
import lottie from 'lottie-web';
import { FC, ReactText, useRef, useEffect } from 'react';
import { IconClose24 } from 'components/base/Icon';
import Text from 'components/base/Text';
import { black, gray_tertiary, kakao_color } from 'styles';
import { typography } from 'styles/font';
import config from 'util/config';

const login_info_img = `${config.s3_cf_url}/auth/login_img.png`;

export enum LoginDialogId {
  LOGIN = 'LOGIN',
  CLOSE = 'CLOSE',
}
interface Props {
  handleSubmit: (id: ReactText) => void;
}

const LoginDialog: FC<Props> = ({ handleSubmit }) => {
  const lottie_ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (lottie_ref.current) {
      const animate = lottie.loadAnimation({
        container: lottie_ref.current,
        autoplay: true,
        loop: true,
        animationData: require('./images/login_lottie.json'),
      });
      animate.setSpeed(2);
    }
  }, []);

  return (
    <SC.Container>
      <SC.Header>
        <IconClose24 color={gray_tertiary} onClick={() => handleSubmit(LoginDialogId.CLOSE)} />
      </SC.Header>
      <SC.Img src={login_info_img} />
      <div className='lottie' ref={lottie_ref} />
      <SC.KakaoLogin onClick={() => handleSubmit(LoginDialogId.LOGIN)}>카카오 로그인</SC.KakaoLogin>
      <Text variant='small1_regular' color='gray500' mt={17} mb={40}>
        패션바이카카오는 지그재그, 포스티 계정으로 로그인 됩니다.
      </Text>
    </SC.Container>
  );
};

export default LoginDialog;

const SC = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;

    .lottie {
      width: 190px;
      height: 64px;
    }
  `,
  Header: styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 40px;

    > svg {
      cursor: pointer;
    }
  `,
  KakaoLogin: styled.button`
    ${typography.title4_medium}
    background-color: ${kakao_color};
    color: ${black};
    height: 51px;
    width: 100%;
    border-radius: 4px;
    position: relative;
  `,
  Img: styled.img`
    max-width: 355px;
    margin: 0 20px;
  `,
};
