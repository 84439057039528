import type { AirbridgeAPI } from 'airbridge-web-sdk-loader/build/airbridge_api';
import { type EventOption } from 'airbridge-web-sdk-loader/build/airbridge_api/event_api';

declare global {
  interface Window {
    airbridge?: AirbridgeAPI;
  }
}

/**
 * Airbridge Tracker
 * - 'airbridge.' 으로 시작하는 event의 경우 Airbridge에서 제공하는 스탠다드 이벤트. (Airbridge Standard Event)
 * - 그 외에는 카카오스타일에서 추가한 커스텀 이벤트. (Custom Event)
 * - 이벤트를 추가하면 옆에 주석으로 간략하게 설명을 추가해주세요.
 */
type AirbridgeTrackerEventsSendEventName =
  | 'airbridge.user.signup' // 회원가입
  | 'airbridge.user.signin' // 로그인
  | 'airbridge.ecommerce.home.viewed' // 메인 화면조회
  | 'airbridge.ecommerce.product.viewed' // 상품 상세조회
  | 'airbridge.ecommerce.searchResults.viewed' // 검색결과조회
  | 'airbridge.ecommerce.product.addedToCart' // 장바구니담기
  | 'airbridge.addToWishlist' // 상품 찜 추가
  | 'airbridge.ecommerce.order.completed' // 구매완료
  | 'airbridge.ecommerce.order.canceled' // 구매취소
  | 'fist_purchase' // 첫구매
  | 'add_bookmark'; // 스토어 즐겨찾기

export function logAirbridge(event_name: AirbridgeTrackerEventsSendEventName, parameters?: EventOption) {
  if (typeof window !== 'undefined' && window.airbridge) {
    try {
      window.airbridge.events.send(event_name, parameters);
    } catch (error: any) {
      console.error('Airbridge log Error', error.message);
    }
  }
}
