// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const { Config } = require('./config');

Sentry.init({
  dsn: Config.sentry_dsn,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  sampleRate: 0.1,
  ignoreErrors: [
    'TypeError: Failed to fetch',
    'TypeError: Load failed',
    'TypeError: NetworkError when attempting to fetch resource.',
    'TypeError: cancelled',
    'TypeError: 취소됨',
    'ResizeObserver loop limit exceeded',
    'Load failed',
    // chrome 108 버전 lottie-web svg 에러발생 특정 케이스
    'r is not a constructor',
    // kakao sdk 이슈
    'Non-Error exception captured with keys: description, extensions, message',
    // react18 hydration 이슈
    'Text content does not match server-rendered HTML.',
    'Hydration failed because the initial UI does not match what was rendered on the server.',
    'There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.',
    'This Suspense boundary received an update before it finished hydrating. This caused the boundary to switch to client rendering. The usual way to fix this is to wrap the original update in startTransition.',
  ],
  denyUrls: [/extensions\//i, /^chrome:\/\//i],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
