import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const Refresh16: FC<IconProps> = ({ width = 16, height = 16, color = colors.gray300, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.4539 4.04606L10 5.5H14V1.5L12.5165 2.98354C11.3203 1.90591 9.73675 1.25 8 1.25C4.27208 1.25 1.25 4.27208 1.25 8C1.25 11.7279 4.27208 14.75 8 14.75C11.7279 14.75 14.75 11.7279 14.75 8H13.25C13.25 10.8995 10.8995 13.25 8 13.25C5.10051 13.25 2.75 10.8995 2.75 8C2.75 5.10051 5.10051 2.75 8 2.75C9.32251 2.75 10.5308 3.239 11.4539 4.04606Z'
        fill={color}
      />
    </svg>
  );
};

export default Refresh16;
