import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray600 } from 'styles';

const BottomStyleN24: FC<IconProps> = ({ width = 24, height = 24, color = gray600, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M20.5 2.5H21.2V1.8H20.5V2.5ZM20.5 18.5V19.2H21.2V18.5H20.5ZM3.5 18.5H2.8V19.2H3.5V18.5ZM5.5 3.2H20.5V1.8H5.5V3.2ZM19.8 2.5V18.5H21.2V2.5H19.8ZM20.5 17.8H3.5V19.2H20.5V17.8ZM4.2 18.5V4.5H2.8V18.5H4.2ZM5.5 1.8C4.00883 1.8 2.8 3.00883 2.8 4.5H4.2C4.2 3.78203 4.78203 3.2 5.5 3.2V1.8Z'
      fill={color}
    />
    <path
      d='M20.5 21.5H21.2V22.2H20.5V21.5ZM20.5 18.5V17.8H21.2V18.5H20.5ZM3.5 18.5H2.8V17.8H3.5V18.5ZM5.5 20.8H20.5V22.2H5.5V20.8ZM19.8 21.5V18.5H21.2V21.5H19.8ZM20.5 19.2H3.5V17.8H20.5V19.2ZM4.2 18.5V19.5H2.8V18.5H4.2ZM5.5 22.2C4.00883 22.2 2.8 20.9912 2.8 19.5H4.2C4.2 20.218 4.78203 20.8 5.5 20.8V22.2Z'
      fill={color}
    />
    <path
      d='M11.7313 6.54073C11.8416 6.31872 12.1584 6.31872 12.2687 6.54073L13.2581 8.5321C13.3018 8.62002 13.3857 8.681 13.4828 8.69538L15.6825 9.021C15.9277 9.05731 16.0256 9.35852 15.8485 9.53204L14.2604 11.0884C14.1902 11.1571 14.1582 11.2558 14.1745 11.3526L14.5446 13.5452C14.5858 13.7896 14.3296 13.9758 14.1098 13.861L12.1389 12.8315C12.0519 12.7861 11.9481 12.7861 11.8611 12.8315L9.89016 13.861C9.67042 13.9758 9.41419 13.7896 9.45545 13.5452L9.82549 11.3526C9.84182 11.2558 9.80976 11.1571 9.73965 11.0884L8.15149 9.53204C7.97443 9.35852 8.0723 9.05731 8.31754 9.021L10.5172 8.69538C10.6143 8.681 10.6982 8.62002 10.7419 8.5321L11.7313 6.54073Z'
      fill={color}
    />
  </svg>
);
export default BottomStyleN24;
