import { EffectCallback, useEffect, useRef, DependencyList } from 'react';

/**
 * @hook mount 시점은 제외하고 update 시점에만 effect 를 진행해야 할 경우 사용
 * @example
 *  useUpdateEffect(() => {
 *   ...
 * },[...args]);
 */
export const useUpdateEffect = (effect_callback: EffectCallback, deps: DependencyList) => {
  const ref = useRef(false);

  useEffect(() => {
    if (ref.current) {
      effect_callback();
    } else {
      ref.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
};
