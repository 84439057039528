import { useState, useCallback } from 'react';

export const useToggle = (initialState?: boolean) => {
  const [state, setState] = useState(initialState ?? false);

  const toggle = useCallback(() => {
    setState((prev) => !prev);
  }, []);

  return [state, toggle] as [boolean, () => void];
};
