import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray_primary } from 'styles';

const NavigationHome24: FC<IconProps> = ({ width = 24, height = 24, color = gray_primary, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M3.5 19.5V9.5L12 3L20.5 9.5V19.5C20.5 20.6046 19.6046 21.5 18.5 21.5H5.5C4.39543 21.5 3.5 20.6046 3.5 19.5Z'
      stroke={color}
      strokeWidth='1.4'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 15C12.8284 15 13.5 15.6716 13.5 16.5V22H10.5V16.5C10.5 15.6716 11.1716 15 12 15Z'
      fill={color}
    />
  </svg>
);
export default NavigationHome24;
