import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray400 } from 'styles';

const Airplane20: FC<IconProps> = ({ width = 20, height = 20, color = gray400, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 20 20' fill='none' {...props}>
    <path
      d='M16.6369 3.35708C17.121 3.8414 17.121 4.62123 16.6369 5.09733L13.4451 8.29053L15.1846 15.8344L14.0277 17L10.8441 10.9009L7.6441 14.1023L7.93949 16.1299L7.06154 17L5.61744 14.3896L3 12.9367L3.86974 12.0501L5.92103 12.3539L9.09641 9.17707L3 5.96746L4.16513 4.81003L11.7056 6.55028L14.8974 3.35708C15.3569 2.88097 16.1774 2.88097 16.6369 3.35708Z'
      fill={color}
    />
  </svg>
);

export default Airplane20;
