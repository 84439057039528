import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray600 } from 'styles';

const BottomCategoryN24: FC<IconProps> = ({ width = 24, height = 24, color = gray600, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
    <path d='M0 7H6' stroke={color} strokeWidth='1.4' />
    <path d='M0 12H5' stroke={color} strokeWidth='1.4' />
    <path d='M0 17H7' stroke={color} strokeWidth='1.4' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.1599 7.13993C20.3822 9.36218 20.3822 12.9652 18.1599 15.1874C15.9377 17.4097 12.3347 17.4097 10.1125 15.1874C7.89021 12.9652 7.89021 9.36218 10.1125 7.13993C12.3347 4.91768 15.9377 4.91768 18.1599 7.13993ZM19.709 15.5486C21.9043 12.7658 21.7179 8.718 19.1499 6.14998C16.3809 3.381 11.8915 3.381 9.12252 6.14998C6.35353 8.91897 6.35353 13.4084 9.12252 16.1774C11.7582 18.8131 15.9527 18.9399 18.7385 16.558L22.6983 20.5177L23.6882 19.5278L19.709 15.5486Z'
      fill={color}
    />
  </svg>
);
export default BottomCategoryN24;
