import styled from '@emotion/styled';
import BottomSheet from '../BottomSheet/index';
import { useAdLandingNotify } from './useAdLadingNotify';
import { Button } from 'components/base/Button';
import Text from 'components/base/Text';
import { gray_tertiary, white } from 'styles';
import { typography } from 'styles/font';
import { isServiceCloseNotify } from 'util/app_utils';
import config from 'util/config';

const logo_img = `${config.s3_cf_url}/common/welcome_coupon.png`;
const APP_NOTIFY_HIDE_END_DATE = 'APP_NOTIFY_HIDE_END_DATE';

/**
 * 앱 랜딩 알림 - 바텀 다이얼로그 타입
 * @component
 */
const Dialog = () => {
  const { is_show, openApp, close } = useAdLandingNotify(APP_NOTIFY_HIDE_END_DATE);

  if (isServiceCloseNotify()) {
    return null;
  }

  return (
    <BottomSheet active={is_show}>
      <SC.Container>
        <SC.AdInfo>
          <Text variant='title2_bold' color='gray_primary' as='h2' alignment='center'>
            앱에서 더 많은 혜택이
            <br />
            기다리고 있어요!
          </Text>
          <Text variant='body3' color='gray_secondary' as='p' mt={8} mb={22}>
            당신을 위해 준비한 혜택을 놓치지 마세요.
          </Text>
          <SC.LogoImg src={logo_img} alt='logo image' />
        </SC.AdInfo>
        <Button size='large' fill onClick={openApp}>
          앱으로 보기
        </Button>
        <SC.CancelButton onClick={close}>오늘 하루 보지 않기</SC.CancelButton>
      </SC.Container>
    </BottomSheet>
  );
};

export default Dialog;

const SC = {
  Container: styled.div`
    padding: 0 20px 21px;
  `,
  AdInfo: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 16px 32px;
  `,
  LogoImg: styled.img`
    width: 214px;
    height: 102px;
  `,
  CancelButton: styled.button`
    width: 100%;
    height: 40px;
    padding: 10px 0;
    ${typography.body3}
    background-color: ${white};
    color: ${gray_tertiary};
  `,
};
