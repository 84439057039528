import numeral from 'numeral';

export const numberWithCommas = (num?: number | null) => {
  if (!num) {
    return 0;
  }
  return numeral(num).format('0,0');
};

export const isNumberOnly = (num: string) => {
  const reg = new RegExp('^[0-9]*$');
  return reg.test(num);
};

export const numberInTenThousand = (num: number | null) => {
  if ((typeof num === 'number' && num < 0) || num === null) {
    return 0;
  }

  const unitWords = ['', '만'];
  const splitUnit = 10000;

  return unitWords
    .map((_, i) => {
      const unitResult = Math.floor((num % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i));
      if (unitResult > 0) {
        return unitResult;
      }
      return null;
    })
    .reduce((acc, cur, i) => {
      if (cur) {
        return cur.toLocaleString('ko-KR') + unitWords[i] + acc;
      }
      return acc;
    }, '');
};

export const numberwithComma = (
  num?: number | null,
  options?: {
    max_count?: number;
    max_count_prefix?: string;
    max_count_subfix?: string;
  },
) => {
  if (!num) {
    return '0';
  }

  if (!options) {
    return num.toLocaleString('ko-KR');
  }

  const { max_count = 999999999, max_count_prefix = '', max_count_subfix = '' } = options;

  if (max_count > num) {
    return num.toLocaleString('ko-KR');
  }

  return `${max_count_prefix}${max_count.toLocaleString('ko-KR')}${max_count_subfix}`;
};

export const formatKoreanNumber = (num: number) => {
  const ten_thousand = Math.floor(num / 10000);
  const thousand = num % 10000;
  const thousand_text = `${thousand / 1000}천`;

  if (num < 1000) {
    return num;
  }

  if (num < 10000) {
    return thousand_text;
  }

  let result = '';
  if (ten_thousand > 0) {
    result += `${ten_thousand}만`;
  }

  if (thousand > 0) {
    result += ` ${thousand_text}`;
  }

  return result;
};

export const numberRoundDown = (num: number, digit: number = 1000) => {
  const rounded_value = Math.floor(num / digit) * digit;
  const min_value = digit;

  return Math.max(rounded_value, min_value);
};
