import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import Text from 'components/base/Text';
import { ColorType } from 'styles';
import { FontVarient } from 'styles/font';

export interface ContentsProps {
  /**
   * 아이콘을 정의합니다.
   */
  icon?: ReactNode;
  /**
   * icon, image 세팅
   */
  src?: string;
  /**
   * 시트 타입
   */
  type?: 'image' | 'icon';
  /**
   * 타이틀 내용을 정의합니다.
   */
  title?: ReactNode;
  /**
   * 타이틀의 폰트를 정의합니다.
   * @default title3_bold
   */
  title_variant?: FontVarient;
  /**
   * 타이틀의 색상을 정의합니다.
   * @default black
   */
  title_color?: ColorType;
  /**
   * 텍스트 내용을 정의합니다.
   */
  text?: ReactNode;
  /**
   * 텍스트의 폰트를 정의합니다.
   * @default body3
   */
  text_variant?: FontVarient;
  /**
   * 텍스트의 색상을 정의합니다.
   * @default gray_secondary
   */
  text_color?: ColorType;
}
interface Props {
  contents?: ContentsProps;
}

/**
 * Bottom Dialog에서 사용되는 공통 contents 컴포넌트
 * @component
 */
const DefaultContents: FC<Props> = ({ contents }) => {
  return (
    <SC.Container>
      {contents?.icon && <SC.Icon>{contents.icon}</SC.Icon>}
      {contents?.title && (
        <SC.Title variant={contents.title_variant || 'title3_bold'} color={contents.title_color || 'black'}>
          {contents.title}
        </SC.Title>
      )}
      {contents?.text && (
        <SC.Contents variant={contents.text_variant || 'body3'} color={contents.text_color || 'gray_secondary'}>
          {contents.text}
        </SC.Contents>
      )}
    </SC.Container>
  );
};

export default DefaultContents;

const SC = {
  Container: styled.div`
    padding: 0 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: pre-wrap;
  `,
  Icon: styled.div`
    padding-top: 8px;
    width: 100%;
    height: 100%;
    text-align: center;
  `,
  Title: styled(Text)`
    padding-top: 8px;
    text-align: center;
  `,
  Contents: styled(Text)`
    padding-top: 8px;
    text-align: center;
  `,
};
