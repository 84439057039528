import { render, unmountComponentAtNode } from 'react-dom';

export interface OverlayCompProps<Resolve = string, Reject = unknown> {
  resolve?: (value?: Resolve) => void;
  reject?: (error?: Reject) => void;
  dispose?: () => void;
  [key: string]: any;
}

export type RenderOverlay<ResolveValue = string, Message = unknown> = (
  message: Message,
) => ({ resolve }: OverlayCompProps<ResolveValue>) => JSX.Element;

export default function createOverlayComp<ReturnValue = string, Message = unknown>(
  renderComp: RenderOverlay<ReturnValue, Message>,
  unmountDelay = 300,
  mountingNode?: HTMLElement,
) {
  return (props: Message) => {
    const wrapper = (mountingNode || document.body).appendChild(document.createElement('div'));
    const dialog = renderComp(props);

    function dispose() {
      setTimeout(() => {
        unmountComponentAtNode(wrapper);
        setTimeout(() => {
          if (document.body.contains(wrapper)) {
            document.body.removeChild(wrapper);
          }
        });
      }, unmountDelay);
    }

    const promise = new Promise((resolve: (value: ReturnValue) => void, reject) => {
      //@ts-ignore
      const Comp = dialog({ dispose, reject, resolve });
      try {
        render(Comp, wrapper);
      } catch (e) {
        console.error(e);
        throw e;
      }
    });

    return promise.then(
      (result) => {
        return result;
      },
      (result) => {
        dispose();
        return Promise.reject(result);
      },
    );
  };
}
