import { FC } from 'react';
import { red500 } from '../../../styles';
import { IconProps } from './index';

const Exclamation16: FC<IconProps> = ({ width = 16, height = 16, color = red500, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M9 2.5H7V9.5H9V2.5ZM9 11.5H7V13.5H9V11.5Z' fill={color} />
    </svg>
  );
};

export default Exclamation16;
