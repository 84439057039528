import { FC } from 'react';
import { IconProps } from './index';
import { black } from 'styles';

const Camera20: FC<IconProps> = ({ width = 20, height = 20, color = black, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.1666 3.0665H5.83329C4.30531 3.0665 3.06663 4.30518 3.06663 5.83317V14.1665C3.06663 15.6945 4.30531 16.9332 5.83329 16.9332H14.1666C15.6946 16.9332 16.9333 15.6945 16.9333 14.1665V5.83317C16.9333 4.30518 15.6946 3.0665 14.1666 3.0665ZM5.83329 1.6665C3.53211 1.6665 1.66663 3.53199 1.66663 5.83317V14.1665C1.66663 16.4677 3.53211 18.3332 5.83329 18.3332H14.1666C16.4678 18.3332 18.3333 16.4677 18.3333 14.1665V5.83317C18.3333 3.53198 16.4678 1.6665 14.1666 1.6665H5.83329ZM10.0001 12.0092C11.1096 12.0092 12.0091 11.1097 12.0091 10.0001C12.0091 8.89053 11.1096 7.99103 10.0001 7.99103C8.89046 7.99103 7.99096 8.89053 7.99096 10.0001C7.99096 11.1097 8.89046 12.0092 10.0001 12.0092ZM10.0001 13.4092C11.8828 13.4092 13.4091 11.8829 13.4091 10.0001C13.4091 8.11733 11.8828 6.59103 10.0001 6.59103C8.11726 6.59103 6.59096 8.11733 6.59096 10.0001C6.59096 11.8829 8.11726 13.4092 10.0001 13.4092ZM5.4546 4.3183C4.827 4.3183 4.31823 4.82707 4.31823 5.45466C4.31823 6.08226 4.827 6.59103 5.4546 6.59103C6.08219 6.59103 6.59096 6.08226 6.59096 5.45466C6.59096 4.82707 6.08219 4.3183 5.4546 4.3183Z'
        fill={color}
      />
    </svg>
  );
};

export default Camera20;
