import type { AirbridgeAPI } from 'airbridge-web-sdk-loader/build/airbridge_api';
import isArray from 'lodash-es/isArray';
import isNull from 'lodash-es/isNull';
import DefaultRouter from 'next/router';
import { createContext, FC, useContext, useEffect, useState, PropsWithChildren } from 'react';
import { logAirbridge } from './airbridge';
import {
  logFacebook,
  logFirebase,
  subl,
  logBraze,
  getAppVersion,
  isLowAppVersion,
  APP_VERSION_TYPE,
} from './app_utils';
import { getDevice } from './device';
import { DEFAULT_SORT_TYPE, getSearchFilterUbl, SearchFilter, SORT_TYPE } from './search_utils';
import config from 'util/config';

declare global {
  interface Window {
    // https://developers.google.com/analytics/devguides/collection/ga4
    gtag?: (type: string, ga_id: string, parameters: Record<string, any>) => void;
    // https://developers.facebook.com/docs/facebook-pixel/advanced/
    fbq?: (type: string, event: string, parameters?: Record<string, any>) => void;
    airbridge?: AirbridgeAPI;
  }
}

export function isInApp(user_agent: string) {
  if (typeof window !== 'undefined') {
    if ((window as any).fbk) {
      return true; // Android
    }
    if ((window as any).webkit?.messageHandlers?.fbk?.postMessage) {
      return true; // iOS
    }
  }
  return user_agent.includes('FBK');
}

export interface Log {
  category:
    | 'tti'
    | 'pageview'
    | 'click'
    | 'impression'
    | 'save_product'
    | 'remove_saved_product'
    | 'add_bookmark'
    | 'remove_bookmark'
    | 'remove_from_cart'
    | 'add_to_cart'
    | 'complete_zpay_purchase';
  navigation: string;
  navigation_sub?: Record<string, any>;
  object_section?: string;
  object_type?: string;
  object_id?: string;
  object_idx?: number;
  object_url?: string;
  data?: Record<string, any>;
  url?: string;
}
interface FacebookParams {
  content_id: string;
  content_name: string;
  currency: string;
  value: number;
}

export interface FacebookParamsPurchase extends FacebookParams {
  num_items: number;
}

export interface AirbridgeOrderParams {
  transactionID: string;
  totalValue: number;
  currency: string;
  totalQuantity: number;
  products: [
    {
      productID: string;
      name: string;
      categoryID: string | null;
      categoryName: string | null;
      brandID: string;
      brandName: string;
      price: number;
      quantity: number;
    },
  ];
}

// 홈 페이지 영역에 대한 페이지뷰 로그 조회
function getHomePageViewLog(tab: string, sub_tab: string, url: string): Log {
  if (tab === 'best') {
    return {
      url,
      category: 'pageview',
      navigation: 'home_best',
      object_section: sub_tab || 'product',
      navigation_sub: { page_url: url },
    };
  }

  if (tab === 'sale') {
    return { url, category: 'pageview', navigation: 'home_benefits', navigation_sub: { page_url: url } };
  }

  if (tab === 'new') {
    return { url, category: 'pageview', navigation: 'home_curation', data: { is_opened: false } };
  }

  return { url, category: 'pageview', navigation: 'home_ad', navigation_sub: { page_url: url } };
}

function getIsSendNativeVersion() {
  const is_app = getAppVersion(navigator.userAgent) !== '';
  const is_row_version = isLowAppVersion(APP_VERSION_TYPE.NATIVE_SEND_PAGEVIEW_LOG, navigator.userAgent);
  return is_app && is_row_version;
}

function getPageviewLog(path: string): Log | Log[] | null {
  try {
    const url = path.startsWith('/') ? window.location.origin + path : path;
    const { pathname, search, searchParams } = new URL(url);
    const params = search ? Object.fromEntries(searchParams) : undefined;
    const is_send_native_version = getIsSendNativeVersion();

    const is_main_tab_path = ['/', '/home', '/categories', '/like', '/stores', '/my-page'].some((main_tab) =>
      pathname.startsWith(main_tab),
    );
    const is_send_main_tab_pageview = is_main_tab_path && !is_send_native_version;

    if (is_send_main_tab_pageview) {
      // home
      if (pathname === '/' || pathname === '/home') {
        return getHomePageViewLog(params?.tab ?? '', params?.sub_tab ?? '', url);
      }

      // 카테고리탭
      const is_categories_main = pathname.startsWith('/categories');
      if (is_categories_main) {
        return {
          category: 'pageview',
          navigation: 'category_tab',
          data: { is_opened: false },
        };
      }

      // style탭
      if (pathname === '/style') {
        return {
          url,
          category: 'pageview',
          navigation: 'style',
          navigation_sub: { page_url: url },
          data: { style_category_id: 'ALL' },
        };
      }

      // store탭
      if (pathname === '/stores' && params?.category === undefined) {
        return {
          url,
          category: 'pageview',
          navigation: 'store',
        };
      }

      // 찜탭
      if (pathname === '/like') {
        return {
          url,
          category: 'pageview',
          navigation: 'saved_product',
          object_section: params?.tab === 'store' ? 'store' : 'product',
          navigation_sub: { page_url: url },
        };
      }

      // 마이페이지탭
      if (pathname === '/my-page') {
        return {
          category: 'pageview',
          navigation: 'my_page',
          data: { is_opened: false },
        };
      }
    }

    // best store
    if (pathname === '/best-stores') {
      return {
        url,
        category: 'pageview',
        navigation: 'best_store_page',
      };
    }

    // 스토어픽
    if (pathname === '/storepick') {
      return {
        url,
        category: 'pageview',
        navigation: 'banner_detail',
        navigation_sub: { page_url: url },
      };
    }

    // 검색
    if (pathname === '/search') {
      return {
        url,
        category: 'pageview',
        navigation: 'search_form',
        navigation_sub: { page_url: url },
        data: { is_opened: false },
      };
    }

    // 장바구니
    if (pathname === '/cart') {
      return {
        url,
        category: 'pageview',
        navigation: 'zpay_browser_cart',
        data: { is_opened: false },
        navigation_sub: { page_url: url },
      };
    }

    // 쿠폰함 - 다운로드
    if (pathname === '/coupons/download') {
      return {
        url,
        category: 'pageview',
        navigation: 'my_page_get_coupon',
      };
    }

    // 라방 상세
    const is_live_detail_path = pathname.startsWith('/lives/');
    if (is_live_detail_path) {
      const campaign_key = pathname.split('/lives/')[1];
      return {
        url,
        category: 'pageview',
        navigation: 'live_stream',
        navigation_sub: { campaign: campaign_key },
        data: { is_opened: false },
      };
    }

    const is_category_list_path = pathname.startsWith('/categories/');
    if (is_category_list_path) {
      const category_id = pathname.split('/categories/')[1];
      const params = new URLSearchParams(search);
      const sub_category_id = params.get('sub_category_id') ?? '-1';
      // 기본 정렬타입 인기순
      const sort_type: SORT_TYPE = (params.get('sort_type') ?? DEFAULT_SORT_TYPE) as SORT_TYPE;

      const has_price_filter = params.get('min_price') && params.get('max_price');

      const search_props: SearchFilter = {
        price: has_price_filter
          ? { max: Number(params.get('max_price')), min: Number(params.get('min_price')) }
          : undefined,
        color_list: params.get('color_list') ? JSON.parse(params.get('color_list') as string) : undefined,
        is_free_shipping: params.get('is_free_shipping') ? params.get('is_free_shipping') === 'true' : undefined,
        is_zigin_shipping: params.get('is_zigin_shipping') ? params.get('is_zigin_shipping') === 'true' : undefined,
        sort_type,
      };

      const filter_option_ubl = getSearchFilterUbl(search_props);
      return {
        url,
        category: 'pageview',
        navigation: 'category_landing',
        data: { category_id, sub_category_id, sort_type, ...filter_option_ubl },
      };
    }

    // 상품리스트 페이지 (신상, 베스트)
    if (pathname.startsWith('/product-list/')) {
      const page_type = pathname.split('/product-list/')[1];
      const params = new URLSearchParams(search);
      const category_id = params.get('category_id') ?? '-1';
      const sub_category_id = params.get('sub_category_id') ?? '-1';

      return {
        category: 'pageview',
        navigation: 'category_landing',
        data: { category_id, sub_category_id, title: page_type },
      };
    }

    // 이미지 검색 페이지
    if (pathname.startsWith('/search/image')) {
      return {
        url,
        category: 'pageview',
        navigation: params?.search_type ? 'image_search_result' : 'image_search',
      };
    }

    const is_promotion_path = pathname.startsWith('/promotion/');
    if (is_promotion_path) {
      const promotion_id = pathname.split('/promotion/')[1];
      return {
        category: 'pageview',
        navigation: 'promotion',
        navigation_sub: { page_url: url },
        data: { promotion_id },
      };
    }

    // 최근 본 상품
    const is_recent_products_path = pathname.startsWith('/recent-products');
    if (is_recent_products_path) {
      return {
        category: 'pageview',
        navigation: 'recently_view',
        data: { is_opened: false },
      };
    }

    // CMS 이벤트 페이지
    const is_events_cms_path = pathname.startsWith('/events/cms/');
    if (is_events_cms_path) {
      return [
        {
          category: 'pageview',
          navigation: 'content_detail',
          navigation_sub: { page_url: url },
          data: { is_opened: false },
        },
        {
          category: 'tti',
          navigation: 'content_detail',
          navigation_sub: { page_url: url },
        },
      ];
    }

    // 직진배송관
    const is_zigzin_category = pathname.startsWith('/special-categories/zigzin');
    if (is_zigzin_category) {
      return {
        category: 'pageview',
        navigation: 'zigzin_main',
        data: { is_opened: false },
      };
    }

    // 키즈관
    const is_kid_category = pathname.startsWith('/special-categories/kid');
    if (is_kid_category) {
      return {
        category: 'pageview',
        navigation: 'kids_main',
        data: { is_opened: false },
      };
    }
    // 출석체크
    const is_attendance_check = pathname.startsWith('/benefits/attendance-check');
    if (is_attendance_check) {
      return {
        category: 'pageview',
        navigation: 'attendance_check',
        data: { is_opened: false },
      };
    }

    // 빠른배송관
    const is_fast_delivery = pathname.startsWith('/special-categories/fast-delivery');
    if (is_fast_delivery) {
      return {
        category: 'pageview',
        navigation: 'fast_delivery_main',
      };
    }

    // 스토어 상품 리스트
    const is_store_product_list = pathname.startsWith('/store') && pathname.endsWith('/product-list');
    if (is_store_product_list) {
      return {
        url,
        category: 'pageview',
        navigation: 'recommend_store_plp',
        navigation_sub: { page_url: url },
      };
    }

    // 배너 모두보기 페이지
    const is_banner_views_path = pathname.startsWith('/banner-views/');
    if (is_banner_views_path) {
      const banner_page_name = pathname.split('/banner-views/')[1];
      // 트렌드탭 상단배너 모두보기
      if (banner_page_name === 'TREND_TAB') {
        return {
          category: 'pageview',
          navigation: 'banner_list',
          navigation_sub: { page_url: url },
          object_id: 'home_ad_banner',
          data: { is_opened: false },
        };
      }
      // 세일탭 상단배너 모두보기
      if (banner_page_name === 'SALE_TAB') {
        return {
          category: 'pageview',
          navigation: 'banner_list',
          navigation_sub: { page_url: url },
          object_id: 'benefits_banner',
          data: { is_opened: false },
        };
      }
    }

    // 스토어픽 모두보기
    const is_storepick_views_path = pathname.startsWith('/storepick-views');
    if (is_storepick_views_path) {
      return {
        category: 'pageview',
        navigation: 'storepick_list',
        data: { is_opened: false },
      };
    }

    const is_my_page_path = pathname.startsWith('/my-page/');
    if (is_my_page_path) {
      const pages = pathname.split('/my-page/')[1];
      switch (pages) {
        case 'reviews':
          return {
            category: 'pageview',
            navigation: 'my_review',
            object_section: params?.tab === 'completed' ? 'tab_writed_review' : 'tab_writable_review',
            data: { is_opened: false },
          };
        case 'profile':
          return { category: 'pageview', navigation: 'my_info', data: { is_opened: false } };
        case 'size-info':
          return { category: 'pageview', navigation: 'my_info', data: { is_opened: false, type: 'enter_size' } };
      }
    }
  } catch (e) {
    return null;
  }
  return null;
}

export class Tracker {
  logs: Log[] = [];
  timer_id: NodeJS.Timeout | undefined;
  in_app: boolean;
  device: string;

  constructor(readonly fake: boolean, readonly user_agent: string) {
    const { is_app, platform, app_version, os_version } = getDevice(user_agent);
    this.device = is_app ? `${platform} ${os_version} / ${app_version}` : platform;
    this.in_app = isInApp(user_agent);

    if (!fake && typeof window !== 'undefined') {
      window.addEventListener('beforeunload', () => {
        this.send(true);
      });
    }
  }

  trackPageview(path: string) {
    if (this.fake) {
      console.log('something wrong');
      return;
    }

    const page_view_log = getPageviewLog(path);
    if (!page_view_log) {
      return;
    }
    isArray(page_view_log) ? page_view_log.forEach((log) => this.addLog(log)) : this.addLog(page_view_log);
  }

  addLog(base_log: Log) {
    const log = {
      ...base_log,
      client_access_time: Date.now(),
    };

    if (this.in_app) {
      subl(log);
      return;
    }

    this.logs.push(log);

    // 로그가 어느 정도 쌓인 경우 바로 보내고, 아니면 조금 모아서 보낸다
    if (this.timer_id) {
      clearTimeout(this.timer_id);
    }
    const wait = this.logs.length > 10 ? 1 : 5;
    this.timer_id = setTimeout(() => {
      this.send(false);
    }, wait * 1000);
  }

  send(last: boolean) {
    if (this.fake) {
      console.log('something wrong');
      return;
    }
    if (this.timer_id) {
      clearTimeout(this.timer_id);
    }
    this.timer_id = undefined;
    if (!last && this.logs.length === 0) {
      return;
    }
    const data: any = {
      last,
      logs: this.filterLog(),
    };
    this.logs = [];
    this._send(data);
  }

  filterLog() {
    // impression 중복 여부 체크 필터링
    const filtered = this.logs.reduce((acc: Log[], cur: Log) => {
      const is_reduplication = acc.some(
        (log) =>
          cur.category === 'impression' &&
          log.object_id === cur.object_id &&
          log.object_section === cur.object_section &&
          cur.object_type === log.object_type,
      );
      if (!is_reduplication) {
        acc.push(cur);
      }
      return acc;
    }, []);
    return filtered;
  }

  async _send(data: any) {
    if (!isNull(navigator.sendBeacon)) {
      try {
        const blob = new Blob([JSON.stringify(data)]);
        const ab = await blob.arrayBuffer();
        const dv = new DataView(ab);
        for (let i = 0; i < ab.byteLength; i++) {
          const byte = dv.getUint8(i);
          if (byte >= 128) {
            dv.setUint8(i, byte - 128);
          } else {
            dv.setUint8(i, byte + 128);
          }
        }

        navigator.sendBeacon(
          config.apiConsumerBaseUrl +
            `/tr?fake=${this.fake}&ua=${this.user_agent}&na=${navigator.userAgent}&device=${this.device}&ia=${this.in_app}&last=${data.last}&ll=${data.logs?.length}`,
          ab,
        );
      } catch (error) {
        // ignore error
      }
    }
  }

  login() {
    logAirbridge('airbridge.user.signin');
  }

  completeSearch(keyword: string) {
    logBraze('search_keyword', { keywrd: keyword });
    logAirbridge('airbridge.ecommerce.searchResults.viewed', { semanticAttributes: { query: keyword } });
  }

  likeProduct(
    shop: { id: string; name: string },
    product: { id: string; name: string; price: number; product_category_lv1?: { id?: number; name?: string } },
  ) {
    const facebook_data = {
      content_id: product.id,
      content_name: product.name,
      value: product.price,
      currency: 'KRW',
    };

    logFirebase('add_to_cart', { catalog_id: product.id });
    logFacebook('AddToWishlist', {
      content_id: product.id,
      content_name: product.name,
      value: product.price,
      currency: 'KRW',
    });
    logBraze('save_product', {
      shop_name: shop.name,
      product_name: product.name,
      price: product.price,
      product_id: Number(product.id),
    });
    logAirbridge('airbridge.addToWishlist', {
      semanticAttributes: {
        products: [
          {
            productID: product.id,
            name: product.name,
            categoryID: product.product_category_lv1?.id ?? null,
            categoryName: product.product_category_lv1?.name ?? null,
            brandID: shop.id,
            brandName: shop.name,
            price: product.price,
          },
        ],
      },
    });
    window.gtag?.('event', 'add_to_cart', {
      catalog_id: product.id,
    });
    window.fbq?.('track', 'AddToWishlist', facebook_data);
  }

  likeStore(shop: { id: string; name: string; domain: string }) {
    logFirebase('generate_lead', { shop_id: shop.id });
    logFacebook('Lead', { shop_id: shop.id });
    logBraze('save_shop', {
      shop_name: shop.name,
      shop_domain_name: shop.domain,
      shop_id: Number(shop.id),
    });
    logAirbridge('add_bookmark', { semanticAttributes: { products: [{ brandID: shop.id, brandName: shop.name }] } });
    window.gtag?.('event', 'generate_lead', {
      shop_id: shop.id,
    });
    window.fbq?.('track', 'Lead', {
      shop_id: shop.id,
    });
  }

  viewProductDetailPage(
    catalog_id: string,
    product: {
      id: string;
      name: string;
      final_price: number;
      product_category_lv1?: { id?: number; name?: string };
    },
    shop: { id: string; name: string; main_domain: string },
  ) {
    const facebook_data = {
      content_id: catalog_id,
      content_name: product.name,
      value: product.final_price,
      currency: 'KRW',
    };

    logFirebase('view_item', { catalog_id });
    logBraze('view_product', {
      shop_name: shop.name,
      shop_domain_name: shop.main_domain,
      product_name: product.name,
      price: product.final_price,
      goods_search_category_lv1: product.product_category_lv1?.id ?? null,
      product_id: Number(product.id),
      shop_id: Number(shop.id),
    });
    logFacebook('ViewContent', facebook_data);
    logAirbridge('airbridge.ecommerce.product.viewed', {
      action: product.product_category_lv1?.name ?? '',
      semanticAttributes: {
        products: [
          {
            productID: catalog_id,
            name: product.name,
            categoryID: product.product_category_lv1?.id ?? null,
            categoryName: product.product_category_lv1?.name ?? null,
            brandID: shop.id,
            brandName: shop.name,
            price: product.final_price,
          },
        ],
      },
    });
    window.gtag?.('event', 'view_item', {
      catalog_id,
    });
    window.fbq?.('track', 'ViewContent', facebook_data);
  }

  purchase(value: number, order_number: string) {
    const params = {
      value,
      order_number,
      currency: 'KRW',
    };
    logFirebase('Purchase', params);
    window.gtag?.('event', 'Purchase', params);
  }

  purchaseByFacebook(facebook_data: FacebookParamsPurchase) {
    logFacebook('Purchase', facebook_data);
    window.fbq?.('track', 'Purchase', facebook_data);
  }

  purchaseByOrderApp(braze_data: Record<string, any>) {
    logBraze('purchase_by_order_app', braze_data);
  }

  purchaseByAirbridge(semantic_data: AirbridgeOrderParams, is_fist_purchase: boolean) {
    // 첫구매
    if (is_fist_purchase) {
      logAirbridge('fist_purchase', {
        action: semantic_data.products[0].categoryName ?? '',
        semanticAttributes: semantic_data,
      });
    } else {
      logAirbridge('airbridge.ecommerce.order.completed', {
        action: semantic_data.products[0].categoryName ?? '',
        semanticAttributes: semantic_data,
      });
    }
  }

  orderCanceled(semantic_data: AirbridgeOrderParams) {
    logAirbridge('airbridge.ecommerce.order.canceled', {
      action: semantic_data.products[0].categoryName ?? '',
      semanticAttributes: semantic_data,
    });
  }

  completeRegistration(braze_data: Record<string, any>) {
    logFirebase('sign_up', {});
    logFacebook('CompleteRegistration', {});
    logBraze('signup', braze_data);
    logAirbridge('airbridge.user.signup');
    window.gtag?.('event', 'sign_up', {});
    window.fbq?.('track', 'CompleteRegistration');
  }

  addToCart(
    product: {
      id: string;
      name: string;
      final_price: number;
      product_category_lv1?: { id?: number; name?: string };
      quantity: number;
    },
    shop: { id: string; name: string; main_domain: string },
  ) {
    const facebook_data = {
      content_id: product.id,
      content_name: product.name,
      value: product.final_price,
      currency: 'KRW',
    };

    logFirebase('add_to_cart', {});
    logBraze('add_to_cart', {
      shop_id: shop.id,
      shop_name: shop.name,
      shop_domain_name: shop.main_domain,
      product_name: product.name,
      price: product.final_price,
      goods_search_category_lv1: product.product_category_lv1?.id ?? null,
      product_id: Number(product.id),
    });
    logFacebook('AddToCart', {
      content_id: product.id,
      content_name: product.name,
      value: product.final_price,
      currency: 'KRW',
    });
    logAirbridge('airbridge.ecommerce.product.addedToCart', {
      action: product.product_category_lv1?.name ?? '',
      semanticAttributes: {
        products: [
          {
            productID: product.id,
            name: product.name,
            categoryID: product.product_category_lv1?.id ?? null,
            categoryName: product.product_category_lv1?.name ?? null,
            brandID: shop.id,
            brandName: shop.name,
            price: product.final_price,
            quantity: product.quantity,
          },
        ],
      },
    });
    window.gtag?.('event', 'add_to_cart', {});
    window.fbq?.('track', 'AddToCart', facebook_data);
  }

  homeBanner(braze_data: Record<string, string>) {
    logBraze('home_banner', braze_data);
  }

  homeBestTab(braze_data: Record<string, string>) {
    logBraze('home_best', braze_data);
  }

  homeSaleTab(braze_data: Record<string, string>) {
    logBraze('home_sale', braze_data);
  }

  homeTrend(braze_data: Record<string, string>) {
    logBraze('home_trend', braze_data);
    logAirbridge('airbridge.ecommerce.home.viewed');
  }

  homeKids(braze_data: Record<string, string>) {
    logBraze('home_kids', braze_data);
  }

  attendanceParticipate() {
    logBraze('attendance_participate', {});
  }
}

const TrackerContext = createContext<Tracker>(new Tracker(true, ''));
TrackerContext.displayName = 'TrackerContext';

export function useTracker() {
  return useContext(TrackerContext);
}

interface Props extends PropsWithChildren {
  user_agent: string;
}

export const TrackerProvider: FC<Props> = (props) => {
  const [tracker] = useState(() => new Tracker(typeof navigator === 'undefined' ? true : false, props.user_agent));

  useEffect(() => {
    tracker.trackPageview(DefaultRouter.asPath);
    const handleRouteChange = (url: string, _: { shallow: boolean }) => {
      tracker.trackPageview(url);
    };
    DefaultRouter.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      DefaultRouter.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [tracker]);

  return <TrackerContext.Provider value={tracker}>{props.children}</TrackerContext.Provider>;
};
