import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray_secondary } from 'styles';

const Align16: FC<IconProps> = ({ width = 16, height = 16, color = gray_secondary, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 16 16' fill='none' {...props}>
    <path d='M5.92715 13.08V3L2.57001 6.08008' stroke={color} strokeWidth='1.4' strokeLinejoin='round' />
    <path d='M10.07 2.92L10.07 13L13.4272 9.91992' stroke={color} strokeWidth='1.4' strokeLinejoin='round' />
  </svg>
);
export default Align16;
