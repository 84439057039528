import styled from '@emotion/styled';
import React, { forwardRef, ReactNode, PropsWithChildren } from 'react';
import { IconArrowRight16 } from 'components/base/Icon';
import { gray900, pink600, white } from 'styles';
import { typography } from 'styles/font';

export interface ToastBarProps extends PropsWithChildren {
  message?: ReactNode;
  bottom?: number;
  onConfirm?: () => void;
}

const ToastBar = forwardRef<HTMLDivElement, ToastBarProps>(({ message, bottom = 52, onConfirm, children }, ref) => {
  return (
    <Container ref={ref} bottom={bottom}>
      {onConfirm ? (
        <Wrapper use_confirm>
          {message ?? children}
          <Confirm onClick={onConfirm}>
            <span>확인하기</span>
            <IconArrowRight16 color={pink600} />
          </Confirm>
        </Wrapper>
      ) : (
        <Wrapper>{message ?? children}</Wrapper>
      )}
    </Container>
  );
});
export default ToastBar;

const Container = styled.div<{ bottom: number }>`
  position: fixed;
  bottom: ${({ bottom }) => bottom}px;
  left: 0;
  right: 0;
  padding: 0 20px;
  padding-bottom: calc(env(safe-area-inset-bottom));
  padding-bottom: calc(constant(safe-area-inset-bottom));
  z-index: 2100;
`;

const Wrapper = styled.div<{ use_confirm?: boolean }>`
  ${typography.button}
  background: ${gray900};
  color: ${white};
  text-align: center;
  padding: 15px 16px;
  border-radius: 4px;
  white-space: pre-wrap;
  max-width: 560px;
  margin: 0 auto;
  display: flex;
  justify-content: ${({ use_confirm }) => (use_confirm ? 'space-between' : 'center')};
`;

const Confirm = styled.div`
  display: flex;
  align-items: center;
  ${typography.small1}
  color: ${pink600};
  cursor: pointer;

  > span {
    margin-right: 4px;
  }
`;
