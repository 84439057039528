export interface IconProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
  sub_color?: string;
}

/**
 * 아이콘 작성시
 * 1. 컴포넌트 명은 아이콘이름+ 아이콘 사이즈 의 구성으로 작성 합니다. ex.) Ok20.tsx
 * 2. index.tsx 에서 export 시 Icon prefix를 추가해 작성 합니다.
 */

export { default as IconStop8 } from './Stop8';
export { default as IconPlay8 } from './Play8';

export { default as IconNewBadge14 } from './NewBadge14';

export { default as IconLineArrowRight15x20 } from './LineArrowRight15x20';
export { default as IconZigzinText51x14 } from './ZigzinText51x14';
export { default as IconFBKLogo108x23 } from './FBKLogo108x23';

export { default as IconCheck16 } from './Check16';
export { default as IconAlign16 } from './Align16';
export { default as IconArrowDown16 } from './ArrowDown16';
export { default as IconArrowRight16 } from './ArrowRight16';
export { default as IconStar16 } from './Star16';
export { default as IconDownload16 } from './Download16';
export { default as IconCancel16 } from './Cancel16';
export { default as IconRefresh16 } from './Refresh16';
export { default as IconSearch16 } from './Search16';
export { default as IconCheckCircle16 } from './CheckCircle16';
export { default as IconCheckCircleEmpty16 } from './CheckCircleEmpty16';
export { default as IconInputCancel16 } from './InputCancel16';
export { default as IconThumbsUp16 } from './ThumbsUp16';
export { default as IconThumbsDown16 } from './ThumbsDown16';
export { default as IconExclamation16 } from './Exclamation16';
export { default as IconInfo16 } from './Info16';
export { default as IconEyes16 } from './Eyes16';
export { default as IconEyesOff16 } from './EyesOff16';
export { default as IconQuestionMark16 } from './QuestionMark16';
export { default as IconMinus16 } from './Minus16';
export { default as IconShare16 } from './Share16';

export { default as IconShare20 } from './Share20';
export { default as IconCancel20 } from './Cancel20';
export { default as IconPointCircle20 } from './PointCircle20';
export { default as IconCheckCircle20 } from './CheckCircle20';
export { default as IconCheckCircleEmpty20 } from './CheckCircleEmpty20';
export { default as IconRadioCircle20 } from './RadioCircle20';
export { default as IconRadioEmptyCircle20 } from './RadioEmptyCircle20';
export { default as IconZigShipping20 } from './ZigShipping20';
export { default as IconRoundCancel20 } from './RoundCancel20';
export { default as IconCamera20 } from './Camera20';
export { default as IconArrowRight20 } from './ArrowRight20';
export { default as IconArrowDown20 } from './ArrowDown20';
export { default as IconShipping20 } from './Shipping20';
export { default as IconCreditCard20 } from './CreditCard20';
export { default as IconPoint20 } from './Point20';
export { default as IconFastShipping20 } from './FastShipping20';
export { default as IconNotice20 } from './Notice20';
export { default as IconSchedule20 } from './Schedule20';
export { default as IconScheduleFill20 } from './ScheduleFill20';
export { default as IconAirplane20 } from './Airplane20';
export { default as IconWerench20 } from './Werench20';
export { default as IconCoupon20 } from './Coupon20';
export { default as IconKRWCoin20 } from './KRWCoin20';

export { default as IconAlarm24 } from './Alarm24';
export { default as IconArrive24 } from './Arrive24';
export { default as IconArrowLeft24 } from './ArrowLeft24';
export { default as IconArrowRight24 } from './ArrowRight24';
export { default as IconBottomHomeN24 } from './BottomHomeN24';
export { default as IconBottomHomeP24 } from './BottomHomeP24';
export { default as IconBottomStyleN24 } from './BottomStyleN24';
export { default as IconBottomStyleP24 } from './BottomStyleP24';
export { default as IconBottomLikeN24 } from './BottomLikeN24';
export { default as IconBottomLikeP24 } from './BottomLikeP24';
export { default as IconBottomMypageN24 } from './BottomMypageN24';
export { default as IconBottomMypageP24 } from './BottomMypageP24';
export { default as IconBottomCategoryN24 } from './BottomCategoryN24';
export { default as IconBottomCategoryP24 } from './BottomCategoryP24';
export { default as IconClose24 } from './Close24';
export { default as IconCourier24 } from './Courier24';
export { default as IconNavigationBack24 } from './NavigationBack24';
export { default as IconHeartFill24 } from './HeartFill24';
export { default as IconHeartShadow24 } from './HeartShadow24';
export { default as IconHeartUnFill24 } from './HeartUnFill24';
export { default as IconSend24 } from './Send24';
export { default as IconShipping24 } from './Shipping24';
export { default as IconSetting24 } from './Setting24';
export { default as IconNavigationBasket24 } from './NavigationBasket24';
export { default as IconNavigationSearch24 } from './NavigationSearch24';
export { default as IconNavigationHome24 } from './NavigationHome24';
export { default as IconCamera24 } from './Camera24';
export { default as IconCircleLoading24 } from './CircleLoading24';
export { default as IconBottomStoreN24 } from './BottomStoreN24';
export { default as IconBottomStoreP24 } from './BottomStoreP24';

export { default as IconKakaoTalkSymbol28 } from './KakaoTalkSymbol28';

export { default as IconShareFacebook32 } from './ShareFacebook32';
export { default as IconShareUrl32 } from './ShareUrl32';
export { default as IconShareKakaoTalk32 } from './ShareKakaoTalk32';
export { default as IconShareBand32 } from './ShareBand32';
export { default as IconShareMore32 } from './ShareMore32';
export { default as IconReview32 } from './Review32';
export { default as IconCoupon32 } from './Coupon32';
export { default as IconPoint32 } from './Point32';
export { default as IconStar32 } from './Star32';
export { default as IconCircleArrowRight32 } from './CircleArrowRight32';

export { default as IconPhone40 } from './Phone40';
export { default as IconKakaoPay40 } from './KakaoPay40';
export { default as IconCard40 } from './Card40';
export { default as IconBank40 } from './Bank40';

export { default as IconFbk44 } from './Fbk44';

export { default as IconPoint60 } from './Point60';
export { default as IconReview60 } from './Review60';
export { default as IconCoupon60 } from './Coupon60';
export { default as IconSearch60 } from './Search60';
export { default as IconSizeInfo60 } from './SizeInfo60';
export { default as IconCheckCircle60 } from './CheckCircle60';
export { default as IconLoading60 } from './Loading60';
export { default as IconHeart60 } from './Heart60';
export { default as IconDescribe60 } from './Describe60';

export { default as IconPhoto20 } from './Photo20';
export { default as IconPlus16 } from './Plus16';
export { default as IconEnlargement36 } from './Enlargement36';
