export function mergeCookie(cookie_str: string | undefined, set_cookie_list: string[]) {
  let cookies = cookie_str ? cookie_str.split('; ') : [];
  for (const set_cookie of set_cookie_list) {
    const name_value = set_cookie.split(';')[0];
    const name = name_value.split('=')[0] + '=';
    cookies = [...cookies.filter((cookie) => !cookie.startsWith(name)), name_value];
  }
  return cookies.length > 0 ? cookies.join('; ') : undefined;
}

export function getCookies(cookie_str: string) {
  const cookies: Record<string, string> = {};
  cookie_str.split('; ').forEach((cookie) => {
    const [key, value] = cookie.split('=');
    cookies[key] = value;
  });
  return cookies;
}

export function getDeviceUuidFromCookie(cookie_str: string) {
  const uuid_cookie = getCookies(cookie_str).ZIGZAGUUID || '';
  const [device_uuid] = uuid_cookie.split('.');
  return device_uuid;
}
