import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const CheckCircle16: FC<IconProps> = ({
  width = 16,
  height = 16,
  color = colors.gray900,
  sub_color = colors.white,
  ...props
}) => (
  <svg width={width} height={height} color={color} viewBox='0 0 16 16' {...props}>
    <g clipPath='url(#clip0_2270_125649)'>
      <circle cx='8' cy='8' r='8' fill='currentColor' />
      <g clipPath='url(#clip1_2270_125649)'>
        <path d='M7.02 11.53L3.5 7.43L4.56 6.52L7.05 9.41L11.51 4.5L12.55 5.44L7.02 11.53Z' fill={sub_color} />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_2270_125649'>
        <rect width='16' height='16' rx='8' fill={sub_color} />
      </clipPath>
      <clipPath id='clip1_2270_125649'>
        <rect width='9.05' height='7.03' fill={sub_color} transform='translate(3.5 4.5)' />
      </clipPath>
    </defs>
  </svg>
);
export default CheckCircle16;
