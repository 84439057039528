import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const CheckCircleEmpty16: FC<IconProps> = ({
  width = 16,
  height = 16,
  color = colors.gray900,
  sub_color = colors.white,
  ...props
}) => (
  <svg width={width} height={height} color={color} fill='none' viewBox='0 0 16 16' {...props}>
    <g clipPath='url(#clip0_2017_2113)'>
      <circle cx='8' cy='8' r='7.5' stroke={color} />
    </g>
    <defs>
      <clipPath id='clip0_2017_2113'>
        <rect width='16' height='16' rx='8' fill={sub_color} />
      </clipPath>
    </defs>
  </svg>
);
export default CheckCircleEmpty16;
