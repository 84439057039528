import { numberInTenThousand } from './number';

export enum SHIPPING_TYPE {
  FREE = '무료배송', // 100
  ZIGIN = '직진배송', // 101
}

export function getDeliveryFilterCode(type: SHIPPING_TYPE) {
  return {
    [SHIPPING_TYPE.FREE]: 100,
    [SHIPPING_TYPE.ZIGIN]: 101,
  }[type];
}

export function getPriceKo(min: number, max: number) {
  const is_all_price = min === -1 && max === -1;

  if (is_all_price) {
    return '모든가격';
  }

  if (min === 0) {
    return `${numberInTenThousand(max)}원 이하`;
  }

  const is_over_10_만원 = min === 100000 && max === 9999999;

  if (is_over_10_만원) {
    return '10만원 이상';
  }

  return `${numberInTenThousand(min)}원~${numberInTenThousand(max)}원`;
}

export enum SORT_TYPE {
  SCORE_DESC = 'SCORE_DESC',
  REVIEW_COUNT_DESC = 'REVIEW_COUNT_DESC',
  LATEST = 'LATEST',
  PRICE_ASC = 'PRICE_ASC',
  PRICE_DESC = 'PRICE_DESC',
  RECOMMEND = 'RECOMMEND',
  NEW = 'NEW',
}
// 기본 정렬 타입
export const DEFAULT_SORT_TYPE = SORT_TYPE.RECOMMEND;

export function getSortId(type: SORT_TYPE) {
  return {
    [SORT_TYPE.SCORE_DESC]: 200,
    [SORT_TYPE.REVIEW_COUNT_DESC]: 201,
    [SORT_TYPE.LATEST]: 202,
    [SORT_TYPE.PRICE_ASC]: 203,
    [SORT_TYPE.PRICE_DESC]: 204,
    [SORT_TYPE.RECOMMEND]: 205,
    [SORT_TYPE.NEW]: 108,
  }[type];
}

export function getSortTypeById(id?: number) {
  if (!id) {
    return DEFAULT_SORT_TYPE;
  }

  return (
    {
      200: SORT_TYPE.SCORE_DESC,
      201: SORT_TYPE.REVIEW_COUNT_DESC,
      202: SORT_TYPE.LATEST,
      203: SORT_TYPE.PRICE_ASC,
      204: SORT_TYPE.PRICE_DESC,
      205: SORT_TYPE.RECOMMEND,
    }[id] ?? SORT_TYPE.SCORE_DESC
  );
}

export interface SortTypeItem {
  type: SORT_TYPE;
  text: string;
}

export const SORT_TYPE_LIST: SortTypeItem[] = [
  {
    type: SORT_TYPE.RECOMMEND,
    text: '추천순',
  },
  {
    type: SORT_TYPE.SCORE_DESC,
    text: '인기순',
  },
  {
    type: SORT_TYPE.LATEST,
    text: '신상품순',
  },
  {
    type: SORT_TYPE.PRICE_ASC,
    text: '가격 낮은순',
  },
  {
    type: SORT_TYPE.PRICE_DESC,
    text: '가격 높은순',
  },
  {
    type: SORT_TYPE.REVIEW_COUNT_DESC,
    text: '리뷰 많은순',
  },
];

export interface CategoryFilterItem {
  id: string;
  name: string;
  depth: number;
}

export interface SearchFilter {
  color_list?: string[];
  price?: {
    min: number;
    max: number;
  };
  category_list?: CategoryFilterItem[];
  category_id_list?: string[];
  sort_type?: SORT_TYPE;
  is_free_shipping?: boolean;
  is_zigin_shipping?: boolean;
  is_fast_shipping?: boolean;
  keyword?: string;
}

export function getFilterIdList({ is_free_shipping, is_zigin_shipping, sort_type, is_fast_shipping }: SearchFilter) {
  const default_sort_id = getSortId(DEFAULT_SORT_TYPE);
  const result: string[] = [];

  result.push(String(sort_type ? getSortId(sort_type) : default_sort_id));

  if (is_free_shipping) {
    result.push('100');
  }

  if (is_zigin_shipping) {
    result.push('101');
  }

  if (is_fast_shipping) {
    result.push('108');
  }

  return result;
}

export interface SearchFilterUbl {
  filter_option: {
    color?: string[];
    price?: {
      min: number;
      max: number;
    };
    shipping?: string[];
  };
  sort_type?: SORT_TYPE;
}

export function getSearchFilterUbl(search_filter: SearchFilter): SearchFilterUbl {
  const { color_list, price, is_free_shipping, is_fast_shipping, is_zigin_shipping, sort_type, category_list } =
    search_filter;
  const shipping: string[] = [];
  if (is_free_shipping) {
    shipping.push('free_shipping');
  }

  if (is_zigin_shipping) {
    shipping.push('z_shipping');
  }

  if (is_fast_shipping) {
    shipping.push('fast_shipping');
  }

  const filter_option = {
    color: color_list,
    category: category_list?.map((category) => {
      const category_li_split_depth = category.id.split(';');
      const current_depth_category_id = category_li_split_depth[category_li_split_depth.length - 1];
      return {
        ...category,
        id: current_depth_category_id,
      };
    }),
    price,
    // MEMO: 전체 선택 안되어도 all_shipping 으로 간주
    shipping: shipping.length === 0 ? ['all_shipping'] : shipping,
  };

  return {
    filter_option,
    sort_type,
  };
}

export const appendCategorySubQuery = (display_category_id_list?: string[]) => {
  // 2차 카테고리까지 적용
  if (display_category_id_list) {
    if (display_category_id_list.length === 2) {
      return `/categories/${display_category_id_list[0]}?sub_category_id=${display_category_id_list[1]}`;
    }
    return `/categories/${display_category_id_list[0]}`;
  }
  return '/';
};
