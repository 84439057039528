import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray_secondary } from 'styles';

const ThumbsUp16: FC<IconProps> = ({ width = 16, height = 16, color = gray_secondary, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 16 16' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.85568 2.59838C9.96236 2.48227 10.1364 2.45805 10.2707 2.54062C10.4025 2.62165 10.4602 2.78353 10.4092 2.92965L9.25333 6.24509L8.92894 7.17554H9.91431H12.9604C13.2192 7.17554 13.4228 7.39641 13.4018 7.6543L13.2299 9.76749C13.0906 11.4806 11.6596 12.7999 9.94078 12.7999H6.04288V6.74828L9.85568 2.59838ZM11.0038 1.3479C10.2985 0.91439 9.38486 1.04155 8.82474 1.65119L5.035 5.776H2.2H1.5V6.476V13.5004V14.2004H2.2H5.34286H6.04286V14.1999H9.94078C12.3888 14.1999 14.4268 12.3209 14.6253 9.88101L14.7972 7.76781C14.8845 6.69466 14.0371 5.77554 12.9604 5.77554H10.8997L11.7312 3.39054C11.9987 2.62332 11.696 1.77335 11.0038 1.3479ZM2.9 12.8004V7.176H4.64286V12.8004H2.9Z'
      fill={color}
    />
  </svg>
);
export default ThumbsUp16;
