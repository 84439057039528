import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import lottie from 'lottie-web';
import React, { ButtonHTMLAttributes, ReactNode, useRef, useEffect } from 'react';
import { colors } from 'styles';
import { typography } from 'styles/font';

export type ButtonSize = 'small' | 'medium' | 'large';

export type ButtonVariant = 'primary' | 'tertiary_color' | 'tertiary_gray' | 'secondary_gray';

export const getButtonVariant = (variant: ButtonVariant = 'primary') => {
  switch (variant) {
    case 'primary':
      return css`
        color: ${colors.white};
        background-color: ${colors.pink600};
        border: 1px solid ${colors.pink600};

        &:disabled {
          background: ${colors.pink_disabled};
          border: 1px solid ${colors.pink150};
        }
      `;
    case 'tertiary_color':
      return css`
        color: ${colors.pink600};
        background-color: ${colors.white};
        border: 1px solid ${colors.pink600};

        &:disabled {
          color: ${colors.pink200};
          border: 1px solid ${colors.pink200};
          background-color: ${colors.white};
        }
      `;
    case 'tertiary_gray':
      return css`
        color: ${colors.gray_active};
        background-color: ${colors.white};
        border: 1px solid ${colors.border2};

        &:disabled {
          color: ${colors.gray300};
        }
      `;
    case 'secondary_gray':
      return css`
        color: ${colors.gray_secondary};
        background-color: ${colors.bg_secondary};
        border: 1px solid ${colors.bg_secondary};

        &:enabled {
          &:hover {
            background-color: ${colors.gray100};
          }
        }

        &:disabled {
          color: ${colors.gray300};
        }
      `;
  }
};

export const button_size_css: Record<ButtonSize, SerializedStyles> = {
  small: css`
    min-width: 63px;
    height: 36px;
    padding: 0 12px;
    ${typography.body4_medium};
  `,
  medium: css`
    min-width: 58px;
    height: 42px;
    padding: 0 16px;
    ${typography.title5_medium};
  `,
  large: css`
    min-width: 47px;
    height: 52px;
    padding: 0 24px;
    ${typography.title4_medium};
  `,
};

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * 전체 영역 버튼 유무를 정의합니다.
   * @default false
   */
  fill?: boolean;
  /**
   * 버튼 variant 를 선택합니다.
   * @default primary
   */
  variant?: ButtonVariant;
  /**
   * 버튼 크기를 정의합니다.
   * @default medium
   */
  size?: ButtonSize;
  /**
   * 버튼 텍스트 좌측의 아이콘을 넣을 수 있습니다.
   */
  start_icon?: ReactNode;
  /**
   * 버튼 텍스트 우측의 아이콘을 넣을 수 있습니다.
   */
  end_icon?: ReactNode;
  /**
   * 버튼에서 로딩 프로그레스 바를 보여줍니다.
   * @default false
   */
  loading?: boolean;
  /**
   * 버튼 비활성화 유무를 정의합니다.
   * @default false
   */
  disabled?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = 'medium',
      variant = 'primary',
      fill,
      disabled,
      loading,
      className,
      start_icon,
      end_icon,
      children,
      ...props
    },
    ref,
  ) => {
    const loading_ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (loading_ref.current && loading) {
        lottie.loadAnimation({
          container: loading_ref.current,
          autoplay: true,
          loop: true,
          animationData: require('./loading_lottie.json'),
        });
      }
    }, [loading]);

    const button_style = [
      css`
        ${fill && 'width: 100%;'}
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
      `,
      getButtonVariant(variant),
      button_size_css[size],
    ];

    return (
      <BaseButton className={className} css={button_style} disabled={disabled} {...props} ref={ref}>
        {!loading && (
          <>
            {start_icon}
            {children}
            {end_icon}
          </>
        )}
        {loading && (
          <>
            <Text>
              {start_icon}
              {children}
              {end_icon}
            </Text>
            <Loading>
              <div ref={loading_ref} />
            </Loading>
          </>
        )}
      </BaseButton>
    );
  },
);

const Text = styled.div`
  visibility: hidden;
  > svg {
    margin: 0 4px;
  }
`;

const BaseButton = styled.button<{ is_loading?: boolean }>`
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;

  > svg {
    margin: 0 4px;
  }

  &:focus {
    outline: 0;
  }

  &:enabled {
    &:active {
      opacity: 0.7;
    }
  }
`;

const Loading = styled.div`
  position: absolute;
  visibility: visible;
  display: flex;
  left: 50%;
  transform: translate(-50%);
  width: 40px;
  margin-bottom: 10px;
`;
