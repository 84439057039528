import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { pink_primary } from 'styles';

const Heart60: FC<IconProps> = ({ width = 60, height = 60, color = pink_primary, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 60 60' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M51.4856 17.743C50.6573 15.96 49.4376 14.4221 47.9324 13.2631C46.4272 12.1041 44.6823 11.3591 42.8492 11.0928C40.3607 10.7677 37.8407 11.3015 35.6512 12.6174C33.8227 13.7082 32.4697 15.3839 31.0008 16.9676C29.532 15.3839 28.1911 13.6819 26.3504 12.6174C24.161 11.3015 21.6409 10.7677 19.1524 11.0928C17.3194 11.3591 15.5744 12.1041 14.0692 13.2631C12.5641 14.4221 11.3443 15.96 10.516 17.743C8.35232 22.5926 9.85775 28.0008 13.021 31.8385C17.9761 37.7987 19.318 38.5764 31 49C42.7056 38.5764 43.9768 37.8249 48.9319 31.8385C52.1439 28.0008 53.6493 22.5926 51.4856 17.743Z'
        stroke={color}
        strokeWidth='2'
      />
    </svg>
  );
};

export default Heart60;
