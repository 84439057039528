import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { gray500 } from 'styles';

const CreditCard20: FC<IconProps> = ({ width = 20, height = 20, color = gray500, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' {...props}>
      <rect x='1.7' y='4.36675' width='16.6' height='10.6' rx='1.96667' fill='white' stroke={color} strokeWidth='1.4' />
      <rect x='3.70312' y='8.16675' width='3.6' height='2.7' rx='0.666667' fill={color} />
    </svg>
  );
};

export default CreditCard20;
