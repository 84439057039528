import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray300, white } from 'styles';

const CheckCircleEmpty20: FC<IconProps> = ({
  width = 20,
  height = 20,
  color = white,
  sub_color = gray300,
  ...props
}) => (
  <svg width={width} height={height} viewBox='0 0 20 20' fill='none' {...props}>
    <g clipPath='url(#clip0_4285_46795)'>
      <circle cx='10' cy='10.5' r='9.5' fill={color} stroke={sub_color} />
    </g>
    <defs>
      <clipPath id='clip0_4285_46795'>
        <rect y='0.5' width={width} height={height} rx='10' fill={color} />
      </clipPath>
    </defs>
  </svg>
);
export default CheckCircleEmpty20;
