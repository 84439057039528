import { FC } from 'react';
import { gray600 } from '../../../styles';
import { IconProps } from './index';

const Minus16: FC<IconProps> = ({ width = 16, height = 16, color = gray600, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' {...props}>
      <path d='M2 8H14' stroke={color} strokeWidth='1.4' />
    </svg>
  );
};

export default Minus16;
