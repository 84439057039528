import { IncomingMessage } from 'http';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getUserInfo, getUserAvailablePoint, getReviewableOrderItemList, getUserCouponList } from 'api2';
import { GetUserInfo, UserAccountCouponStatus } from 'api2/types';
import { updateProfile } from 'util/app_utils';

type UserAccountInfo = NonNullable<GetUserInfo['user_account']>;

export interface UserInfo extends UserAccountInfo {
  point: number;
  coupon_count: number;
  review_count: number;
  max_review_point: number;
  has_user_account_order: boolean;
}

export const USER_INFO_QUERY_KEY = ['getUserInfo'];

export const fetchUserInfo = async (req?: IncomingMessage): Promise<UserInfo | null> => {
  const context_request = req ? { context: req, show_alert: false } : { show_alert: false };

  try {
    const {
      data: { user_account, has_user_account_order },
    } = await getUserInfo(undefined, context_request);
    // app 프로파일 전송
    updateProfile(
      user_account ? { uuid: user_account.uuid, email: user_account.email, name: user_account.full_name } : null,
    );

    if (!user_account) {
      return null;
    }

    // 포인트의 경우 비로그인 상태에서 호출시 api 로그인 에러를 응답해서 자동으로 로그인 페이지 이동이 되기때문에 로그인 확인 후 호출해준다.
    const [
      {
        data: { user_account_coupon_list },
      },
      {
        data: { user_account_available_point },
      },
      {
        data: { reviewable_order_item_list },
      },
    ] = await Promise.all([
      getUserCouponList({ status_list: [UserAccountCouponStatus.ISSUED] }, context_request),
      getUserAvailablePoint(undefined, context_request),
      getReviewableOrderItemList(undefined, context_request),
    ]);

    const max_review_point = reviewable_order_item_list.item_list.reduce((acc, cur) => {
      return (acc += cur.product_review_create_info.point_amounts.normal.with_photo);
    }, 0);

    return {
      ...user_account,
      point: user_account_available_point,
      coupon_count: user_account_coupon_list.total_count,
      review_count: reviewable_order_item_list.total_count,
      max_review_point,
      has_user_account_order,
    };
  } catch {
    return null;
  }
};

export const useUserInfo = () => {
  const query_client = useQueryClient();

  const resetInfo = () => {
    query_client.setQueryData<UserInfo | undefined>(USER_INFO_QUERY_KEY, undefined);
  };

  const setSmsPush = (sms_reception_agreed: boolean) => {
    query_client.setQueryData<UserInfo | undefined>(USER_INFO_QUERY_KEY, (prev) => {
      if (prev) {
        prev.sms_reception_agreed = sms_reception_agreed;
      }
      return prev;
    });
  };

  const setEmailPush = (email_reception_agreed: boolean) => {
    query_client.setQueryData<UserInfo | undefined>(USER_INFO_QUERY_KEY, (prev) => {
      if (prev) {
        prev.email_reception_agreed = email_reception_agreed;
      }
      return prev;
    });
  };

  const setNightlyPush = (nightly_ad_noti_agreed: boolean) => {
    query_client.setQueryData<UserInfo | undefined>(USER_INFO_QUERY_KEY, (prev) => {
      if (prev) {
        prev.nightly_ad_noti_agreed = nightly_ad_noti_agreed;
      }
      return prev;
    });
  };

  const {
    data,
    isInitialLoading: is_loading,
    ...rest
  } = useQuery<UserInfo | null>(USER_INFO_QUERY_KEY, () => fetchUserInfo(), {
    staleTime: Infinity,
    refetchOnWindowFocus: 'always',
  });

  const is_login = !!data;

  return {
    ...rest,
    user_info: { ...data },
    is_loading,
    is_login,
    setSmsPush,
    setEmailPush,
    setNightlyPush,
    resetInfo,
  };
};
