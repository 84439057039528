import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray400 } from 'styles';

const Werench20: FC<IconProps> = ({ width = 20, height = 20, color = gray400, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 20 20' fill='none' {...props}>
    <path
      d='M3.28629 14.5437L9.06855 8.72638C8.49668 7.25608 8.67066 5.38634 9.87795 4.17174C10.9677 3.07533 12.5248 2.7311 13.908 3.09874C14.2437 3.18795 14.3208 3.60149 14.0759 3.84782L12.1981 5.73705C12.0042 5.93206 12.0042 6.247 12.1981 6.44201L13.4034 7.65463C13.5973 7.84973 13.9124 7.85154 14.1086 7.65868L16.0583 5.74175C16.3007 5.50337 16.705 5.57339 16.8003 5.89978C17.2089 7.29999 16.8226 8.88351 15.7237 9.98905C14.5165 11.2036 12.7541 11.4677 11.2927 10.8923L5.5104 16.7096C5.25623 16.9654 4.87499 16.9654 4.62082 16.7096L3.34984 15.4387C3.03213 15.183 3.03213 14.7355 3.28629 14.5437Z'
      fill={color}
    />
  </svg>
);
export default Werench20;
