import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { black } from 'styles';

const EyesOff16: FC<IconProps> = ({ width = 16, height = 16, color = black, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 16 16' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.73766 3.77624L0.983621 2.0222L2.00582 1L15.0163 14.0105L13.9941 15.0327L12.1503 13.1889C10.9412 14.0407 9.55905 14.5216 8 14.5216C5.5407 14.5216 3.52148 13.325 1.92764 11.3634C1.38069 10.6902 0.922916 9.97214 0.548761 9.25377C0.322617 8.81957 0.172689 8.47688 0.0951726 8.27017L0 8.01637L0.0951726 7.76258C0.172689 7.55587 0.322617 7.21318 0.548761 6.77898C0.922916 6.0606 1.38069 5.34253 1.92764 4.66936C2.18641 4.35087 2.4564 4.05255 2.73766 3.77624ZM3.76016 4.79874C3.51461 5.03887 3.27779 5.30009 3.04957 5.58098C2.56878 6.17272 2.16258 6.8099 1.83087 7.44678C1.72374 7.65247 1.63196 7.84386 1.55537 8.0164C1.63196 8.18894 1.72374 8.38033 1.83087 8.58602C2.16258 9.2229 2.56878 9.86008 3.04957 10.4518C4.39213 12.1042 6.0321 13.076 7.99998 13.076C9.14494 13.076 10.1789 12.747 11.1079 12.1464L9.46872 10.5073C9.03828 10.7617 8.53618 10.9076 7.99999 10.9076C6.40322 10.9076 5.10878 9.61317 5.10878 8.0164C5.10878 7.48021 5.25474 6.97811 5.50909 6.54767L3.76016 4.79874ZM6.60337 7.64195C6.57143 7.76139 6.5544 7.88692 6.5544 8.01643C6.5544 8.81482 7.20162 9.46204 8.00001 9.46204C8.12952 9.46204 8.25505 9.44501 8.37449 9.41307L6.60337 7.64195ZM5.13931 2.10963L6.25931 3.22963C6.80847 3.05063 7.38839 2.95677 7.99998 2.95677C9.96786 2.95677 11.6078 3.9286 12.9504 5.58098C13.4312 6.17272 13.8374 6.8099 14.1691 7.44678C14.2762 7.65247 14.368 7.84386 14.4446 8.0164C14.368 8.18894 14.2762 8.38033 14.1691 8.58602C13.8924 9.11725 13.5639 9.6487 13.1835 10.1538L14.2145 11.1849C14.6991 10.564 15.11 9.90899 15.4512 9.25377C15.6774 8.81957 15.8273 8.47688 15.9048 8.27017L16 8.01637L15.9048 7.76258C15.8273 7.55587 15.6774 7.21318 15.4512 6.77898C15.0771 6.0606 14.6193 5.34253 14.0724 4.66936C12.4785 2.70771 10.4593 1.51113 8 1.51113C6.96954 1.51113 6.01633 1.72121 5.13931 2.10963Z'
      fill={color}
    />
  </svg>
);
export default EyesOff16;
