import { cx } from '@emotion/css';
import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, PropsWithChildren, ReactNode, MouseEvent, useState, useRef } from 'react';
import DefaultContents, { ContentsProps } from './DefaultContents';
import Header, { HeaderProps } from './Header';
import { Button, ButtonProps } from 'components/base/Button';
import Portal from 'components/base/Portal';
import { usePreventScroll } from 'hooks';
import { WEB_MAX_WIDTH, white } from 'styles';

interface BottomDialogButtonOption extends ButtonProps {
  id?: string;
  onClick?: () => void;
}

export interface DialogOption extends HeaderProps {
  /**
   * 바텀싯의 기본 콘텐츠 내용을 정의합니다.
   * icon, title, text의 유무 및 스타일을 변경할 수 있습니다.
   */
  contents?: ContentsProps;
  /**
   * 바텀싯 하단의 버튼을 정의합니다.
   */
  buttons?: BottomDialogButtonOption[];
  /**
   * Backdrop 클릭 여부를 정의합니다.
   * @default true
   */
  disable_outside_click?: boolean;
  /**
   * 콘텐츠 영역을 full 영역으로 표시할지 정의합니다.
   * @default false
   */
  is_full_screen?: boolean;
  /**
   * window scroll을 할 수 있게 하는지 여부를 정의합니다.
   * @default false
   */
  can_scroll?: boolean;
  classname?: string;
  children?: ReactNode;
  custom_css?: SerializedStyles;
}

interface Props extends DialogOption, PropsWithChildren {
  active: boolean;
  setIsActive?: (isActive: boolean) => void;
  /**
   * 커스텀 헤더를 사용 시 디폴트 헤더를 렌더하지 않기 위해 정의합니다.
   * @default false
   */
  custom_head?: boolean;
  /**
   * 바텀시트가 animation 완료 된 시점 callback func
   */
  onAnimationComplete?: () => void;
}

/**
 * 공통 바텀싯 컴포넌트
 * @components
 */
const BottomSheet: FC<Props> = ({
  active,
  setIsActive,
  title,
  is_title_center,
  use_handlebar,
  show_close_icon,
  is_full_screen = false,
  disable_outside_click = false,
  can_scroll = false,
  custom_head = false,
  contents,
  buttons,
  children,
  custom_css,
  classname,
  onAnimationComplete,
}) => {
  const [full_screen, setFullScreen] = useState(is_full_screen);
  const [handlebar_click, setHandlebarClick] = useState<number | null>(null);
  const handlebar_ref = useRef<HTMLDivElement>(null);

  usePreventScroll(Boolean(can_scroll) === false && Boolean(active) === true);

  const handleCloseButtonClick = () => {
    setIsActive?.(false);
  };

  const handleOutsideClicked = () => {
    if (disable_outside_click === true || use_handlebar) {
      return;
    }
    handleCloseButtonClick();
  };

  // handleBar pull up 동작을 위해 click(touch) 시 포지션 값 저장
  const handleMoveHandleBar = (start_position: number) => {
    if (!handlebar_ref.current) {
      return;
    }

    const { top: header_top_position, bottom: header_bottom_position } = handlebar_ref.current.getBoundingClientRect();
    const is_set_full = !full_screen && start_position > header_top_position && start_position < header_bottom_position;
    const is_set_normal = full_screen && start_position > header_top_position;

    if (is_set_full) {
      setHandlebarClick(start_position);
      return;
    }
    if (is_set_normal) {
      setHandlebarClick(start_position);
      return;
    }

    setHandlebarClick(null);
  };

  const handleFullScreen = (end_position: number) => {
    if (!use_handlebar || !handlebar_click) {
      return;
    }

    setFullScreen(handlebar_click > end_position);
  };

  const stopPropagation: React.MouseEventHandler<HTMLDivElement> = (event) => event.stopPropagation();

  return (
    <Portal>
      <AnimatePresence>
        {active && (
          <SC.Wrapper
            animate={{ background: 'rgba(0, 0, 0, 0.4)' }}
            onClick={handleOutsideClicked}
            onMouseDown={(e: MouseEvent<HTMLDivElement>) => handleMoveHandleBar(e.clientY)}
            onMouseUp={(e: MouseEvent<HTMLDivElement>) => handleFullScreen(e.clientY)}
            onTouchStart={(e: React.TouchEvent) => handleMoveHandleBar(e.changedTouches[0].pageY)}
            onTouchEnd={(e: React.TouchEvent) => handleFullScreen(e.changedTouches[0].pageY)}
          >
            <SC.Container
              initial={{ translateY: '100%' }}
              animate={{ translateY: 0 }}
              exit={{ translateY: '100%' }}
              transition={{ type: 'tween' }}
              onAnimationComplete={onAnimationComplete}
              css={[custom_css, getScreenStyle(full_screen)]}
              onClick={stopPropagation}
              className={cx(classname, { handelebar: use_handlebar })}
            >
              {!custom_head && (
                <Header
                  use_handlebar={use_handlebar}
                  title={title}
                  is_title_center={is_title_center}
                  show_close_icon={show_close_icon}
                  onClose={handleCloseButtonClick}
                  ref={handlebar_ref}
                />
              )}
              {children ?? (
                <>
                  {contents && <DefaultContents contents={contents} />}
                  {buttons && (
                    <SC.ButtonWrapper className={cx({ buttons: full_screen })}>
                      {buttons.map((button_props) => (
                        <SC.StyledButton key={button_props.id} {...button_props} />
                      ))}
                    </SC.ButtonWrapper>
                  )}
                </>
              )}
            </SC.Container>
          </SC.Wrapper>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default BottomSheet;

function getScreenStyle(is_full_screen: boolean) {
  return is_full_screen
    ? css`
        border-radius: 0px;
        height: 100%;

        &.handelebar {
          top: 44px;
          border-radius: 16px 16px 0px 0px;
        }

        & .buttons {
          bottom: 44px;
          position: fixed;
          width: 100%;
        }
      `
    : css`
        border-radius: 16px 16px 0px 0px;
      `;
}

const SC = {
  Wrapper: styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 9998;
  `,
  Container: styled(motion.div)`
    transform: translateZ(0);
    position: absolute;
    z-index: 9999;
    margin: 0 auto;
    width: 100%;
    background: ${white};
    border-radius: 8px 8px 0 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: ${WEB_MAX_WIDTH}px;
    display: flex;
    flex-direction: column;
  `,
  ButtonWrapper: styled.div`
    display: flex;
    justify-content: space-around;
    padding: 0 16px;
    gap: 7px;
  `,
  StyledButton: styled(Button)`
    margin: 9px 0;
    width: 100%;
    display: block;
    cursor: pointer;
  `,
};
