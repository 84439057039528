import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const RadioEmptyCircle20: FC<IconProps> = ({
  size = 20,
  color = colors.white,
  sub_color = colors.gray300,
  ...props
}) => {
  return (
    <svg width={size} height={size} color={color} viewBox='0 0 20 20' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.5 10C0.5 4.75614 4.75614 0.5 10 0.5C15.2439 0.5 19.5 4.75614 19.5 10C19.5 15.2439 15.2439 19.5 10 19.5C4.75614 19.5 0.5 15.2439 0.5 10Z'
        fill='currentColor'
        stroke={sub_color}
      />
    </svg>
  );
};

export default RadioEmptyCircle20;
