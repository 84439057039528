import { FC } from 'react';

import { IconProps } from 'components/base/Icon';

const KakaoTalkSymbol28: FC<IconProps> = ({ size = 28, color = '#212529', ...props }) => {
  return (
    <svg width={size} height={size} fill='none' viewBox='0 0 28 28' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.0019 6.08299C8.84644 6.08299 4.66602 9.5387 4.66602 13.8238C4.66602 16.4777 6.28424 18.8 8.7427 20.2099L7.70537 24.2628C7.68525 24.3426 7.68862 24.427 7.71498 24.5048C7.74134 24.5825 7.78945 24.6497 7.8528 24.6973C7.91615 24.745 7.99169 24.7707 8.06922 24.7711C8.14674 24.7715 8.22251 24.7465 8.28629 24.6995L12.8298 21.4871C13.2136 21.5258 13.6026 21.548 13.9968 21.548C19.1523 21.548 23.3327 18.0923 23.3327 13.8072C23.3327 9.52211 19.1523 6.06641 13.9968 6.06641'
        fill={color}
      />
    </svg>
  );
};

export default KakaoTalkSymbol28;
