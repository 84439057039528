import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray600 } from 'styles';

const Courier24: FC<IconProps> = ({ width = 24, height = 24, color = gray600, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' color={color} {...props}>
    <path
      fill='currentColor'
      fillRule='nonzero'
      d='M15 3v3.999L19.414 7 24 11.586V19h-2.035a3.5 3.5 0 0 1-6.93 0L15 18.999V19H9.964a3.5 3.5 0 0 1-6.928 0H0V3h15zM6.644 17.007L6.5 17a1.5 1.5 0 1 0 1.493 1.644L8 18.52v-.041a1.5 1.5 0 0 0-1.355-1.472L6.5 17zM18.5 17a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM13 5H2v12h1.337a3.5 3.5 0 0 1 6.326 0H13V5zm5.586 4H15v8l.337-.001a3.5 3.5 0 0 1 6.326 0L22 17v-4.586L18.586 9z'
    />
  </svg>
);
export default Courier24;
