import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { black } from 'styles';

const QuestionMark16: FC<IconProps> = ({ width = 16, height = 16, color = black, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 16 16' fill='none' {...props}>
    <rect x='1.7' y='2.2' width='12.6' height='12.6' rx='6.3' stroke={color} strokeWidth='1.4' />
    <path
      d='M7.97998 12.75C8.42181 12.75 8.77998 12.3918 8.77998 11.95C8.77998 11.5081 8.42181 11.15 7.97998 11.15C7.53815 11.15 7.17998 11.5081 7.17998 11.95C7.17998 12.3918 7.53815 12.75 7.97998 12.75Z'
      fill={color}
      stroke={color}
      strokeWidth='0.1'
    />
    <path
      d='M8.57534 9.80696C8.57534 9.55041 8.73971 9.29462 8.94485 9.20295C9.96407 8.79483 10.6012 7.80069 10.5505 6.70497C10.5247 5.37869 9.45383 4.30785 8.15306 4.25641C7.43898 4.20564 6.7505 4.46081 6.24065 4.94517C5.73043 5.42988 5.45 6.09315 5.45 6.80662V6.85662H5.5H6.55012H6.60012V6.80662C6.60012 6.41941 6.74509 6.05816 7.03395 5.79336C7.32522 5.52637 7.68729 5.38231 8.07217 5.40638L8.07362 5.40643C8.79579 5.43051 9.37677 6.03304 9.42551 6.75928C9.44938 7.36296 9.087 7.91803 8.53225 8.13516C7.86154 8.3932 7.42522 9.06279 7.42522 9.80696V10.157V10.207H7.47522H8.52534H8.57534V10.157V9.80696Z'
      fill={color}
      stroke={color}
      strokeWidth='0.1'
    />
  </svg>
);
export default QuestionMark16;
