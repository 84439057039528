import { useState } from 'react';
import { useIntersect } from './useIntersect';

/**
 * 최초 노출 시에만 감지하며 이후 observe를 중단한다. (요소의 impression 여부를 확인)
 * @example
 * const { targetRef, isRevealed } = useOnceReveal();
 * useEffect(() => {
 *   if (isRevealed) { 최초 노출 시 실행할 로직 }
 * }, [isRevealed]);
 * @deprecated react-intersection-observer 라이브러리로 대체 합니다.
 * const {ref, inView} = useInView({ triggerOnce: true});
 */
export const useOnceReveal = (options?: Partial<IntersectionObserverInit>) => {
  const [isRevealed, setIsRevealed] = useState<boolean>(false);
  const { targetRef } = useIntersect(
    (entry, observer) => {
      if (entry.isIntersecting) {
        setIsRevealed(true);
        observer.unobserve(entry.target);
      }
    },
    { ...options },
  );

  return { targetRef, isRevealed };
};
