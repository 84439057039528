import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray_secondary } from 'styles';

const ThumbsDown16: FC<IconProps> = ({ width = 16, height = 16, color = gray_secondary, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 16 16' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.2 10.2237H1.5V9.52371V2.49932V1.79932H2.2H5.34286H6.04286V1.79978H9.94078C12.3888 1.79978 14.4268 3.67879 14.6253 6.1187L14.7972 8.23189C14.8845 9.30505 14.0371 10.2242 12.9604 10.2242H10.8997L11.7312 12.6092C11.9987 13.3764 11.696 14.2264 11.0038 14.6518C10.2985 15.0853 9.38486 14.9582 8.82474 14.3485L5.035 10.2237H2.2ZM2.9 3.19932V8.82371H4.64286V3.19932H2.9ZM9.85568 13.4013C9.96236 13.5174 10.1364 13.5417 10.2707 13.4591C10.4025 13.3781 10.4602 13.2162 10.4092 13.0701L9.25333 9.75461L8.92894 8.82417H9.91431H12.9604C13.2192 8.82417 13.4228 8.6033 13.4018 8.34541L13.2299 6.23221C13.0906 4.51908 11.6596 3.19978 9.94078 3.19978H6.04288V9.25142L9.85568 13.4013Z'
      fill={color}
    />
  </svg>
);
export default ThumbsDown16;
