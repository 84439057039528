import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray900 } from 'styles';

const BottomStyleP24: FC<IconProps> = ({ width = 24, height = 24, color = gray900, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.80078 4.50078C2.80078 3.00961 4.00961 1.80078 5.50078 1.80078H21.2008V19.2008H2.80078V4.50078ZM12.2696 6.54171C12.1593 6.31969 11.8426 6.31969 11.7323 6.54171L10.7429 8.53308C10.6992 8.62099 10.6153 8.68198 10.5182 8.69635L8.31851 9.02198C8.07327 9.05828 7.97541 9.35949 8.15247 9.53301L9.74062 11.0894C9.81074 11.1581 9.8428 11.2568 9.82646 11.3536L9.45642 13.5462C9.41517 13.7906 9.67139 13.9768 9.89113 13.862L11.8621 12.8325C11.9491 12.7871 12.0529 12.7871 12.1399 12.8325L14.1108 13.862C14.3306 13.9768 14.5868 13.7906 14.5455 13.5462L14.1755 11.3536C14.1592 11.2568 14.1912 11.1581 14.2613 11.0894L15.8495 9.53301C16.0265 9.35949 15.9287 9.05828 15.6834 9.02198L13.4838 8.69635C13.3867 8.68198 13.3027 8.62099 13.2591 8.53308L12.2696 6.54171Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.80078 19.5008C2.80078 20.9919 4.00961 22.2008 5.50078 22.2008H21.2008V20.6012H5.50098C4.94869 20.6012 4.50098 20.1535 4.50098 19.6012V19.2012H21.2008V17.8008H2.80078V19.5008Z'
      fill={color}
    />
  </svg>
);
export default BottomStyleP24;
