import { isNativeAppVersion } from './app/useAppVersion';
import useBrowserEffect from './useBrowserEffect';
import { hideHeaderForLegacyApp } from 'util/app_utils';
import { DeviceType } from 'util/device';

export const setHideAppHeader = () => {
  hideHeaderForLegacyApp();
};

// 앱 접근시 네이티브 헤더 숨기기 위한 공용 hook
const useHideAppHeader = (device_info: DeviceType) => {
  const is_native_version = isNativeAppVersion(device_info.user_agent);
  const is_not_native_app_version = device_info.is_app && !is_native_version;

  useBrowserEffect(() => {
    if (!is_not_native_app_version) {
      return;
    }
    setHideAppHeader();
  }, [is_not_native_app_version]);
};

export default useHideAppHeader;
