import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const Share20: FC<IconProps> = ({ size = 20, color = colors.gray900, sub_color = colors.white, ...props }) => {
  return (
    <svg width={size} height={size} fill='none' viewBox='0 0 20 20' {...props}>
      <rect width='20' height='20' fill={sub_color} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.4127 6.42687C12.0851 6.09343 11.8419 5.67684 11.7175 5.21167L6.71118 8.07245C7.03879 8.40588 7.28206 8.82246 7.40641 9.28763L12.4127 6.42687ZM7.40643 10.7117C7.28209 11.1768 7.03884 11.5934 6.71124 11.9269L11.7176 14.7876C11.8419 14.3225 12.0852 13.9059 12.4128 13.5724L7.40643 10.7117Z'
        fill={color}
      />
      <mask id='path-2-inside-1_715_3069' fill={sub_color}>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.125 4.5C17.125 6.01878 15.8938 7.25 14.375 7.25C12.8562 7.25 11.625 6.01878 11.625 4.5C11.625 2.98122 12.8562 1.75 14.375 1.75C15.8938 1.75 17.125 2.98122 17.125 4.5ZM7.5 10C7.5 11.5188 6.26878 12.75 4.75 12.75C3.23122 12.75 2 11.5188 2 10C2 8.48122 3.23122 7.25 4.75 7.25C6.26878 7.25 7.5 8.48122 7.5 10ZM14.375 18.25C15.8938 18.25 17.125 17.0188 17.125 15.5C17.125 13.9812 15.8938 12.75 14.375 12.75C12.8562 12.75 11.625 13.9812 11.625 15.5C11.625 17.0188 12.8562 18.25 14.375 18.25Z'
        />
      </mask>
      <path
        d='M14.375 8.65C16.667 8.65 18.525 6.79198 18.525 4.5H15.725C15.725 5.24558 15.1206 5.85 14.375 5.85V8.65ZM10.225 4.5C10.225 6.79198 12.083 8.65 14.375 8.65V5.85C13.6294 5.85 13.025 5.24558 13.025 4.5H10.225ZM14.375 0.35C12.083 0.35 10.225 2.20802 10.225 4.5H13.025C13.025 3.75442 13.6294 3.15 14.375 3.15V0.35ZM18.525 4.5C18.525 2.20802 16.667 0.35 14.375 0.35V3.15C15.1206 3.15 15.725 3.75442 15.725 4.5H18.525ZM4.75 14.15C7.04198 14.15 8.9 12.292 8.9 10H6.1C6.1 10.7456 5.49558 11.35 4.75 11.35V14.15ZM0.6 10C0.6 12.292 2.45802 14.15 4.75 14.15V11.35C4.00442 11.35 3.4 10.7456 3.4 10H0.6ZM4.75 5.85C2.45802 5.85 0.6 7.70802 0.6 10H3.4C3.4 9.25442 4.00442 8.65 4.75 8.65V5.85ZM8.9 10C8.9 7.70802 7.04198 5.85 4.75 5.85V8.65C5.49558 8.65 6.1 9.25442 6.1 10H8.9ZM15.725 15.5C15.725 16.2456 15.1206 16.85 14.375 16.85V19.65C16.667 19.65 18.525 17.792 18.525 15.5H15.725ZM14.375 14.15C15.1206 14.15 15.725 14.7544 15.725 15.5H18.525C18.525 13.208 16.667 11.35 14.375 11.35V14.15ZM13.025 15.5C13.025 14.7544 13.6294 14.15 14.375 14.15V11.35C12.083 11.35 10.225 13.208 10.225 15.5H13.025ZM14.375 16.85C13.6294 16.85 13.025 16.2456 13.025 15.5H10.225C10.225 17.792 12.083 19.65 14.375 19.65V16.85Z'
        fill={color}
        mask='url(#path-2-inside-1_715_3069)'
      />
    </svg>
  );
};

export default Share20;
