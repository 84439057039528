import { useRouter } from 'next/router';
import { useMount } from 'hooks/useMount';
import { useTracker } from 'util/Tracker';

export const useSendLoginLog = () => {
  const { replace } = useRouter();
  const tracker = useTracker();
  const href = typeof window !== 'undefined' ? window.location.href : '';

  useMount(() => {
    const url = new URL(href);
    const is_login_completed = url.searchParams.get('_tracker_');

    if (!is_login_completed) {
      return;
    }

    tracker.login();
    url.searchParams.delete('_tracker_');

    replace(url, undefined, { shallow: true });
  });
};
