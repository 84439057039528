import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray700 } from 'styles';

const ArrowRight24: FC<IconProps> = ({ width = 24, height = 24, color = gray700, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
    <path d='M7.19998 3.6001L15.6 12.0001L7.19998 20.4001' stroke={color} strokeWidth='1.4' />
  </svg>
);
export default ArrowRight24;
