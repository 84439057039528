import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const Coupon20: FC<IconProps> = ({ width = 20, height = 20, color = colors.white, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' {...props}>
      <g id='101_Icon/Mypage/Coupon' opacity='0.5'>
        <path
          id='Union'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19 8.70024L18.19 9.51029L17.2 10.5002L18.19 11.4902L19 12.3002V16.26C19 17.0553 18.3553 17.7 17.56 17.7H2.44C1.64471 17.7 1 17.0553 1 16.26V12.3002L1.81005 11.4902L2.8 10.5002L1.81005 9.5103L1 8.70024V4.74005C1 3.94476 1.64471 3.30005 2.44 3.30005H17.56C18.3553 3.30005 19 3.94476 19 4.74005V8.70024ZM16.2101 11.4902L17.6 12.8801V16.26C17.6 16.2682 17.5986 16.2723 17.5974 16.2752C17.5958 16.279 17.5929 16.2837 17.5883 16.2883C17.5836 16.293 17.5789 16.2959 17.5751 16.2975C17.5723 16.2987 17.5681 16.3 17.56 16.3H2.44C2.4319 16.3 2.4277 16.2987 2.42486 16.2975C2.42109 16.2959 2.41635 16.293 2.41172 16.2883C2.40708 16.2837 2.40416 16.279 2.40257 16.2752C2.40137 16.2723 2.4 16.2682 2.4 16.26V12.8801L3.78995 11.4902L4.7799 10.5002L3.78995 9.5103L2.4 8.12035V4.74005C2.4 4.73194 2.40137 4.72775 2.40257 4.72491C2.40416 4.72114 2.40708 4.7164 2.41172 4.71176C2.41635 4.70713 2.42109 4.70421 2.42486 4.70262C2.4277 4.70142 2.4319 4.70005 2.44 4.70005H17.56C17.5681 4.70005 17.5723 4.70142 17.5751 4.70262C17.5789 4.70421 17.5836 4.70713 17.5883 4.71176C17.5929 4.7164 17.5958 4.72114 17.5974 4.72491C17.5986 4.72775 17.6 4.73194 17.6 4.74005V8.12034L16.2101 9.51029L15.2201 10.5002L16.2101 11.4902ZM7.08062 10.0604C7.85382 10.0604 8.48062 9.43356 8.48062 8.66036C8.48062 7.88716 7.85382 7.26036 7.08062 7.26036C6.30743 7.26036 5.68062 7.88716 5.68062 8.66036C5.68062 9.43356 6.30743 10.0604 7.08062 10.0604ZM6.77536 13.1583L11.3787 7.67232H13.2062L8.60293 13.1583H6.77536ZM14.4806 11.8156C14.4806 12.5888 13.8538 13.2156 13.0806 13.2156C12.3074 13.2156 11.6806 12.5888 11.6806 11.8156C11.6806 11.0424 12.3074 10.4156 13.0806 10.4156C13.8538 10.4156 14.4806 11.0424 14.4806 11.8156Z'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Coupon20;
