import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const RadioCircle20: FC<IconProps> = ({ size = 20, color = colors.pink600, sub_color = colors.white, ...props }) => {
  return (
    <svg width={size} height={size} fill='none' viewBox='0 0 20 20' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14Z'
        fill={sub_color}
      />
    </svg>
  );
};

export default RadioCircle20;
