import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray900 } from 'styles';

const BottomMypageP24: FC<IconProps> = ({ width = 24, height = 24, color = gray900, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
    <mask id='path-1-inside-1_2221_12725' fill='white'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12ZM10.5 13H13.5C17.9183 13 21.5 16.5817 21.5 21C15.2773 22.674 8.72272 22.674 2.5 21C2.50001 16.5817 6.08173 13 10.5 13Z'
      />
    </mask>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12ZM10.5 13H13.5C17.9183 13 21.5 16.5817 21.5 21C15.2773 22.674 8.72272 22.674 2.5 21C2.50001 16.5817 6.08173 13 10.5 13Z'
      fill={color}
    />
    <path
      d='M21.5 21L21.8637 22.3519L22.9 22.0732L22.9 21L21.5 21ZM2.5 21L1.1 21L1.1 22.0732L2.13632 22.3519L2.5 21ZM15.6 7C15.6 8.98822 13.9882 10.6 12 10.6V13.4C15.5346 13.4 18.4 10.5346 18.4 7H15.6ZM12 3.4C13.9882 3.4 15.6 5.01177 15.6 7H18.4C18.4 3.46538 15.5346 0.6 12 0.6V3.4ZM8.4 7C8.4 5.01177 10.0118 3.4 12 3.4V0.6C8.46538 0.6 5.6 3.46538 5.6 7H8.4ZM12 10.6C10.0118 10.6 8.4 8.98822 8.4 7H5.6C5.6 10.5346 8.46538 13.4 12 13.4V10.6ZM13.5 11.6H10.5V14.4H13.5V11.6ZM22.9 21C22.9 15.8085 18.6915 11.6 13.5 11.6V14.4C17.1451 14.4 20.1 17.3549 20.1 21L22.9 21ZM2.13632 22.3519C8.59726 24.09 15.4027 24.09 21.8637 22.3519L21.1363 19.6481C15.1518 21.2579 8.84818 21.2579 2.86368 19.6481L2.13632 22.3519ZM10.5 11.6C5.30853 11.6 1.10001 15.8085 1.1 21L3.9 21C3.9 17.3549 6.85493 14.4 10.5 14.4V11.6Z'
      fill={color}
      mask='url(#path-1-inside-1_2221_12725)'
    />
  </svg>
);
export default BottomMypageP24;
