import {
  isAndroid,
  isBrowser,
  compareWithAppVersion,
  sendMarketingEvent,
  login,
  loginWithRedirectUrl,
  logout,
  back,
  close,
  createOrderSheet,
  open,
  setTitle,
  addCart,
  deleteCart,
  updateCart,
  addOrder,
  deleteOrder,
  updateOrder,
  productReviewPhotoDetail,
  relatedItemList,
  injectZpayHandler,
  zpayHandler,
  sual,
  st,
  tt,
  ckla,
  cbk,
  csp,
  abg,
  MarketingEventItem,
  callZigzagFunction,
  SaveProduct,
  SavedProduct,
  zigzagHandler,
  SavedProductStatus,
} from '@croquiscom/web2app';
import { CouponPayload } from 'util/coupon-utils/types';

export const checkZPayFunctionExists = (browser_window: any) => {
  return Boolean(
    browser_window.zpay ||
      (browser_window.webkit && browser_window.webkit.messageHandlers && browser_window.webkit.messageHandlers.zpay),
  );
};

// Zpay
export enum ZpayPostMessageType {
  REFRESH_SIMPLE_BANK_ACCOUNT_LIST = 'refresh_simple_bank_account_list',
  PURCHASE_BY_SIMPLE_PAY = 'purchase_by_simple_pay',
  ADD_PRODUCT_REVIEW = 'add_product_review',
  PURCHASE_ORDER_SHEET_BY_SIMPLE_PAY = 'purchase_order_sheet_by_simple_pay',
  SET_COUPON_PAYLOADS = 'set_coupon_payloads',
  FORCE_REFRESH_PAGE = 'force_refersh_page',
}

export const getAppScheme = (): string => {
  if (isBrowser() && compareWithAppVersion('5.11.2')) {
    if (isAndroid()) {
      return '';
    }
    const browser_window: any = window;

    if (!browser_window) {
      return 'zigzag://';
    }
    return checkZPayFunctionExists(browser_window) ? 'zigzag://' : '';
  }
  return '';
};

export const overwriteClose = (callback: (close: () => void) => void) => {
  injectZpayHandler();
  zpayHandler('handleCloseClick', () => {
    callback(ZpayEvent.close);
    return true;
  });
};

export const overwriteOnMessage = (callback: (type: ZpayPostMessageType, message: any, origin?: string) => void) => {
  injectZpayHandler();
  zpayHandler('onMessage', (message, targetOrigin) => {
    if (targetOrigin === '*' || window.location.origin === targetOrigin) {
      const data = JSON.parse(message);
      const type = data.type as ZpayPostMessageType;
      callback(type, data, targetOrigin);
    }
  });
};

const sendPostMessageEvent = (type: ZpayPostMessageType, data: any = {}) => {
  const targetOrigin = isBrowser() ? location.origin : '*';

  const strMessage = JSON.stringify({ type, ...data });
  window.opener.postMessage(strMessage, targetOrigin);
};

export const ZpayEvent = {
  login,
  loginWithRedirectUrl,
  logout,
  back,
  close,
  createOrderSheet,
  open,
  setTitle,
  addCart,
  deleteCart,
  updateCart,
  addOrder,
  deleteOrder,
  updateOrder,
  productReviewPhotoDetail,
  relatedItemList,
  refreshSimpleBankAccountList: () => sendPostMessageEvent(ZpayPostMessageType.REFRESH_SIMPLE_BANK_ACCOUNT_LIST),
  purchaseBySimplePay: (password: string) =>
    sendPostMessageEvent(ZpayPostMessageType.PURCHASE_BY_SIMPLE_PAY, { password }),
  purchaseOrderSheetBySimplePay: (order_sheet_uuid: string, password: string) =>
    sendPostMessageEvent(ZpayPostMessageType.PURCHASE_ORDER_SHEET_BY_SIMPLE_PAY, { order_sheet_uuid, password }),
  setCouponPayloads: (coupon_payloads: CouponPayload[]) =>
    sendPostMessageEvent(ZpayPostMessageType.SET_COUPON_PAYLOADS, { coupon_payloads }),
  forceRefreshPage: () => sendPostMessageEvent(ZpayPostMessageType.FORCE_REFRESH_PAGE),
};

// ZpayMarketingEvent
export const ZpayMarketingEvent = {
  addCart: (params: {
    item_list: Array<{
      shop_main_domain: string;
      product_no: string;
      product_item_id: string;
      quantity: number;
      price: number;
    }>;
  }) => sendMarketingEvent('ADD_CART', params),
  addOrder: (params: {
    order_number: string;
    item_list: MarketingEventItem[];
    total_amount: number;
    points_used: number;
    coupon_policy_list: string[];
    coupons_used: number;
  }) => sendMarketingEvent('ADD_ORDER', params),
  initiateCheckout: (params: { item_list: MarketingEventItem[] }) => sendMarketingEvent('INITIATE_CHECKOUT', params),
};

interface UBL {
  category: string;
  navigation: string;
  navigation_sub?: string;
  object_type?: string;
  object_id?: string | null;
  object_section?: string;
  object_url?: string;
  object_idx?: number;
  data?: string;
}

type ActionOptionalLog = Pick<UBL, 'object_section' | 'object_idx'>;

function sendUbl(log: UBL) {
  if (isAndroid()) {
    callZigzagFunction('subl')({ log: JSON.stringify(log) });
    return;
  }
  callZigzagFunction('subl')({ log });
}

const addBookMark = (shopMainDomain: string, logs?: ActionOptionalLog) => {
  const data = stringifyLogs({
    shopMainDomain,
    logs: {
      server_log: logs,
    },
  });

  return new Promise<boolean>((resolve) => {
    callZigzagFunction('abk')(data);
    zigzagHandler('onBookmarkStatus', (maps) => {
      resolve(maps[shopMainDomain]);
    });
  });
};

const removeBookMark = (shopMainDomain: string, logs?: ActionOptionalLog) => {
  const data = stringifyLogs({
    shopMainDomain,
    logs: {
      server_log: logs,
    },
  });

  return new Promise<boolean>((resolve) => {
    callZigzagFunction('rbk')(data);
    zigzagHandler('onBookmarkStatus', (maps) => {
      resolve(maps[shopMainDomain]);
    });
  });
};

const saveProduct = (saveProductData: SaveProduct, logs?: ActionOptionalLog & { main_title?: string }) => {
  const data = stringifyLogs({
    ...saveProductData,
    logs: {
      server_log: logs,
    },
  });
  return new Promise<SavedProductStatus>((resolve) => {
    callZigzagFunction('sp')(data);
    zigzagHandler('onSavedProductStatus', (savedProductStatusList) => {
      resolve(savedProductStatusList[0]);
    });
  });
};

const removeSavedProduct = (savedProductData: SavedProduct, logs?: ActionOptionalLog) => {
  const data = stringifyLogs({
    ...savedProductData,
    logs: {
      server_log: logs,
    },
  });
  return new Promise<SavedProductStatus>((resolve) => {
    callZigzagFunction('rsp')(data);
    zigzagHandler('onSavedProductStatus', (savedProductStatusList) => {
      resolve(savedProductStatusList[0]);
    });
  });
};

// @ts-ignore
function stringifyLogs(data) {
  if (!data.logs || !data.logs.server_log) {
    return data;
  }
  if (!isAndroid()) {
    return data;
  }
  return {
    ...data,
    logs: JSON.stringify(data.logs),
  };
}

// ZigzagEvent
export const getShopProductNo = (shop_main_domain: string, product_no: string) => shop_main_domain + '$' + product_no;

export const ZigzagEvent = {
  sual: (log: string) => sual('action', log),
  subl: (log: UBL) => sendUbl(log),
  sual_app: (log: string) => sual('app', log),
  sual_info: (log: string) => sual('info', log),
  st,
  tt,
  ckla,
  cbk,
  abk: (shopMainDomain: string, logs?: ActionOptionalLog) => addBookMark(shopMainDomain, logs),
  rbk: (shopMainDomain: string, logs?: ActionOptionalLog) => removeBookMark(shopMainDomain, logs),
  csp,
  sp: (saveProductData: SaveProduct, logs?: ActionOptionalLog & { main_title?: string }) =>
    saveProduct(saveProductData, logs),
  rsp: (savedProductData: SavedProduct, logs?: ActionOptionalLog) => removeSavedProduct(savedProductData, logs),
  abg,
};

// misc
export const getMobileOS = (userAgent: NavigatorID['userAgent']) => {
  const userAgentLowerCase = userAgent.toLowerCase();

  if (userAgentLowerCase.match(/iPhone|iPad|iPod|\bOS X\b/gi)) {
    return 'ios';
  } else if (userAgentLowerCase.match(/windows phone|iemobile|wpdesktop/i)) {
    return 'windows';
  } else if (userAgentLowerCase.match(/blackberry|bb10|playbook/i)) {
    return 'blackberry';
  } else if (userAgentLowerCase.match(/android/i)) {
    return 'android';
  } else {
    return 'unknown';
  }
};
