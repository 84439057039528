import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray600 } from 'styles';

const BottomStoreN24: FC<IconProps> = ({ width = 24, height = 24, color = gray600, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M2.35487 12.2548L3.07475 3.07515L12.2544 2.35527L22.8738 12.9747L12.9743 22.8742L2.35487 12.2548Z'
      stroke={color}
      strokeWidth='1.4'
    />
    <circle cx='8.02565' cy='8.0249' r='1.75' transform='rotate(-45 8.02565 8.0249)' stroke={color} strokeWidth='1.4' />
  </svg>
);
export default BottomStoreN24;
