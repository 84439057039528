import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { border1, border2, gray050 } from 'styles';

interface Props extends IconProps {
  third_color?: string;
}

const Card40: FC<Props> = ({
  width = 40,
  height = 40,
  color = border1,
  sub_color = border2,
  third_color = gray050,
  ...props
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 40 40' fill='none' {...props}>
      <rect x='5.6' y='10.6' width='28.8' height='18.8' rx='3.4' fill={third_color} stroke={color} strokeWidth='1.2' />
      <rect x='9' y='18' width='5' height='4' rx='1' fill={sub_color} />
    </svg>
  );
};

export default Card40;
