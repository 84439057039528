import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray_tertiary } from 'styles';

const Schedule20: FC<IconProps> = ({ width = 20, height = 20, color = gray_tertiary, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 20 20' fill='none' {...props}>
    <path d='M18 9L2 9' stroke={color} strokeWidth='1.4' />
    <path d='M6 3L6 7' stroke={color} strokeWidth='1.4' />
    <path d='M14 3L14 7' stroke={color} strokeWidth='1.4' />
    <rect x='2' y='5' width='16' height='12' rx='2' stroke={color} strokeWidth='1.4' />
  </svg>
);
export default Schedule20;
