import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray600 } from 'styles';

const BottomMypageN24: FC<IconProps> = ({ width = 24, height = 24, color = gray600, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.5 14.4H13.5C16.7809 14.4 19.5026 16.7939 20.0138 19.9303C14.7426 21.1638 9.2574 21.1638 3.98624 19.9303C4.49739 16.7939 7.21913 14.4 10.5 14.4ZM2.5 21C8.72272 22.6739 15.2773 22.6739 21.5 21C21.5 16.5817 17.9183 13 13.5 13H10.5C6.08173 13 2.50001 16.5817 2.5 21Z'
      fill={color}
    />
    <circle cx='12' cy='7' r='4.3' stroke={color} strokeWidth='1.4' />
  </svg>
);
export default BottomMypageN24;
