import { isIOS } from '@croquiscom/web2app';
import { callAppDeepLink, getAppVersion } from './app_utils';

// 카카오톡 전용브라우저 사용 시작 버전
const KAKAO_PRIVATE_BROWSER_VERION = {
  IOS: '9.6.4',
  AOS: '0964',
  // 우측 x버튼 내재화 가능 IOS 버전
  IOS_BY_USE_X_BUTTON: '9.6.9',
};

export const isKaKaoTalkBrowser = () => {
  if (typeof window !== 'undefined') {
    return window.navigator.userAgent.includes('KAKAOTALK');
  }

  return false;
};

// 카톡 브라우저에서 이벤트 클릭하여 바로 특정 페이지 진입 한 경우
export const iskaKaoTalBrowserDirectAccess = () => {
  return isKaKaoTalkBrowser() && !document.referrer;
};

// 카톡 브라우저 닫기
export const closeKaKaoTalkBrowserClose = () => {
  location.href = 'app://closeWebview';
};

//  aos: versionCode, ios: semver 버저닝 (서로 버전전략이 다름)
// feat: https://kakaostyle.agit.in/g/300027056/wall/349107466?ir=false&l=agit_user_mentioned_v3&sid=349403120&tid=300046008&ts=1640233634#comment_panel_349403120
export const getKaKaoTalkAppVersion = (user_agent = navigator.userAgent) => {
  const is_ios = isIOS();
  const reg = {
    aos: /KAKAOTALK \D*(\d+(\d+)+)/,
    ios: /KAKAOTALK \D*(\d+(\.\d+)+)/,
  };

  const exp_match = user_agent.match(is_ios ? reg.ios : reg.aos);

  if (exp_match) {
    return is_ios ? exp_match[1] : exp_match[1].substr(2, 4);
  }

  return '';
};

// IOS 자체x버튼 사용 체크
// feat: https://croquis.atlassian.net/browse/FBK-458
export const isShowKakaoCloseButton = () => {
  return isIOS() && !isOverIosVersion(KAKAO_PRIVATE_BROWSER_VERION.IOS_BY_USE_X_BUTTON);
};

const isOverIosVersion = (compare_ios_version: string) => {
  const splitVersion = (version: string) => version.split('.').map((v) => Number(v));
  const current_version = splitVersion(getKaKaoTalkAppVersion());
  const cocompare_version = splitVersion(compare_ios_version);

  if (current_version.length !== cocompare_version.length) {
    return false;
  }

  for (let i = 0; i < cocompare_version.length; i++) {
    if (current_version[i] === cocompare_version[i]) {
      continue;
    }

    return current_version[i] > cocompare_version[i];
  }
  return false;
};

// 카카오톡 전용 인앱브라우저 실행 판단 여부
export const isKaKaoTalkPrivateBroswer = () => {
  if (!isKaKaoTalkBrowser()) {
    return false;
  }

  const iosCheck = () => {
    return isOverIosVersion(KAKAO_PRIVATE_BROWSER_VERION.IOS);
  };

  const aosCheck = () => {
    const current_version = Number(getKaKaoTalkAppVersion());
    return current_version > Number(KAKAO_PRIVATE_BROWSER_VERION.AOS);
  };

  return isIOS() ? iosCheck() : aosCheck();
};

// 플러스 친구 등록
export const addPlusFriends = () => {
  const Kakao = (window as any).Kakao;

  if (!Kakao) {
    return;
  }

  // IOS 6.5.0 버전에서 새창웹뷰 버그로 앱 튕김 현상 발생하여, 채널추가 딥링크 직접 호출
  if (typeof window !== 'undefined') {
    const current_version = getAppVersion(navigator.userAgent);
    const add_channel_url = 'https://pf.kakao.com/_eurnq/friend';

    if (isIOS() && current_version === '6.5.0') {
      callAppDeepLink(add_channel_url, 'navigation');
      return;
    }
  }

  Kakao.Channel.addChannel({
    channelPublicId: '_eurnq',
  });
};
