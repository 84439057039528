import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const ShareUrl32: FC<IconProps> = ({ size = 32, color = colors.gray700, sub_color = colors.gray100, ...props }) => {
  return (
    <svg width={size} height={size} fill='none' viewBox='0 0 32 32' {...props}>
      <rect width={size} height={size} rx='16' fill={sub_color} />
      <path
        d='M12.1172 12.9297V17.4414C12.1172 18.335 11.502 18.9697 10.5254 18.9746C9.54883 18.9697 8.92871 18.335 8.92383 17.4414V12.9297H7.6543V17.5488C7.65918 19.0674 8.79199 20.1074 10.5254 20.1074C12.249 20.1074 13.3916 19.0674 13.3965 17.5488V12.9297H12.1172ZM14.7051 20H15.9746V17.4121H17.3027L18.6895 20H20.1055L18.5771 17.1973C19.4072 16.8604 19.8467 16.1475 19.8516 15.1855C19.8467 13.8379 18.9824 12.9297 17.3613 12.9297H14.7051V20ZM15.9746 16.3477V13.9941H17.166C18.123 13.9941 18.5527 14.4336 18.5527 15.1855C18.5527 15.9424 18.123 16.3525 17.166 16.3477H15.9746ZM20.9453 20H25.3398V18.9258H22.2148V12.9297H20.9453V20Z'
        fill={color}
      />
    </svg>
  );
};

export default ShareUrl32;
