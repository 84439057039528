import { useEffect, useRef } from 'react';

/**
 *
 * @hook 이전 상태의 props, state 의 값이 필요한 경우 사용한다.
 * @example
 *  const [count, setCount] = useState(0);
 *  const prev_count = usePreviousValue(count);
 *  위의 케이스에서 setCount(1)이 호출 되는 경우
 *  count = 1, prev_count = 0 으로 노출
 */
export const usePreviousValue = <T>(value: T): T | undefined => {
  const prevValue = useRef<T>();

  useEffect(() => {
    prevValue.current = value;
  }, [value]);

  return prevValue.current;
};

export default usePreviousValue;
