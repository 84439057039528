import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const Point60: FC<IconProps> = ({ size = 60, color = colors.pink600, ...props }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 60 60' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.9973 11.9609C20.0361 11.9609 11.9609 20.0361 11.9609 29.9973C11.9609 39.9585 20.0361 48.0336 29.9973 48.0336C39.9585 48.0336 48.0336 39.9585 48.0336 29.9973C48.0336 20.0361 39.9585 11.9609 29.9973 11.9609ZM9.96094 29.9973C9.96094 18.9315 18.9315 9.96094 29.9973 9.96094C41.063 9.96094 50.0336 18.9315 50.0336 29.9973C50.0336 41.063 41.063 50.0336 29.9973 50.0336C18.9315 50.0336 9.96094 41.063 9.96094 29.9973Z'
        fill={color}
      />
      <path d='M25.7393 22.2324V38.0125' stroke={color} strokeWidth='2' />
      <path
        d='M25.7393 22.2324H31.876C34.2968 22.2324 36.2593 24.1949 36.2593 26.6158V26.6158C36.2593 29.0367 34.2968 30.9992 31.876 30.9992H25.7393V22.2324Z'
        stroke={color}
        strokeWidth='2'
      />
    </svg>
  );
};

export default Point60;
