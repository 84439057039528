import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray200, gray500 } from 'styles';

const CircleArrowRight32: FC<IconProps> = ({ width = 32, height = 32, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 32 32' fill='none' {...props}>
    <circle cx='16' cy='16' r='15.5' fill='white' stroke={gray200} />
    <path d='M16 10.6665L21.3334 15.9998L16 21.3332' stroke={gray500} strokeWidth='1.4' />
    <path d='M20.6667 16L10.0001 16' stroke={gray500} strokeWidth='1.4' />
  </svg>
);
export default CircleArrowRight32;
