import { useCallback, useRef } from 'react';
require('intersection-observer');

export const useIntersect = (
  onIntersect: (entry: IntersectionObserverEntry, observer: IntersectionObserver) => void,
  options?: IntersectionObserverInit,
) => {
  const targetRef = useRef<Element | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const checkIntersect = useCallback(
    ([entry]: [IntersectionObserverEntry], observer: IntersectionObserver) => {
      onIntersect(entry, observer);
    },
    [onIntersect],
  );

  const unobserve = useCallback(() => {
    const currentObserver = observerRef.current;
    currentObserver?.disconnect();
    observerRef.current = null;
  }, []);

  const observe = useCallback(() => {
    const target = targetRef.current;
    let observer: IntersectionObserver;

    if (target) {
      // @ts-ignore
      observer = new IntersectionObserver(checkIntersect, options);
      observer.observe(target);
      observerRef.current = observer;
    }
  }, [options, checkIntersect]);

  const refCallback = useCallback<React.RefCallback<Element>>(
    (element) => {
      targetRef.current = element;
      unobserve();
      observe();
    },
    [observe, unobserve],
  );

  return { targetRef: refCallback };
};
