import { useBroadcastChannel } from '@use-broadcast-channel/hooks';
import { PropsWithChildren, createContext, useContext } from 'react';
import { useAppVersion } from 'hooks/app/useAppVersion';
import { useLikeProductList } from 'queries/like/useLikeProductList';
import { useLikeStoreList } from 'queries/like/useLikeStoreList';

type BroadCastRefetchKey = 'like_product_list' | 'like_store_list';

interface BroadCastRefetch {
  key: BroadCastRefetchKey;
  props?: unknown;
}

export interface BroadCastConextData {
  refetchBroadCast: (info: BroadCastRefetch) => void;
}

const BroadCastConext = createContext<BroadCastConextData>({
  refetchBroadCast: () => {},
});

export const useBroadCastConext = () => useContext(BroadCastConext);

export interface BroadContextProps extends PropsWithChildren {}

export default function BroadCastConextProvider({ children }: BroadContextProps) {
  const { is_native_version } = useAppVersion();
  // TODO: https://croquis.slack.com/archives/CSR4U25PG/p1710138871189199 이슈로 의심포인트 우선 주석(유저서비스 영향도 x)
  // const { refetch: refetchLikeProductList } = useLikeProductList(false);
  // const { refetch: refetchLikeStoreList } = useLikeStoreList(false);
  const { postMessage: refetchBroadCast } = useBroadcastChannel<BroadCastRefetch>('refetch_broadcast', ({ key }) => {
    if (typeof window === 'undefined') {
      console.warn('not working for serverSide');
      return;
    }

    if (!is_native_version) {
      return;
    }

    const is_refetch_like_page = window.location.pathname.startsWith('/like');
    // 메인탭 (찜)
    if (is_refetch_like_page) {
      // key === 'like_product_list' && refetchLikeProductList();
      // key === 'like_store_list' && refetchLikeStoreList();
      return;
    }
  });

  return <BroadCastConext.Provider value={{ refetchBroadCast }}>{children}</BroadCastConext.Provider>;
}
