import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { border1, border2, gray050 } from 'styles';

interface Props extends IconProps {
  third_color?: string;
}

const Phone40: FC<Props> = ({
  width = 40,
  height = 40,
  color = border1,
  sub_color = border2,
  third_color = gray050,
  ...props
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 40 40' fill='none' {...props}>
      <rect
        x='29.4'
        y='5.6'
        width='28.8'
        height='18.8'
        rx='3.4'
        transform='rotate(90 29.4 5.6)'
        fill={third_color}
        stroke={color}
        strokeWidth='1.2'
      />
      <mask
        id='mask0_2095_14886'
        // style='mask-type:alpha'
        maskUnits='userSpaceOnUse'
        x='10'
        y='5'
        width='20'
        height='30'
      >
        <rect
          x='29.4'
          y='5.6'
          width='28.8'
          height='18.8'
          rx='3.4'
          transform='rotate(90 29.4 5.6)'
          fill={third_color}
          stroke={color}
          strokeWidth='1.2'
        />
      </mask>
      <g mask='url(#mask0_2095_14886)'>
        <rect x='17.5' y='8' width='5' height='2' rx='1' fill={sub_color} />
        <rect x='10' y='11' width='20' height='19' fill={sub_color} />
      </g>
      <rect
        x='29.4'
        y='5.6'
        width='28.8'
        height='18.8'
        rx='3.4'
        transform='rotate(90 29.4 5.6)'
        stroke={color}
        strokeWidth='1.2'
      />
    </svg>
  );
};

export default Phone40;
