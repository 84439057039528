import { EventHandler, SyntheticEvent } from 'react';

import { appLandingUrl } from '../components/common/AppLandingNotify/useAdLadingNotify';
import { DialogOption } from '../components/common/BottomSheet';
import { useIsKakaoAosPrivateBrowser } from './useIsKakaoPrivateBrowser';
import { useBottomDialog } from 'context/ButtomDialogContext';

type AnyEvent = SyntheticEvent<HTMLElement>;
type AnyEventHandler<P extends AnyEvent> = EventHandler<P>;

interface Config<T> extends DialogOption {
  callback: T;
}

export const useCheckKakaoAosBrowser = <T extends AnyEventHandler<P>, P extends AnyEvent>(config: Config<T>) => {
  const { callback, ...rest } = config;
  const is_aos_kakao_browser = useIsKakaoAosPrivateBrowser();
  const { open: openAppDownloadGuide } = useBottomDialog();

  const handleAppDownload = async () => {
    const id = await openAppDownloadGuide(rest);
    if (id === 'confirm') {
      window.location.assign(appLandingUrl);
    }
  };

  const proxy_callback = (e: P) => {
    if (!is_aos_kakao_browser) {
      return callback(e);
    }
    e.preventDefault();
    handleAppDownload();
  };

  return proxy_callback as T;
};
