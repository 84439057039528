import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray500 } from 'styles';

const ArrowRight16: FC<IconProps> = ({ width = 16, height = 16, color = gray500, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 16 16' fill='none' {...props}>
    <path d='M5.5 13.5L11 8L5.5 2.5' stroke={color} strokeWidth='1.4' />
  </svg>
);
export default ArrowRight16;
