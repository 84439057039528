import { FC } from 'react';

import { gray_tertiary } from '../../../styles';
import { IconProps } from './index';

const Photo20: FC<IconProps> = ({ width = 20, height = 20, color = gray_tertiary, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' {...props}>
      <mask
        id='mask0_5033_26204'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='-2'
        y='8'
        width='26'
        height='13'
      >
        <path
          d='M12.2337 9.56499L11.6905 9.12342L11.6905 9.12342L12.2337 9.56499ZM14.6568 9.56499L15.2 9.12342L15.2 9.12342L14.6568 9.56499ZM8.29235 14.4131L7.74574 14.8504L8.28797 15.5282L8.83551 14.8547L8.29235 14.4131ZM7.19932 13.0468L6.65271 13.4841L6.65271 13.4841L7.19932 13.0468ZM4.76079 13.0468L4.21418 12.6095L4.21418 12.6095L4.76079 13.0468ZM-0.265625 19.3298L-0.812233 18.8925L-1.72206 20.0298H-0.265625V19.3298ZM12.2257 19.3298V20.0298H13.6822L12.7723 18.8925L12.2257 19.3298ZM12.0091 19.059V18.359H10.5526L11.4625 19.4963L12.0091 19.059ZM22.3751 19.059V19.759H23.8463L22.9182 18.6174L22.3751 19.059ZM12.7768 10.0066C13.1216 9.58252 13.7689 9.58252 14.1136 10.0066L15.2 9.12342C14.295 8.01022 12.5955 8.01023 11.6905 9.12342L12.7768 10.0066ZM8.83551 14.8547L12.7768 10.0066L11.6905 9.12342L7.74919 13.9715L8.83551 14.8547ZM6.65271 13.4841L7.74574 14.8504L8.83896 13.9758L7.74593 12.6095L6.65271 13.4841ZM5.3074 13.4841C5.65225 13.053 6.30787 13.053 6.65271 13.4841L7.74593 12.6095C6.84063 11.4779 5.11949 11.4779 4.21418 12.6095L5.3074 13.4841ZM0.280983 19.7671L5.3074 13.4841L4.21418 12.6095L-0.812233 18.8925L0.280983 19.7671ZM12.2257 18.6298H-0.265625V20.0298H12.2257V18.6298ZM11.4625 19.4963L11.6791 19.7671L12.7723 18.8925L12.5557 18.6217L11.4625 19.4963ZM22.3751 18.359H12.0091V19.759H22.3751V18.359ZM14.1136 10.0066L21.8319 19.5006L22.9182 18.6174L15.2 9.12342L14.1136 10.0066Z'
          fill={color}
        />
      </mask>
      <g mask='url(#mask0_5033_26204)'>
        <rect
          x='1.92034'
          y='1.9981'
          width='15.9265'
          height='15.9265'
          rx='3.27898'
          fill={color}
          stroke='#E62F71'
          strokeWidth='1.24914'
        />
      </g>
      <rect x='1.99578' y='2.07354' width='15.7756' height='15.7756' rx='3.20355' stroke={color} strokeWidth='1.4' />
      <circle cx='5.98006' cy='6.05769' r='1.17107' transform='rotate(180 5.98006 6.05769)' fill={color} />
    </svg>
  );
};

export default Photo20;
