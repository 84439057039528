import { useAtomValue } from 'jotai';
import { global_scope } from 'store';
import { device_info_atom } from 'store/device';
import { APP_VERSION_TYPE, getAppVersion, isLowAppVersion } from 'util/app_utils';

/**
 * @description
 * 앱버전 분기로 인한 앱 버전별 관리 hook
 * @example
 * const { is_native_version } = useAppVersion();
 *
 * if (is_native_version) {
 *  return <ShowNative>;
 * }
 *
 * return <ShowWeb />;
 */
export const useAppVersion = () => {
  const { user_agent, is_app } = useAtomValue(device_info_atom, global_scope);

  const is_native_version = isNativeAppVersion(user_agent);
  const is_live_version = isLiveAppVersion(user_agent);
  const is_not_native_app_version = is_app && !is_native_version;
  const is_native_login_version = isLowAppVersion(APP_VERSION_TYPE.LOGIN_NATIVE, user_agent);

  const app_version = getAppVersion(user_agent);

  return {
    app_version,
    is_live_version,
    is_native_version,
    is_not_native_app_version,
    is_native_login_version,
  };
};

export function isNativeAppVersion(user_agent: string) {
  // 버전코드 버그 임시 대응
  if (user_agent.endsWith('6.10.1') || user_agent.endsWith('6.11.0')) {
    return true;
  }
  const is_not_app = getAppVersion(user_agent) === '';
  if (is_not_app) {
    return false;
  }

  return isLowAppVersion(APP_VERSION_TYPE.NATIVE_SUPPORT, user_agent);
}

export function isNativeLoginVersion(user_agent: string) {
  return isLowAppVersion(APP_VERSION_TYPE.LOGIN_NATIVE, user_agent);
}

export function isLiveAppVersion(user_agent: string) {
  // 버전코드 버그 임시 대응
  if (user_agent.endsWith('6.10.1')) {
    return true;
  }

  const is_not_app = getAppVersion(user_agent) === '';
  if (is_not_app) {
    return false;
  }

  return isLowAppVersion(APP_VERSION_TYPE.LIVE_SUPPORT, user_agent);
}
