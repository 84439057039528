import React, { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { gray600 } from 'styles';

const Play8: FC<IconProps> = ({ size = 8, color = gray600, ...props }) => {
  return (
    <svg width={size} height={size} fill='none' viewBox='0 0 8 8' {...props}>
      <path d='M1 8V0L8 4L1 8Z' fill={color} />
    </svg>
  );
};

export default Play8;
