import { ParsedUrlQuery } from 'querystring';
import { parse, UrlObject, UrlWithParsedQuery } from 'url';
import { RouteType as RouteUtilType } from 'util/route_util';

// @ts-ignore
import pathToRegexp from 'path-to-regexp';
import config from 'util/config';


const ROUTES: RouteUtilType[] = [];

// Reference: https://github.com/fridays/next-routes/blob/master/src/index.js

export type ZpayHeaderType = 'checkout' | 'pay' | 'order_completed' | 'product';
export type ZpayCategory =
  | 'cart'
  | 'order'
  | 'order-request'
  | 'order-sheet'
  | 'point'
  | 'product'
  | 'refund'
  | 'simple-pay'
  | 'coupon';

export interface RouteType {
  pattern: string;
  page: string;
  title?: string;
  type?: ZpayHeaderType;
  category?: ZpayCategory;
}

export class Route implements RouteType {
  pattern: string;
  page: string;
  title?: string;
  type?: ZpayHeaderType;
  regex: RegExp;
  keys: pathToRegexp.Key[];
  keyNames: Array<string | number>;
  category?: ZpayCategory;

  constructor({ pattern, page, title, type, category }: RouteType) {
    this.pattern = pattern;
    this.page = '/' + page;
    this.keys = [];
    this.title = title;
    this.type = type;
    this.regex = pathToRegexp(this.pattern, this.keys);
    this.keyNames = this.keys.map((key) => key.name);
    this.category = category;
  }

  match(path: string) {
    const values = this.regex.exec(path);
    if (values) {
      return this.valuesToParams(values.slice(1));
    }
  }

  // @ts-ignore
  valuesToParams(values) {
    // @ts-ignore
    return values.reduce((params, val, i) => {
      if (val === undefined) {
        return params;
      }
      return Object.assign(params, { [this.keys[i].name]: decodeURIComponent(val) });
    }, {});
  }
}

const routes: Route[] = ROUTES.reduce((result: Route[], route) => {
  result.push(new Route(route), new Route({ ...route, pattern: '/app' + route.pattern }));
  return result;
}, []);

export function match(url: string | UrlObject) {
  const parsedUrl = typeof url === 'string' ? parse(url, true) : (url as UrlWithParsedQuery);
  const { pathname, query } = parsedUrl;
  return routes.reduce<{ query: ParsedUrlQuery; parsedUrl: UrlWithParsedQuery; route?: Route }>(
    (result, route) => {
      if (result.route) {
        return result;
      }
      const params = route.match(pathname!);
      if (!params) {
        return result;
      }
      return { ...result, route, params, query: { ...query, ...params } };
    },
    { query, parsedUrl },
  );
}

/**
 * @description queryString 문자열 데이터를 json 포맷으로 변환시켜 줍니다.
 * @example
 *  queryStringToJSON('test=12&data=34') -> return {test: 12, data: 34}
 */
export function queryStringToJSON(qs: string) {
  const pairs = qs.split('&');
  const result = {};
  pairs.forEach(function (p) {
    const pair = p.split('=');
    const key = pair[0];
    const value = decodeURIComponent(pair[1] || '');
    // @ts-ignore
    if (result[key]) {
      // @ts-ignore
      if (Object.prototype.toString.call(result[key]) === '[object Array]') {
        // @ts-ignore
        result[key].push(value);
      } else {
        // @ts-ignore
        result[key] = [result[key], value];
      }
    } else {
      // @ts-ignore
      result[key] = value;
    }
  });

  return result;
}

/**
 * @description queryString 문자열 데이터를 json 포맷으로 변환시켜 줍니다.
 * @example
 *  jsonToQueryString({test: 12, data: 34}) -> return 'test=12&data=34'
 */
export function jsonToQueryString(qs: Object) {
  return Object.entries(qs)
    .map((e) => e.join('='))
    .join('&');
}

export function getZendesLink(page_parameter: string) {
  return `${config.zendesk_url}?page=${page_parameter}`;
}
