import { useEffect, useState } from 'react';
import { getUserName } from 'api2';
import { ApiErrors } from 'util/request';

export type AuthState = 'authenticated' | 'pending' | 'failed';

export const useAuthenticationState = () => {
  const [authState, setAuthState] = useState<AuthState>('pending');

  useEffect(() => {
    const checkLoggedIn = async () => {
      const {
        data: { user_account },
      } = await getUserName(null, { ignore_error: ApiErrors.NOT_LOGGED_IN });

      setAuthState(user_account !== null ? 'authenticated' : 'failed');
    };
    checkLoggedIn();
  }, []);

  return { authState };
};
