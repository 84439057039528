import styled from '@emotion/styled';
import { useUpdateAtom } from 'jotai/utils';
import React, { useEffect } from 'react';

import { isOpenAppLandingNavigationAtom } from './store/isOpenAppLandingNavigation';
import { useAdLandingNotify } from './useAdLadingNotify';
import { IconCancel16 } from 'components/base/Icon';
import { gray100, gray700, gray800, pink600, WEB_MAX_WIDTH, white } from 'styles';
import { typography } from 'styles/font';
import { isServiceCloseNotify } from 'util/app_utils';

const BUTTOM_APP_NOTIFY_HIDE_END_DATE = 'BUTTOM_APP_NOTIFY_HIDE_END_DATE';

/**
 * 앱 랜딩 알림 - 하단 네비게이션바 노출타입
 * @component
 */
const Navigation = () => {
  const { is_show, openApp, close } = useAdLandingNotify(BUTTOM_APP_NOTIFY_HIDE_END_DATE);
  const setIsOpenAppLandingNavigation = useUpdateAtom(isOpenAppLandingNavigationAtom);

  useEffect(() => {
    setIsOpenAppLandingNavigation((c) => {
      c.is_show = is_show;
    });
  }, [is_show, setIsOpenAppLandingNavigation]);

  if (!is_show) {
    return null;
  }

  if (isServiceCloseNotify()) {
    return null;
  }

  return (
    <Container>
      <Description>
        <Cancel onClick={close} color={gray700} />
        <Text>앱에서 10% 웰컴 쿠폰팩 지급!</Text>
      </Description>
      <AppViewButton onClick={openApp}>앱으로 보기</AppViewButton>
    </Container>
  );
};

export default Navigation;

const Container = styled.div`
  background-color: ${gray100};
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  width: 100%;
  position: fixed;
  bottom: 48px;
  max-width: ${WEB_MAX_WIDTH}px;
  z-index: 3;
`;

const Description = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  ${typography.small1}
  color: ${gray800};
`;

const Cancel = styled(IconCancel16)`
  margin-right: 10px;
  cursor: pointer;
`;

const AppViewButton = styled.button`
  ${typography.small1_bold}
  background-color: ${white};
  color: ${pink600};
  height: 30px;
  width: 75px;
  border-radius: 40px;
`;
