import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { pink_primary, white } from 'styles';

const NewBadge14: FC<IconProps> = ({ size = 14, color = pink_primary, sub_color = white, ...props }) => {
  return (
    <svg width={size} height={size} fill='none' viewBox='0 0 14 14' {...props}>
      <circle cx={size / 2} cy={size / 2} r={size / 2} fill={color} />
      <path
        d='M9.63658 3.88V10H8.34958L5.66758 6.148V10H4.35358V3.88H5.63158L8.31358 7.759V3.88H9.63658Z'
        fill={sub_color}
      />
    </svg>
  );
};

export default NewBadge14;
