import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const PointCircle20: FC<IconProps> = ({ size = 20, color = colors.yellow500, sub_color = colors.white, ...props }) => {
  return (
    <svg width={size} height={size} fill='none' viewBox='0 0 20 20' {...props}>
      <rect width={size} height={size} rx='10' fill={color} />
      <path
        d='M10.6958 4.85498C11.3305 4.85498 11.8998 4.92498 12.4038 5.06498C12.9172 5.20498 13.3465 5.41498 13.6918 5.69498C14.0465 5.97498 14.3172 6.32965 14.5038 6.75898C14.6905 7.17898 14.7838 7.67365 14.7838 8.24298C14.7838 8.82165 14.6905 9.32565 14.5038 9.75498C14.3172 10.175 14.0465 10.525 13.6918 10.805C13.3465 11.085 12.9172 11.295 12.4038 11.435C11.8998 11.575 11.3305 11.645 10.6958 11.645L8.31582 11.645L8.31582 15.145L6.21582 15.145L6.21582 4.85498L10.6958 4.85498ZM8.31582 6.53498L8.31582 9.96498L10.4438 9.96498C11.1998 9.96498 11.7598 9.83898 12.1238 9.58698C12.4972 9.32565 12.6838 8.87765 12.6838 8.24298C12.6838 7.60831 12.4972 7.16498 12.1238 6.91298C11.7598 6.66098 11.1998 6.53498 10.4438 6.53498L8.31582 6.53498Z'
        fill={sub_color}
      />
    </svg>
  );
};

export default PointCircle20;
