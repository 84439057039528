import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { pink500 } from 'styles';

const Download16: FC<IconProps> = ({ width = 16, height = 16, color = pink500, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 16 16' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.69999 9.31004L13.505 4.50503L14.495 5.49497L8.49495 11.495L7.99998 11.99L7.505 11.495L1.505 5.49497L2.49495 4.50503L7.29999 9.31006L7.29999 1H8.69999L8.69999 9.31004Z'
      fill={color}
    />
    <path d='M2 14H14' stroke={color} strokeWidth='1.4' />
  </svg>
);
export default Download16;
