import React, { useState, PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { useMount } from 'hooks';

interface Props extends PropsWithChildren {
  /**
   * element id를 정의합니다.
   * @default root_modal
   */
  target_id?: string;
}

const Portal: React.FC<Props> = ({ target_id = 'root_modal', children }) => {
  const [element, setElement] = useState<HTMLElement | null>(null);

  useMount(() => {
    let modal_root = document.getElementById(target_id);
    if (!modal_root) {
      modal_root = document.createElement('div');
      modal_root.setAttribute('id', target_id);
      document.body.appendChild(modal_root);
    }

    setElement(modal_root);
    return () => {
      document.body.style.overflowY = 'unset';
    };
  });

  return element && ReactDOM.createPortal(children, element);
};

export default Portal;
