import { isAndroid, isBrowser, isIOS } from '@croquiscom/web2app';
import { useState, useEffect } from 'react';

export function isMobile(user_agent?: string) {
  const ua = user_agent ?? window.navigator.userAgent;
  return (
    ua.match(
      /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i,
    ) != null || ua.match(/LG|SAMSUNG|Samsung/) != null
  );
}
export function isIE(userAgent?: string) {
  const ua = userAgent ?? window.navigator.userAgent; //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE '); // IE 10 or older
  const trident = ua.indexOf('Trident/'); //IE 11

  return msie > 0 || trident > 0;
}

export const isZigzag = () => {
  if (isBrowser()) {
    const app_window = window as any;
    const HAS_ZIGZAG_PROPS = !!(app_window?.zigzag || app_window?.webkit?.messageHandlers?.zigzag);
    return HAS_ZIGZAG_PROPS || /(zigzag)/i.test(navigator.userAgent);
  } else {
    return false;
  }
};

export const useZigzag = () => {
  const [is_app, setIsApp] = useState<boolean | null>(null);

  useEffect(() => {
    const app_window = window as any;
    const HAS_ZIGZAG_PROPS = !!(app_window?.zigzag || app_window?.webkit?.messageHandlers?.zigzag);
    setIsApp(HAS_ZIGZAG_PROPS || /(zigzag)/i.test(navigator.userAgent));
  }, []);

  return is_app;
};

export const getIosVersion = (user_agent: string) => {
  const match = user_agent.toLowerCase().match(/os (\d+([_\s]\d+)*) like mac os x/i);
  if (match) {
    return match[1].replace(/[_\s]/g, '.');
  }

  return '';
};

export const getAndroidVersion = (user_agent: string) => {
  if (/Android ([0-9.]*)/.test(user_agent)) {
    return RegExp.$1;
  }
  return '';
};

export interface DeviceType {
  platform: string;
  app_version: string;
  os_version: string;
  os_version_full: string;
  app_version_full: string;
  is_app: boolean;
  is_ios: boolean;
  is_aos: boolean;
  is_mobile: boolean;
  user_agent: string;
}

export const getDevice = (user_agent: string) => {
  const result: DeviceType = {
    platform: 'Web',
    app_version: '',
    os_version: '',
    os_version_full: '',
    app_version_full: '',
    is_app: false,
    is_ios: false,
    is_aos: false,
    is_mobile: isMobile(user_agent),
    user_agent: '',
  };

  if (isAndroid(user_agent)) {
    const android_version = getAndroidVersion(user_agent);
    result.os_version = android_version;
    result.os_version_full = `And ${android_version}`;
    result.app_version_full = `And ${android_version}`;
    result.is_aos = true;
  }

  if (isIOS(user_agent)) {
    const ios_version = getIosVersion(user_agent);
    result.os_version = ios_version;
    result.os_version_full = `IOS ${ios_version}`;
    result.app_version_full = `IOS ${ios_version}`;
    result.is_ios = true;
  }

  // MEMO: app device check
  if (/FBK\/([0-9.]*)/.test(user_agent)) {
    const fbk_app_version = RegExp.$1;
    result.app_version = fbk_app_version;
    result.is_app = true;

    if (isAndroid(user_agent)) {
      result.platform = 'And';
      result.app_version_full = `And ${fbk_app_version}`;
    }
    if (isIOS(user_agent)) {
      result.platform = 'IOS';
      result.app_version_full = `IOS ${fbk_app_version}`;
    }
  }
  // MEMO: 카카오톡 더보기탭 접근
  if (user_agent.includes('KAKAOTALK')) {
    result.platform = 'Web kakao';
  }

  result.user_agent = user_agent;
  return result;
};
