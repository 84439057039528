import { BaseConfigType } from './default';

const Config: BaseConfigType = {
  site_name: '패션 바이 카카오[알파]',
  baseDomain: 'alpha.zigzag.kr',
  apiBaseUrl: 'https://alpha.fashionbykakao.com/api/store',
  apiConsumerBaseUrl: 'https://alpha.fashionbykakao.com/api/2',
  appUrl: 'https://alpha.fashionbykakao.com',
  contentUrl: 'https://content.alpha.zigzag.kr',
  assetPrefix: '',
  payApiBaseUrl: 'https://alpha.fashionbykakao.com/api/user-pay',
  simplePayPassword:
    'https://api.alpha.zigzag.kr/api/security-keypad/static/js/simple_pay/simple_pay_password_alpha.js',
  simpleBankAppVersion: '6.5.0',
  sentry_dsn: 'https://d0f21d892ee944cd8bd7208e070d4a8b@o314573.ingest.sentry.io/6208265',
  sentry_org: 'zigzag-alpha',
  kakaoRestApiKey: '3a93cc75d6c1cefe075943a7f15896ab',
  /**
   * alpha app
   * https://developers.kakao.com/console/app/677446
   */
  kakaoJavascriptKey: 'd6d61d372970fe2448ed3354cf1e6110',
  live_player_access_key: 'EFro7KRE5UldHywwJsE6',
  ga_id: 'G-7J9Q5CZNYW',
  fbq_id: '5446676675351041',
  // 카카오톡 메세지 친구초대 템플릿 템플릿 id
  kakao_invite_friend_template_id: 75915,
  // 친구초대 쿠폰 id
  invite_code_welcome_coupon_id: '13169',
  // 앱 다이나믹 딥링크
  dynamic_deep_link: 'https://fashionbykakao.page.link',
  // IOS APP 설치 id
  ios_app_store_id: '653403166',
  // 파이어베이스 키값
  firebase_web_key: 'AIzaSyAp5QzeMiJP1ay9bxTsC83uf4HppuTNK2k',
  zendesk_url: 'https://fbk-alpha.zendesk.com/hc/p/requests-with-sso',
  // 회원가입 웰컴 쿠폰 id
  register_complete_welcome_coupon_id: '8a3736c7-4bf6-4f85-bc43-2addeff8482b',
  // 키즈 카테고리 id
  kid_category_id: '3040',
  // '전체' 상품 카테고리 id
  root_product_category_id: '2071',
  // 에어브릿지 앱 이름
  airbridge_app_name: 'fbkalpha',
  // 에어브릿지 웹 SDK 토큰
  airbridge_web_token: '1dfab839bad24fc19e262dc8e4b8037c',
  // 에어브릿지 url
  airbridge_url: 'https://abr.ge/@fbkalpha',
};

export default Config;
