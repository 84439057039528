import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { colors } from 'styles';

const Coupon32: FC<IconProps> = ({ width = 32, height = 32, color = colors.gray700, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 33 32' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29 13.5L27.49 15.0101L26.5 16L27.49 16.9899L29 18.5V24C29 25.1046 28.1046 26 27 26H6C4.89543 26 4 25.1046 4 24V18.5L5.51005 16.9899L6.5 16L5.51005 15.01L4 13.5V8C4 6.89543 4.89543 6 6 6H27C28.1046 6 29 6.89543 29 8V13.5ZM25.51 16.9899L27.6 19.0799V24C27.6 24.3314 27.3314 24.6 27 24.6H6C5.66863 24.6 5.4 24.3314 5.4 24V19.0799L7.48995 16.99L8.4799 16L7.48995 15.0101L5.4 12.9201V8C5.4 7.66863 5.66863 7.4 6 7.4H27C27.3314 7.4 27.6 7.66863 27.6 8V12.9201L25.51 15.0101L24.5201 16L25.51 16.9899ZM13.4857 13.6429C13.4857 14.1084 13.1084 14.4857 12.6429 14.4857C12.1774 14.4857 11.8 14.1084 11.8 13.6429C11.8 13.1774 12.1774 12.8 12.6429 12.8C13.1084 12.8 13.4857 13.1774 13.4857 13.6429ZM14.7857 13.6429C14.7857 14.8263 13.8263 15.7857 12.6429 15.7857C11.4594 15.7857 10.5 14.8263 10.5 13.6429C10.5 12.4594 11.4594 11.5 12.6429 11.5C13.8263 11.5 14.7857 12.4594 14.7857 13.6429ZM12.3496 19.6917L18.7428 12.0726H20.5703L14.1772 19.6917H12.3496ZM20.3571 18.8677C20.8226 18.8677 21.2 18.4903 21.2 18.0248C21.2 17.5593 20.8226 17.182 20.3571 17.182C19.8916 17.182 19.5143 17.5593 19.5143 18.0248C19.5143 18.4903 19.8916 18.8677 20.3571 18.8677ZM20.3571 20.1677C21.5406 20.1677 22.5 19.2083 22.5 18.0248C22.5 16.8414 21.5406 15.882 20.3571 15.882C19.1737 15.882 18.2143 16.8414 18.2143 18.0248C18.2143 19.2083 19.1737 20.1677 20.3571 20.1677Z'
        fill={color}
      />
    </svg>
  );
};

export default Coupon32;
