import { FC } from 'react';
import { IconProps } from 'components/base/Icon';
import { black } from 'styles';

const Eyes16: FC<IconProps> = ({ width = 16, height = 16, color = black, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 16 16' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.0951726 8.25903L0 8.00524L0.0951726 7.75145C0.172689 7.54473 0.322617 7.20204 0.548761 6.76785C0.922916 6.04947 1.38069 5.3314 1.92764 4.65823C3.52148 2.69658 5.5407 1.5 8 1.5C10.4593 1.5 12.4785 2.69658 14.0724 4.65823C14.6193 5.3314 15.0771 6.04947 15.4512 6.76785C15.6774 7.20204 15.8273 7.54473 15.9048 7.75145L16 8.00524L15.9048 8.25903C15.8273 8.46574 15.6774 8.80844 15.4512 9.24263C15.0771 9.96101 14.6193 10.6791 14.0724 11.3523C12.4785 13.3139 10.4593 14.5105 8 14.5105C5.5407 14.5105 3.52148 13.3139 1.92764 11.3523C1.38069 10.6791 0.922916 9.96101 0.548761 9.24263C0.322617 8.80844 0.172689 8.46574 0.0951726 8.25903ZM1.83087 8.57482C2.16258 9.2117 2.56878 9.84889 3.04957 10.4406C4.39213 12.093 6.0321 13.0648 7.99998 13.0648C9.96786 13.0648 11.6078 12.093 12.9504 10.4406C13.4312 9.84889 13.8374 9.2117 14.1691 8.57482C14.2762 8.36914 14.368 8.17775 14.4446 8.0052C14.368 7.83266 14.2762 7.64127 14.1691 7.43559C13.8374 6.79871 13.4312 6.16152 12.9504 5.56978C11.6078 3.91741 9.96786 2.94557 7.99998 2.94557C6.0321 2.94557 4.39213 3.91741 3.04957 5.56978C2.56878 6.16152 2.16258 6.79871 1.83087 7.43559C1.72374 7.64127 1.63196 7.83266 1.55537 8.0052C1.63196 8.17775 1.72374 8.36914 1.83087 8.57482ZM8.00001 10.8964C9.59679 10.8964 10.8912 9.60198 10.8912 8.0052C10.8912 6.40843 9.59679 5.11399 8.00001 5.11399C6.40324 5.11399 5.10879 6.40843 5.10879 8.0052C5.10879 9.60198 6.40324 10.8964 8.00001 10.8964ZM8.00001 9.45075C7.20162 9.45075 6.5544 8.80353 6.5544 8.00514C6.5544 7.20675 7.20162 6.55953 8.00001 6.55953C8.79839 6.55953 9.44561 7.20675 9.44561 8.00514C9.44561 8.80353 8.79839 9.45075 8.00001 9.45075Z'
      fill={color}
    />
  </svg>
);
export default Eyes16;
