import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { colors, white } from 'styles';

const Review60: FC<IconProps> = ({ width = 60, height = 60, color = colors.pink600, sub_color = white, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 60 60' fill='none' {...props}>
      <g clipPath='url(#clip0_854_14462)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.4 25.317C8.4 15.974 15.974 8.4 25.317 8.4H34.7334C44.0764 8.4 51.6504 15.974 51.6504 25.317C51.6504 34.4983 44.3361 41.9716 35.2171 42.2273L34.5814 42.2451L34.2392 42.7812L30.0251 49.3836L25.811 42.7812L25.4689 42.2451L24.8332 42.2273C15.7143 41.9715 8.4 34.4982 8.4 25.317ZM25.317 6C14.6485 6 6 14.6485 6 25.317C6 35.5846 14.0105 43.9809 24.1233 44.5977L29.0136 52.2596L30.0251 53.8444L31.0367 52.2596L35.927 44.5978C46.0399 43.981 54.0504 35.5847 54.0504 25.317C54.0504 14.6485 45.4019 6 34.7334 6H25.317ZM20.6102 27.8108C21.9233 27.8108 22.9878 26.7463 22.9878 25.4331C22.9878 24.12 21.9233 23.0555 20.6102 23.0555C19.297 23.0555 18.2325 24.12 18.2325 25.4331C18.2325 26.7463 19.297 27.8108 20.6102 27.8108ZM29.9626 27.8108C31.2758 27.8108 32.3403 26.7463 32.3403 25.4332C32.3403 24.1201 31.2758 23.0556 29.9626 23.0556C28.6495 23.0556 27.585 24.1201 27.585 25.4332C27.585 26.7463 28.6495 27.8108 29.9626 27.8108ZM41.6925 25.4332C41.6925 26.7463 40.628 27.8108 39.3148 27.8108C38.0017 27.8108 36.9372 26.7463 36.9372 25.4332C36.9372 24.1201 38.0017 23.0556 39.3148 23.0556C40.628 23.0556 41.6925 24.1201 41.6925 25.4332Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_854_14462'>
          <rect width={width} height={height} fill={sub_color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Review60;
