import { Global } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Atom, Provider } from 'jotai';
import { NextPage } from 'next';
import { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { ReactElement, ReactNode } from 'react';
import { getServerTime } from 'api2';
import AppLandingNotify, { LandingType } from 'components/common/AppLandingNotify';
import BroadCastConextProvider from 'context/BroadCastContext';
import { BottomDialogProvider } from 'context/ButtomDialogContext';
import { ScrollRestorationProvider } from 'context/ScrollRestorationContext';
import { useMount } from 'hooks';
import { useSendLoginLog } from 'hooks/auth/useSendLoginLog';
import useHideAppHeader from 'hooks/useHideAppHeader';
import useScrollRestoration from 'hooks/useScrollRestoration';
import { global_scope } from 'store';
import { device_info_atom } from 'store/device';
import 'styles/event-modules.css';
import { font } from 'styles/font';
import { reset } from 'styles/reset';
import config from 'util/config';
import { getDevice } from 'util/device';
import { TrackerProvider } from 'util/Tracker';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function setFrameOptions({ req, res }: AppContext['ctx']) {
  if (!req || !res) {
    return;
  }
  try {
    const { hostname } = new URL(req.headers.referer || '');
    if (
      hostname === 'localhost' ||
      hostname.endsWith('fashionbykakao.com') ||
      hostname.endsWith('partners.kakaostyle.com')
    ) {
      return;
    } else {
      res.setHeader('X-Frame-Options', 'DENY');
    }
  } catch (e) {
    res.setHeader('X-Frame-Options', 'DENY');
  }
}

export const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const queryClient = new QueryClient({
    defaultOptions: { queries: { cacheTime: 300000, refetchOnWindowFocus: false, staleTime: 300000, retry: 1 } },
  });

  const getLayout = Component.getLayout ?? ((page) => page);
  // 서버에서 user-agent는 CloudFront에 의해 오염된 상태여서 클라이언트에서는 클라이언트 것을 사용한다
  const user_agent: string = (typeof navigator === 'undefined' ? pageProps.user_agent : navigator.userAgent) ?? '';
  const device_info = getDevice(user_agent);

  useHideAppHeader(device_info);
  useScrollRestoration();
  useSendLoginLog();

  useMount(() => {
    document.body.className += 'active';

    try {
      // kakao sdk init
      if (!(window as any).Kakao.isInitialized()) {
        (window as any).Kakao.init(config.kakaoJavascriptKey!);
      }
    } catch (e) {
      console.error('kakao sdk not install');
    }
  });

  const init_values: Array<[Atom<unknown>, unknown]> = [[device_info_atom, device_info]];
  return (
    <Provider scope={global_scope} initialValues={init_values}>
      <QueryClientProvider client={queryClient}>
        <BroadCastConextProvider>
          <TrackerProvider user_agent={user_agent}>
            <Head>
              {['Regular', 'Medium', 'SemiBold', 'Bold'].map((font) => (
                <link
                  rel='preload'
                  key={font}
                  href={`${config.resUrl}/fonts/Pretendard/Pretendard-${font}.woff2`}
                  as='font'
                  type='font/woff2'
                  crossOrigin='anonymous'
                />
              ))}
              <meta
                name='viewport'
                content='width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no,viewport-fit=cover'
              />
            </Head>
            <Global styles={reset} />
            <Global styles={font} />
            <ScrollRestorationProvider>
              <BottomDialogProvider>
                {getLayout(<Component {...pageProps} />)}
                <AppLandingNotify type={LandingType.DIALOG} />
              </BottomDialogProvider>
            </ScrollRestorationProvider>
          </TrackerProvider>
        </BroadCastConextProvider>
      </QueryClientProvider>
    </Provider>
  );
};

MyApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
  // Component.getInitialProps 전에 setHeader 호출해야함
  setFrameOptions(ctx);

  /**
   * @description
   * 쿠키정보가 없는 최초진입 SSR에서 API 병렬 호출 시, 세션이 여러개 생기는 이슈로 initialProps 이전에 단독으로 먼저 호출합니다.
   * https://github.com/croquiscom/fbk-web/pull/797
   */
  let server_time = 0;
  if (ctx.req) {
    const { data } = await getServerTime(null, { context: ctx.req, use_new_web_session: true });
    server_time = data.metadata.server_time;
  }

  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  const user_agent = ctx.req?.headers['user-agent'] ?? '';

  return {
    pageProps: {
      ...pageProps,
      user_agent,
      server_time,
    },
  };
};

export default MyApp;
