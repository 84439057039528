import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import DefaultRouter from 'next/router';
import { useRef, useCallback } from 'react';
import useBrowserEffect from './useBrowserEffect';
import { cms_scroll_restoration_atom } from 'components/content-editor/store';
import { global_scope } from 'store';
import { device_info_atom } from 'store/device';

interface ScrollHistory {
  path: string;
  scroll_pos: number;
}

function useScrollRestoration() {
  const scroll_history = useRef<ScrollHistory[]>([]);
  const setCmsScrollRestoration = useUpdateAtom(cms_scroll_restoration_atom);
  const device_info = useAtomValue(device_info_atom, global_scope);

  useBrowserEffect(() => {
    // 뒤로 가기, 앞으로 가기시 이전 위치로 복귀하지 않는다
    // TODO: 일단은 search화면에만 적용
    // window.history.scrollRestoration = 'manual';
    let pop_state = false;

    const onRouteChangeStart = () => {
      scroll_history.current.push({
        path: DefaultRouter.asPath,
        scroll_pos: document.documentElement.scrollTop,
      });

      if (pop_state) {
        // 뒤로가기 예정인 history item
        const reverse_history = [...scroll_history.current].reverse();
        const will_pop_item = reverse_history.find((item) => item.path !== DefaultRouter.asPath);

        const is_cms_page = will_pop_item ? getIsCmsPage(will_pop_item.path) : false;
        if (is_cms_page) {
          beforeCmsRouteChange();
        }
      }
    };
    const onRouteChangeComplete = () => {
      if (pop_state) {
        pop_state = false;
        let item = scroll_history.current.pop();
        while (item != null && item.path !== DefaultRouter.asPath) {
          item = scroll_history.current.pop();
        }

        const is_cms_page = item ? getIsCmsPage(item.path) : false;
        if (is_cms_page) {
          afterCmsRouteChange(item);
          return;
        }

        if (item) {
          document.documentElement.scrollTo(0, item.scroll_pos);
        }
      }
    };

    DefaultRouter.events.on('routeChangeStart', onRouteChangeStart);
    DefaultRouter.events.on('routeChangeComplete', onRouteChangeComplete);
    DefaultRouter.beforePopState(() => {
      pop_state = true;
      return true;
    });

    return () => {
      DefaultRouter.events.off('routeChangeStart', onRouteChangeStart);
      DefaultRouter.events.off('routeChangeComplete', onRouteChangeComplete);
      DefaultRouter.beforePopState(() => true);
    };
  }, [device_info]);

  const beforeCmsRouteChange = useCallback(() => {
    // cms > pdp 최상위 위치에서 뒤로가기 시, 스크롤 복원 안되는 현상으로 라우트 이동시 강제 1px 스크롤 이동
    const current_y = document.documentElement.scrollTop;
    document.documentElement.scrollTo(0, current_y + 1);
    setCmsScrollRestoration(true);
  }, [setCmsScrollRestoration]);

  const afterCmsRouteChange = useCallback(
    (item?: ScrollHistory) => {
      // cms 페이지 다중 virtuso 렌더시, 뒤로가기 스크롤 복원 이슈로 timer로 지연
      const scroll_y = item?.scroll_pos || 0;
      const { is_ios, is_mobile } = device_info;
      const delay = is_ios && is_mobile ? 500 : 200;
      const timer = setTimeout(() => {
        document.documentElement.scrollTo(0, scroll_y);
        setCmsScrollRestoration(false);
        clearTimeout(timer);
      }, delay);
    },
    [device_info, setCmsScrollRestoration],
  );
}

export default useScrollRestoration;

function getIsCmsPage(path?: string) {
  if (!path) {
    return false;
  }
  if (path.startsWith('/events/cms/') || path.startsWith('/?tab=promotion')) {
    return true;
  }
  return false;
}
