import 'isomorphic-unfetch';
import { IncomingHttpHeaders, IncomingMessage } from 'http';
import { isBrowser, toQueryString } from '@croquiscom/web2app';
import { splitCookiesString } from 'set-cookie-parser';
import config from './config';
import { mergeCookie } from './cookie-util';
import { isZigzag } from './device';
import { Router } from './router';
import { ZpayEvent } from './zigzag_app_event';
import { web_path } from 'constants/web_path';
import { getAppVersion } from './app_utils';
import { isNativeLoginVersion } from 'hooks/app/useAppVersion';

export type RequestContext = Partial<IncomingMessage> & {
  cookies?: Partial<{
    [key: string]: string;
  }>;
  headers?: IncomingHttpHeaders;
  res?: { setHeader?: (key: string, value: string[]) => void };
};

export interface GqlRequestOptions {
  appendFormdata?: (form_data: FormData) => void;
  context?: RequestContext;
  show_alert?: boolean;
  ignore_error?: ApiErrors;
  use_consumer?: boolean;
  use_new_web_session?: boolean;
}
export interface RequestParams {
  headers?: object;
  method?: string;
  url: string;
  data?: unknown;
  res?: {
    setHeader: (key: string, value: string[]) => void;
  };
}

export interface RequestOptions {
  show_alert?: boolean;
  ignore_error?: ApiErrors;
  use_consumer?: boolean;
}

export interface ErrorObject {
  message: string;
  locations?: object[];
  path?: string[];
  extensions?: {
    code: string;
    description: string;
    ignorable: boolean;
  };
  description?: string;
}

export enum ApiErrors {
  NOT_LOGGED_IN = 'not logged in',
  INSUFFICIENT_PRODUCT_QUANTITY = 'insufficient product quantity',
  EXCEEDED_PRODUCT_QUANTITY = 'exceeded product quantity',
}

export function gotoLogin(redirect_url?: string) {
  if (!isBrowser()) {
    return console.log('[Error] gotoLogin not available in server');
  }
  if (isZigzag()) {
    return ZpayEvent.login();
  }
  const redirect = !redirect_url ? '' : `?redirect=${redirect_url}`;
  return Router.replace(web_path.auth.login + redirect);
}

function getDescription(errorObj: ErrorObject) {
  if (errorObj.description) {
    return errorObj.description;
  }
  if (errorObj.extensions?.description) {
    return errorObj.extensions.description;
  }
  if (errorObj.message) {
    return errorObj.message;
  }
  return '';
}

async function handleError(errorObj: ErrorObject, show_alert: boolean) {
  const error_object = {
    message: errorObj.message,
    extensions: errorObj.extensions ?? {},
    description: getDescription(errorObj),
  };

  if (error_object.message === ApiErrors.NOT_LOGGED_IN && isBrowser()) {
    gotoLogin(window.location.href);
    return;
  }

  if (
    (error_object.message === ApiErrors.INSUFFICIENT_PRODUCT_QUANTITY ||
      error_object.message === ApiErrors.EXCEEDED_PRODUCT_QUANTITY) &&
    isBrowser()
  ) {
    throw error_object;
  }

  if (show_alert && isBrowser()) {
    alert(error_object.description || '알 수 없는 오류가 발생했습니다. 고객센터로 문의해주세요');
  }

  throw error_object;
}

export default async function request(
  params: RequestParams,
  { show_alert = true, ignore_error, use_consumer = false }: RequestOptions,
) {
  let url = `${use_consumer ? config.apiConsumerBaseUrl : config.apiBaseUrl}/${params.url.replace(/^\//, '')}`;
  const request_init: RequestInit = {
    method: params.method || 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...params.headers,
    },
    credentials: 'include',
  };
  if (params.data) {
    if (request_init.method === 'GET') {
      url += `?${toQueryString(params.data)}`;
    } else {
      request_init.body = JSON.stringify(params.data);
    }
  }
  const response = await fetch(url, request_init);
  if (response.status === 200) {
    const result = await response.json();
    const set_cookie = response.headers.get('set-cookie');

    if (set_cookie) {
      params.res?.setHeader('set-cookie', splitCookiesString(set_cookie));
    }
    if (result.errors && result.errors.length > 0 && (!ignore_error || result.errors[0].message !== ignore_error)) {
      await handleError(result.errors[0], show_alert);
    }
    return result;
  } else {
    const error = await response.json();
    await handleError(error, show_alert);
  }
}

export async function gql_request<R, V = {}>(
  operation_name: string,
  query: string,
  variables?: V,
  options: GqlRequestOptions = {},
) {
  const {
    appendFormdata,
    context,
    show_alert = true,
    ignore_error,
    use_consumer,
    use_new_web_session = false,
  } = options;
  let data: object = { query, variables };
  if (appendFormdata != null) {
    const form_data = new FormData();
    form_data.append('operations', JSON.stringify(data));
    appendFormdata(form_data);
    data = form_data;
  }
  const headers =
    context && context.headers
      ? {
          'cookie': context.headers.cookie,
          'user-agent': context.headers['user-agent'],
          'x-forwarded-for': context.headers['x-forwarded-for'] || context.headers.Remote_Addr,
        }
      : {};
  const agent = context && context.headers ? context.headers['user-agent'] : '';

  const result = await request(
    {
      method: 'POST',
      headers,
      url: `/graphql/${operation_name}`,
      data,
      res: {
        setHeader(key, value) {
          if (!use_new_web_session) {
            return;
          }

          if (isAppHeaderSetting(agent)) {
            return;
          }

          if (context?.res?.setHeader) {
            context.res.setHeader(key, value);
          }
          if (key === 'set-cookie' && context && context.headers) {
            context.headers.cookie = mergeCookie(context.headers.cookie, value);
          }
        },
      },
    },
    { show_alert, ignore_error, use_consumer },
  );

  return result as { data: R; errors?: ErrorObject[] };
}

function isAppHeaderSetting(agent?: string) {
  if (!agent) {
    return false;
  }

  const is_native_login_version = isNativeLoginVersion(agent);
  return is_native_login_version;
}
