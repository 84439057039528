import { useRouter } from 'next/router';
import { DEFAULT_SORT_TYPE, getSearchFilterUbl, SearchFilter, SORT_TYPE } from 'util/search_utils';

/**
 * @hook 검색 옵션 조회용 hook
 * @description
 *  검색의 옵션필터는 query_string 을 통해서 관리합니다. (링크를 통해 진입시점에 검색조건 유지를 위함)
 * @example
 *  const { search_props } = useSearchQueryString();
 *  ...
 */
export const useSearchQueryString = () => {
  const { query } = useRouter();
  const is_new_type = query.product_list_page === 'new' || query.tab === 'new';

  const has_price_filter = query.min_price !== undefined && query.max_price !== undefined;
  const is_selected_sort_type = !!query.sort_type;
  const sort_type = is_new_type ? SORT_TYPE.NEW : ((query.sort_type || DEFAULT_SORT_TYPE) as SORT_TYPE);
  // @ts-ignore
  const category_list = (query.category_list ? JSON.parse(query.category_list as string) : []).map((category) => {
    const [id, name, depth] = category.split('/');
    return { id, name, depth: Number(depth) };
  });

  const search_props: SearchFilter = {
    price: has_price_filter ? { max: Number(query.max_price), min: Number(query.min_price) } : undefined,
    color_list: query.color_list ? JSON.parse(query.color_list as string) : undefined,
    category_list,
    is_free_shipping: query.is_free_shipping ? query.is_free_shipping === 'true' : undefined,
    is_zigin_shipping: query.is_zigin_shipping ? query.is_zigin_shipping === 'true' : undefined,
    is_fast_shipping: query.is_fast_shipping ? query.is_fast_shipping === 'true' : undefined,
    sort_type,
    keyword: query.keyword ? String(query.keyword) : undefined,
  };

  const is_not_selected_search_filter =
    !has_price_filter && !query.color_list && !query.is_zigin_shipping && !query.is_free_shipping;

  //MEMO: 검색 초기화시 정렬, 무료배송, 직진배송은 유지 정책
  const reset_query_string = Object.entries({
    sort_type,
    is_free_shipping: search_props.is_free_shipping ?? false,
    is_zigin_shipping: search_props.is_zigin_shipping ?? false,
    is_fast_shipping: search_props.is_fast_shipping ?? false,
  })
    .map((item) => item.join('='))
    .join('&');

  return {
    sort_type,
    search_props,
    reset_query_string,
    is_selected_sort_type,
    is_not_selected_search_filter,
    search_filter_ubl: getSearchFilterUbl(search_props),
  };
};
