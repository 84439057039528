import { FC } from 'react';

import { IconProps } from 'components/base/Icon';
import { gray100 } from 'styles';

const ShareMore32: FC<IconProps> = ({ width = 32, height = 32, color = gray100, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 32 32' fill='none' {...props}>
      <rect width={width} height={height} rx='16' fill={color} />
      <circle cx='16.5' cy='16.5' r='1.5' fill='#C4C4C4' />
      <circle cx='10.5' cy='16.5' r='1.5' fill='#C4C4C4' />
      <circle cx='22.5' cy='16.5' r='1.5' fill='#C4C4C4' />
    </svg>
  );
};

export default ShareMore32;
