import BaseConfig, { BaseConfigType } from './default';

let Config = { ...BaseConfig };
const NODE_ENV = process.env.NODE_ENV;
const DEVELOPMENT_ENV = process.env.DEVELOPMENT_ENV;

try {
  const EnvConfig: BaseConfigType = getEnvConfg(NODE_ENV);

  const base_domain =
    EnvConfig.baseDomain &&
    (DEVELOPMENT_ENV ? `${DEVELOPMENT_ENV}.development.${EnvConfig.baseDomain}` : EnvConfig.baseDomain);

  Config = {
    ...Config,
    ...EnvConfig,
    ...(base_domain &&
      [
        'apiBaseUrl',
        'apiConsumerBaseUrl',
        'appUrl',
        'payApiBaseUrl',
        // TODO: dev에서 포트 노출이 안되 임시로 alpha, prod 서비스 사용.
        // 'authenticationScriptUrl',
        // 'authenticationSimplePayScriptUrl',
        // 'simplePayPassword',
      ].reduce<BaseConfigType>((result, key) => {
        // @ts-ignore
        result[key] = EnvConfig[key].replace(EnvConfig.baseDomain, base_domain);
        return result;
      }, {})),
  };
} catch (e) {
  console.log(`Cannot find configs for env=${NODE_ENV}`);
}

function getEnvConfg(node_env: string) {
  const is_dev_env = node_env.startsWith('dev-');

  if (!is_dev_env) {
    return require(`./${node_env}`).default;
  }

  const dev_env = require('./dev-dafault').default;

  return {
    ...dev_env,
    site_name: `패션 바이 카카오${node_env}`,
    apiBaseUrl: `https://fbk-${node_env}.dev.zigzag.kr/api/store`,
    apiConsumerBaseUrl: `https://fbk-${node_env}.dev.zigzag.kr/api/2`,
    appUrl: `https://fbk-${node_env}.dev.zigzag.kr`,
    payApiBaseUrl: `https://fbk-${node_env}.dev.zigzag.kr/api/user-pay`,
  };
}

export * from './default';
export { Config };
